@if (isLoading) {
  <div class="w-full h-full flex items-center justify-center gap-2 py-10">
    <arkiq-spinner />
    <p class="text-gray-500">Loading layout...</p>
  </div>
} @else {
  <div class="w-full flex flex-col p-[15px] overflow-x-auto">
    @if (nodes.length) {
      @for (node of nodes; track $index; let index = $index) {
        <ng-container
          *ngTemplateOutlet="
          nodeButton;
          context: { node: node, deep: 0, path: index + '' }
        "
        ></ng-container>
      }
    } @else {
      <div class="w-full h-full flex items-center justify-center flex-col gap-4 py-5 px-3">
        <p class="text-[#AAAAAA] font-medium text-center">
          No locations found. Click the button to add your first location
        </p>

      </div>
    }
  </div>

  <ng-template #nodeButton let-node="node" let-deep="deep" let-path="path">
    <div class="flex">
      @for( n of generateArray(deep); track $index; let index = $index) {
        <div
          class="border-l border-dashed border-gray-400 w-6 ml-3"
          [ngClass]="{'w-[26px]': index === deep - 1}"
        ></div>
      }

      <button
        type="button"
        class="flex-1 flex items-center p-2 rounded-[9px] transition-all"
        [ngClass]="[
          selectedNode?.pl_id === node.pl_id
            ? 'bg-primary/10 hover:bg-primary/20'
            : 'hover:bg-gray-200'
        ]"
        (click)="handleNodeClicked(node, path, deep)"
      >
        <section class="flex items-center gap-2 flex-1">
          <div
            class="size-7 min-w-[28px] min-h-[28px] border border-black/10 rounded-[5px] flex items-center justify-center"
          >
            <i class="bi bi-pin text-[#CCCCCC] text-base"></i>
          </div>

          <span class="text-[11px] text-left">
            {{ node.l_name }}
          </span>
        </section>

        <section class="flex items-center gap-2">
          <arkiq-icon-button
            *hasRole="[USER_ROLES.ORGANIZATION_MASTER]"
            icon="bi-three-dots-vertical"
            (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{ node: node }"
          />

          @if (node.children.length > 0) {
          <arkiq-icon-button
            [icon]="node.isCollapsed ? 'bi-chevron-down' : 'bi-chevron-up'"
            (click)="$event.stopPropagation(); node.isCollapsed = !node.isCollapsed"
          />
          }
        </section>
      </button>
    </div>

    @if (node.children.length > 0 && !node.isCollapsed) {
      @for (childNode of node.children; track $index; let index = $index) {
        <ng-container
          *ngTemplateOutlet="
            nodeButton;
            context: {
              node: childNode,
              deep: deep + 1,
              path: path + '-' + index
            }
          "
        ></ng-container>
      }
    }
  </ng-template>

  <mat-menu #menu="matMenu">
    <ng-template matMenuContent let-node="node">
      <!-- <button
        type="button"
        class="w-full flex items-center gap-2 p-2 py-4 border-b border-dashed border-gray-300 hover:bg-gray-100 transition-all"
        (click)="handleMoveNode(node)"
      >
        <span>Move</span>
      </button>

      <button
        type="button"
        class="w-full flex items-center gap-2 p-2 py-4 border-b border-dashed border-gray-300 hover:bg-gray-100 transition-all"
        (click)="handleDuplicateNode(node)"
      >
        <span>Duplicate</span>
      </button> -->

      <button
        type="button"
        class="w-full flex items-center gap-2 p-2 py-4 border-b border-dashed hover:bg-gray-100 transition-all"
        (click)="handleRenameNode(node)"
      >
        <span>Rename</span>
      </button>
      <button
        type="button"
        class="w-full flex items-center gap-2 p-2 py-4 border-b border-dashed font-medium text-[#F00] hover:bg-gray-100 transition-all"
        (click)="handleDeleteNode(node)"
      >
        <span>Delete</span>
      </button>
    </ng-template>
  </mat-menu>
}
