import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import {
  ButtonComponent,
  DialogAlertComponent,
  DialogAlertParams,
  DialogAlertVariant,
  DialogConfirmActionComponent,
  HasRoleDirective,
  IconButtonComponent,
  InputComponent,
  SpinnerComponent,
  TableColumn,
  TableColumnType,
  TableComponent,
} from '@arkiq-portals/ui';

import { FormControl, FormGroup } from '@angular/forms';
import {
  PropertiesService,
  Property,
  User,
  UserOrganizationRole,
  UserRole,
  UserRoleLabel,
  UsersService,
} from '@arkiq-portals/sdk';
import { Subscription, debounceTime, distinctUntilChanged, take } from 'rxjs';

interface GenericFilter {
  rolesToFilter: UserRole[];
  search?: string;
}

@Component({
  selector: 'app-property-details-user',
  templateUrl: 'property-details-user.component.html',
  standalone: true,
  imports: [
    TableComponent,
    MatDialogModule,
    MatTooltipModule,
    IconButtonComponent,
    CommonModule,
    SpinnerComponent,
    RouterModule,
    ButtonComponent,
    InputComponent,
    HasRoleDirective,
  ],
})
export class PropertyDetailsUserComponent implements OnInit, OnDestroy {
  public isManagersLoading = false;
  public isResidentsLoading = false;
  public managers!: UserOrganizationRole[] | null;
  public residents!: UserOrganizationRole[] | null;
  public property!: Property;
  public subscriptions = new Subscription();
  public roles = UserRole;
  public managerSearch = '';
  public residentSearch = '';

  public USER_ROLES = UserRole;
  public ALL_USER_ROLES = Object.values(UserRole);

  public form = new FormGroup({
    managers: new FormControl<string>(''),
    residents: new FormControl<string>(''),
  });

  public columns: TableColumn<User>[] = [
    {
      type: TableColumnType.PICTURE_AND_TEXT,
      label: 'Name',
      pictureAndTextValueFn: user => ({
        text: `${user.firstName} ${user.lastName}`,
        picture: user.photo,
        isHyperlink: false,
      }),
      headerClasses: 'text-left',
      valueClasses: 'text-left font-bold',
    },
    {
      type: TableColumnType.TEXT,
      label: 'Role',
      valueFn: user => UserRoleLabel[user.role],
      headerClasses: 'text-center',
      valueClasses: 'text-center text-[#888]',
    },
    {
      type: TableColumnType.ACTIONS,
      label: 'Actions',
      headerClasses: 'text-center',
      valueClasses: 'text-center text-[#888]',
      allowedRoles: [UserRole.ORGANIZATION_MASTER],
      actionButtons: [
        {
          type: 'icon-button',
          icon: 'bi-pencil-square',
          handler: user => this.handleEditUser(user),
          tooltip: 'Edit User',
        },
        {
          type: 'icon-button',
          icon: 'bi-trash-fill',
          handler: user => this.handleDeleteUser(user),
          tooltip: 'Delete User',
        },
      ],
    },
  ];

  constructor(
    private propertyService: PropertiesService,
    private matDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.form.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe(event => {
          if (event.managers !== this.managerSearch) {
            this.searchManagers(event.managers);
          }
          if (event.residents !== this.residentSearch)
            this.searchResidents(event.residents);
        }),
    );
    const routeParams = this.activatedRoute.parent?.snapshot.params;

    if (routeParams?.['id']) {
      this.getProperty(Number(routeParams['id']));
    }
  }

  private async _deleteUser(userId: number) {
    try {
      this.isResidentsLoading = true;
      this.isManagersLoading = true;

      await this.usersService.delete(userId);
      const routeParams = this.activatedRoute.parent?.snapshot.params;

      if (routeParams?.['id']) {
        this.getProperty(Number(routeParams['id']));
      }
    } catch (error) {
      console.error(error);
      this.showAlert({
        variant: DialogAlertVariant.ERROR,
        title: `Couldn't delete user, try again later!`,
        text: error as string,
      });
    } finally {
      this.isManagersLoading = false;
    }
  }

  private managerRoles = [
    UserRole.PROPERTY_MASTER,
    UserRole.BUILDING_MANAGEMENT,
    UserRole.ORGANIZATION_MASTER,
  ];

  private residentRoles = [
    UserRole.CUSTOMER,
    UserRole.RESIDENT,
    UserRole.OTHER,
  ];

  private async getProperty(propertyId: number) {
    try {
      const property = await this.propertyService.getById(propertyId);
      this.property = property;
      if (!property.users_organizations_roles) {
        throw new Error('No users in property organization');
      }

      this.managers = this.genericFilter({ rolesToFilter: this.managerRoles });
      this.residents = this.genericFilter({
        rolesToFilter: this.residentRoles,
      });
    } catch (error) {
      console.error(error);

      this.showAlert({
        variant: DialogAlertVariant.ERROR,
        title: `Couldn't find user roles`,
        text: error as string,
      });
    } finally {
      this.isManagersLoading = false;
      this.isResidentsLoading = false;
    }
  }

  public handleEditUser(user: User) {
    this.router.navigateByUrl(`/users/${user.id}/edit`, {
      state: { user },
    });
  }
  public handleDeleteUser(user: User) {
    const userRolesInCurrentProperty = this.property.users_organizations_roles
      ?.filter(role => role.userId === user.id)
      .map(role => role.role);

    const isUserOrganizationMaster = userRolesInCurrentProperty
      ?.includes(UserRole.ORGANIZATION_MASTER);

    const loggedUserRolesInCurrentProperty = this.property.users_organizations_roles
      ?.filter(role => role.userId === this.usersService.loggedUser?.id)
      .map(role => role.role);

    const isLoggedUserOrganizationMasterOrAdmin = loggedUserRolesInCurrentProperty
      ?.includes(UserRole.ORGANIZATION_MASTER) || this.usersService.isLoggedUserAdmin;

    if (isUserOrganizationMaster && !isLoggedUserOrganizationMasterOrAdmin) {
      this.matDialog.open(
        DialogAlertComponent,
        {
          hasBackdrop: true,
          disableClose: false,
          data: {
            variant: DialogAlertVariant.WARNING,
            title: 'Permission denied',
            text: 'You cannot remove an organization master from this property',
          } as DialogAlertParams,
        },
      );

      return;
    }

    const ref = this.matDialog.open(DialogConfirmActionComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        title: 'Are you sure?',
        text: `Are you sure you want to delete ${user.firstName} ${user.lastName}?`,
      },
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(async data => {
        if (data.confirm) {
          this._deleteUser(user.id);
        }
      });
  }
  public handleAddUser(role: UserRole) {
    this.router.navigateByUrl('/users/create', {
      state: {
        property: this.property,
        role,
      },
    });
  }
  public searchManagers(search) {
    this.managerSearch = search;
    this.managers = this.genericFilter({
      search,
      rolesToFilter: this.managerRoles,
    });
  }

  public genericFilter({
    rolesToFilter,
    search,
  }: GenericFilter): UserOrganizationRole[] | [] {
    if (!this.property || !this.property.users_organizations_roles) return [];
    const filteredObject =
      this.property.users_organizations_roles.filter(
        ({ role, user }) => rolesToFilter.includes(role) && user !== undefined,
      ) || null;
    if (!search) return filteredObject;
    const filteredObjectSearchedByName = filteredObject.filter(({ user }) =>
      `${user?.firstName} ${user?.lastName}`.toLowerCase().includes(search),
    );
    return filteredObjectSearchedByName;
  }

  public searchResidents(search) {
    this.residentSearch = search;
    this.residents = this.genericFilter({
      search,
      rolesToFilter: this.residentRoles,
    });
  }

  public showAlert(params: DialogAlertParams) {
    const ref = this.matDialog.open(DialogAlertComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: params,
    });

    return ref;
  }
}
