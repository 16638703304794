import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {
    FunctionsService,
    Organization,
    OrganizationsService,
    User,
    UserOrganizationRoleService,
    UserRole,
    UsersService,
    UserType
} from '@arkiq-portals/sdk';
import {
    ButtonComponent,
    IconButtonComponent,
    InputComponent,
    SelectOrganizationMasterComponent,
    TextAreaComponent
} from '../../components';
import { DialogAlertComponent, DialogAlertParams, DialogAlertVariant } from '../dialog-alert/dialog-alert.component';

@Component({
  selector: 'arkiq-dialog-create-organization',
  templateUrl: './dialog-create-organization.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    IconButtonComponent,
    InputComponent,
    TextAreaComponent,
    SelectOrganizationMasterComponent,
    ButtonComponent,
  ],
})
export class DialogCreateOrganizationComponent {
  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    master: new FormControl<User | Partial<User> | null>(null),
  });

  public isSaving = false;
  public isMasterValid = true

  constructor(
    private dialogRef: MatDialogRef<DialogCreateOrganizationComponent>,
    private organizationsService: OrganizationsService,
    private userOrganizationRolesService: UserOrganizationRoleService,
    private functionsService: FunctionsService,
    private usersService: UsersService,
    private matDialog: MatDialog,
  ) {}

  public handleClose(): void {
    this.dialogRef.close();
  }

  public async handleCreate(): Promise<void> {
    if (this.form.invalid) {
      return;
    }

    try {
      this.isSaving = true;

      const organization = await this.organizationsService.create({
        name: this.form.value.name ?? '',
        description: this.form.value.description ?? '',
      });

      await this.saveOrganizationMaster(organization);

      this.isSaving = false;

      this.dialogRef.close({ organization });
    } catch (error) {
      this.isSaving = false;
      console.error(error);

      this.matDialog.open(
        DialogAlertComponent,
        {
          hasBackdrop: true,
          disableClose: false,
          data: {
            variant: DialogAlertVariant.ERROR,
            title: 'Error creating organization',
            text: error as string,
          } as DialogAlertParams,
        },
      );
    }
  }

  private async saveOrganizationMaster(organization: Organization) {
    const currentOrganizationMaster = organization.users_organizations_roles
      ?.find(userRole => userRole.role === UserRole.ORGANIZATION_MASTER);

    if (currentOrganizationMaster) {
      await this.userOrganizationRolesService.delete(currentOrganizationMaster.id);
    }

    if (this.form.value.master?.id) {
      await this.userOrganizationRolesService.create({
        organizationId: organization.id,
        userId: this.form.value.master.id,
        role: UserRole.ORGANIZATION_MASTER
      });
    } else {
      const { uid: firebaseId } = await this.functionsService.registerUser(
        this.form.value.master?.email as string,
        `${this.form.value.master?.firstName} ${this.form.value.master?.lastName}`,
      );

      const organizationMasterUser = await this.usersService.create({
        firebaseId,
        email: this.form.value.master?.email,
        firstName: this.form.value.master?.firstName,
        lastName: this.form.value.master?.lastName,
        phoneNumber: this.form.value.master?.phoneNumber,
        type: UserType.CUSTOMER,
        photo: '',
      });

      await this.userOrganizationRolesService.create({
        organizationId: organization.id,
        userId: organizationMasterUser.id,
        role: UserRole.ORGANIZATION_MASTER
      });
    }
  }

  public isMastersTouchedAndValid(event) {
    this.isMasterValid = event
  }
}
