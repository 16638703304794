import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { firstValueFrom } from "rxjs";
import { SortOrder } from "../types";
import { DeviceToiletSensorView } from "../views";

export type ListDevicesToiletSensorParams = {
  limit?: number;
  page?: number;
  search?: string;
  order?: SortOrder;
  orderBy?: string;
  startDate?: string;
  endDate?: string;
};

export type ListDevicesToiletSensorQueryResult = {
  arkiq_devices_history_toilet_sensor_view: DeviceToiletSensorView[];
  arkiq_devices_history_toilet_sensor_view_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export type ListDevicesToiletSensorResponse = {
  devices: DeviceToiletSensorView[];
  totalItems: number;
};

const GET_DEVICES_TOILET_SENSOR_QUERY = (params: ListDevicesToiletSensorParams) => {
  const order = params.order ?? 'desc_nulls_last';
  const orderBy = params.orderBy ?? 'date_time';
  const limit = params.limit ?? 10;
  const page = params.page ?? 1;
  const search = params.search ?? '';

  const offset = (page - 1) * limit;

  const query = search
    ? `order_by: {${orderBy}: ${order}} limit:${limit} offset:${offset}`
    : `order_by: {${orderBy}: ${order}} limit:${limit} offset:${offset}`;

  const aggregateQuery = search
    ? `order_by: {${orderBy}: ${order}}`
    : `order_by: {${orderBy}: ${order}}`;

  let where = '';

  if (search) {
    where += search;
  }

  if (params.startDate || params.endDate) {
    where += 'date_time: {';

    if (params.startDate) {
      where += `_gte: "${params.startDate}",`;
    }
  
    if (params.endDate) {
      where += `_lte: "${params.endDate}",`;
    }

    where += '},';
  }

  return gql`
    query ListDevicesToiletSensor {
      arkiq_devices_history_toilet_sensor_view(${query} where: {${where}}) {
        date_time
        device_id
        flush_status
        volume
      }
      arkiq_devices_history_toilet_sensor_view_aggregate(${aggregateQuery} where: {${where}}) {
        aggregate {
          count
        }
      }
    }
  `;
};

@Injectable({ providedIn: 'root' })
export class DevicesToiletSensorService {
  constructor(private apollo: Apollo) {}

  public async list(params?: ListDevicesToiletSensorParams): Promise<ListDevicesToiletSensorResponse> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<ListDevicesToiletSensorQueryResult>({
          query: GET_DEVICES_TOILET_SENSOR_QUERY(params ?? {}),
        })
      );

      return {
        devices: response.data.arkiq_devices_history_toilet_sensor_view,
        totalItems: response.data.arkiq_devices_history_toilet_sensor_view_aggregate.aggregate.count,
      };
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async listByDeviceId(deviceId: string, params?: ListDevicesToiletSensorParams): Promise<ListDevicesToiletSensorResponse> {
    try {
      const search = `device_id: {_eq: "${deviceId}"}`;

      const response = await firstValueFrom(
        this.apollo.query<ListDevicesToiletSensorQueryResult>({
          query: GET_DEVICES_TOILET_SENSOR_QUERY({ ...params, search }),
        })
      );

      return {
        devices: response.data.arkiq_devices_history_toilet_sensor_view,
        totalItems: response.data.arkiq_devices_history_toilet_sensor_view_aggregate.aggregate.count,
      };
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async getById(deviceId: string): Promise<DeviceToiletSensorView> {
    try {
      const search = `device_id: {_eq: "${deviceId}"}`;

      const response = await firstValueFrom(
        this.apollo.query<ListDevicesToiletSensorQueryResult>({
          query: GET_DEVICES_TOILET_SENSOR_QUERY({ search }),
        })
      );

      return response.data.arkiq_devices_history_toilet_sensor_view[0];
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }
}
