import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  Organization,
  OrganizationsService,
  PropertiesService,
  Property,
  PropertyAndUserByOrganizationResult,
  PropertyTypeLabel,
  User,
  UserRole
} from '@arkiq-portals/sdk';
import {
  ButtonComponent,
  DialogAlertComponent,
  DialogAlertParams,
  DialogAlertVariant,
  IconButtonComponent,
  InputComponent,
  SpinnerComponent,
  TableColumnHeaderButtonComponent,
  TableColumnHeaderFilter,
  TableColumnHeaderFilterType,
  TableColumnHeaderOptions,
} from '@arkiq-portals/ui';
import { Subscription, debounceTime, distinctUntilChanged, filter } from 'rxjs';

@Component({
  selector: 'app-organization-details-properties',
  templateUrl: 'organization-details-properties.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SpinnerComponent,
    ButtonComponent,
    InputComponent,
    IconButtonComponent,
    TableColumnHeaderButtonComponent
  ],
  styles:[`:host {display: flex; flex:1; }`]

})
export class OrganizationDetailsPropertiesComponent implements OnInit, OnDestroy {
  public organization!: Organization;
  public properties: PropertyAndUserByOrganizationResult[] = [];
  public isLoading = false;

  public PROPERTY_TYPES = PropertyTypeLabel;

  public propertyTypesOptions: TableColumnHeaderOptions[] = Object.keys(
    this.PROPERTY_TYPES,
  ).map(type => ({
    label: PropertyTypeLabel[type],
    value: type,
    isSelected: false,
  }));

  public stateHeaderFilter!: TableColumnHeaderFilter
  public cityHeaderFilter!: TableColumnHeaderFilter

  public filterForm = new FormGroup({
    buildingType: new FormControl(),
    state: new FormControl(),
    city: new FormControl(),
    search: new FormControl()
  })

  public buildingTypeHeaderFilter: TableColumnHeaderFilter = {
    type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
    options: this.propertyTypesOptions,
    control: this.filterForm.controls.buildingType
  }

  private subscriptions = new Subscription()

  constructor(
    private activatedRoute: ActivatedRoute,
    private organizationsService: OrganizationsService,
    private propertiesService: PropertiesService,
    private matDialog: MatDialog,
    private router: Router,
    private location: Location,
  ) {}

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  public ngOnInit(): void {
    const state = this.location.getState();

    if (state && state['propertyType']) {
      this.filterForm.patchValue({
        buildingType: [state['propertyType']],
      });
    }

    const routeParams = this.activatedRoute.parent?.snapshot.params;

    const organizationId = Number(routeParams?.['id']);
    if (!isNaN(organizationId)) {
      this.getOrganization(organizationId);
    }

    this.subscriptions.add(
      this.filterForm.valueChanges
        .pipe(
          debounceTime(400),
          distinctUntilChanged(),
          filter(() => !!this.organization),
        )
        .subscribe(() => {
          this.getOrganization(organizationId, true);
        })
    );
  }

  private async getOrganization(organizationId: number, buildJustProperties = false) {
    try {
      this.isLoading = true;

      this.organization = await this.organizationsService.getById(
        organizationId,
      );
      this.properties = await this.propertiesService.propertiesWithDeviceAndUserCountByOrganization({
        organizationId: organizationId,
        city: this.filterForm.value.city,
        state: this.filterForm.value.state,
        propertyType: this.filterForm.value.buildingType,
        search: this.filterForm.value.search
      })

      if(!buildJustProperties) {
        this.buildCityOptions(this.properties.map(property => property.address_city))
        this.buildStateOptions(this.properties.map(property => property.address_state))
      }
    } catch (error: any) {
      console.error(error)
      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to fetch property details',
          text: error?.error?.message || error?.message || error,
        } as DialogAlertParams,
      });
    } finally {
      this.isLoading = false;
    }
  }

  public getPropertyMaster(propertyId: number | string) {
    const property = this.properties.find(property => property.id === propertyId)
    if (!property) return
    const masterRole = property.users_organizations_roles?.find(
      userRole => userRole.role === UserRole.PROPERTY_MASTER,
    );

    return masterRole?.user;
  }

  public onBuildingTypeFilterSelection(event) {
    const selectedBuildingType = event.options
    this.filterForm.patchValue({
      buildingType: selectedBuildingType
    })
  }

  public onStateFilterSelection(event) {
    const selectedState = event.options
    this.filterForm.patchValue({
      state: selectedState
    })
  }

  public onCityFilterSelection(event) {
    const selectedCity = event.options
    this.filterForm.patchValue({
      city: selectedCity
    })
  }

  public onAddProperty() {
    this.router.navigateByUrl(
      '/properties/create',
      {
        state: {
          organizationId: this.organization.id,
          redirectTo: `/organizations/${this.organization.id}/summary`,
        },
      },
    );
  }

  private buildCityOptions(cities: string[]) {
    const cityMap = new Map()
    const cityOptions:TableColumnHeaderOptions[] = cities.map((city, index) => {
      if (!cityMap.get(city.toLowerCase())) {
        cityMap.set(city.toLowerCase(),index + 1)
        return ({
          isSelected: false,
          label: city,
          value: city
        })
      }
      return false
    }).filter((item): item is TableColumnHeaderOptions => Boolean(item))
    this.cityHeaderFilter = {
      type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
      options: cityOptions,
      control: this.filterForm.controls.city
    }

  }
  private buildStateOptions(states: string[]) {
    const stateMap = new Map()
    const stateOptions:TableColumnHeaderOptions[] = states.map((state, index) => {

      if (!stateMap.get(state.toLowerCase())) {
        stateMap.set(state.toLowerCase(),index + 1)
        return ({
          isSelected: false,
          label: state,
          value: state
        })
      }
      return false
    }).filter((item): item is TableColumnHeaderOptions => Boolean(item))


    this.stateHeaderFilter = {
      type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
      options: stateOptions,
      control: this.filterForm.controls.city
    }
  }

  public getUserPhoto(user: User | undefined) {
    if (!user) return `https://ui-avatars.com/api/?background=random&name=ArkIQ`
    return user.photo && user.photo.length > 1 ? user.photo :  `https://ui-avatars.com/api/?background=random&name=${user.firstName}+${user.lastName}`
  }

  public getPropertyPhoto(property: Property) {
    return property.photo ? property.photo : `https://ui-avatars.com/api/?background=random&name=${property.name}`
  }
}
