import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { FixtureType } from '../models';
import { UsersService } from './users.service';

enum AlertsMessage {
  LEAK = 'leak',
  FLOW = 'flow',
  TEMPERATURE = 'temperature',
  HUMIDITY = 'humidity',
  TAMPERING = 'tamper',
  POWER_OUTAGE = 'outage',
}

export type AlertsObject = {
  alert_date_time: string;
  device: {
    type: string;
    id: string
  };
  fixture: {
    name: string
    location: {
      name: string
    };
    type: FixtureType;
  }
  organization: {
    id: number;
    name: string
  }
  property: {
    id: number;
    name: string
  }
  alert_type: {
    name: string
  }
  time_cleared: string;
  cleared: boolean
};

export type ListAlertsParam = {
  limit?: number;
  page?: number;
  leak: boolean;
  flow: boolean;
  temperature: boolean;
  humidity: boolean;
  tampering: boolean;
  power_outage: boolean;
  organizationId?: number[] | null;
  locationId?: number[] | null;
  propertyId?: number[] | null;
  type?: string[] | null;
  onGoing?: boolean;
  deviceId?: string;
};

@Injectable({ providedIn: 'root' })
export class AlertsService {
  constructor(private apollo: Apollo, private usersServices: UsersService) {}

  public async list(
    params: Partial<ListAlertsParam>,
  ): Promise<{ alerts: AlertsObject[]; count: number }> {
    const hasZeroOrganization =
      this.usersServices.loggedUserOrganizations.length === 0;
    const user = this.usersServices.loggedUser;
    const isUserCustomer = this.usersServices.isLoggedUserCustomer;
    const limit = params.limit ?? 10;
    const page = params.page ?? 1;
    const offset = (page - 1) * limit;
    const leak = params.leak ?? false;
    const flow = params.flow ?? false;
    const temperature = params.temperature ?? false;
    const humidity = params.humidity ?? false;
    const tampering = params.tampering ?? false;
    const power_outage = params.power_outage ?? false;
    const paramOrganizationId = params.organizationId ?? [];
    const paramDeviceId = params.deviceId ?? ''
    const paramPropertiesId = params.propertyId ?? [];
    const paramLocationsId = params.locationId ?? [];
    const type = params.type ?? [];
    const alertTypeObject = {
      [AlertsMessage.LEAK]: leak,
      [AlertsMessage.FLOW]: flow,
      [AlertsMessage.TEMPERATURE]: temperature,
      [AlertsMessage.HUMIDITY]: humidity,
      [AlertsMessage.TAMPERING]: tampering,
      [AlertsMessage.POWER_OUTAGE]: power_outage,
    };

    const alertsToFilter = Object.entries(alertTypeObject).reduce(
      (prev, [key, value]) => {
        if (value) {
          return [...prev, key];
        }
        return prev;
      },
      [] as string[],
    );

    const alertsRegex = alertsToFilter.join('|');
    const emptyResponse = { alerts: [], count: 0 };
    if (!user) {
      return emptyResponse;
    }
    if (isUserCustomer && hasZeroOrganization) {
      return emptyResponse;
    }

    const userOrganizationsIds = this.usersServices.loggedUserOrganizations;
    const userPropertiesIds = this.usersServices.loggedUserProperties;

    const organizationsIds = Array.from(
      new Set([...userOrganizationsIds, ...paramOrganizationId]),
    ).join(',');
    const propertiesIds = Array.from(
      new Set([...userPropertiesIds, ...paramPropertiesId]),
    ).join(',');
    const organizationAndPropertyQuery = `
        ${
            paramOrganizationId.length > 0
            ? `organization_id: { _in: [${paramOrganizationId.join(',')}]}`
            : ''
        }
        ${
            paramPropertiesId.length > 0
            ? `property_id: { _in: [${paramPropertiesId.join(',')}]}`
            : ''
        }

    `;

    const query = `
      where: {
          ${organizationAndPropertyQuery}
          ${paramDeviceId ?  `device_id: {_in: ["${paramDeviceId}"]}` : '' }
          ${
            alertsToFilter.length > 0
              ? `
              alert_type: {name: {_iregex: "${alertsRegex}"}}
          `
              : ''
          }
          ${
            isUserCustomer
              ? '_or: {property_id: {_is_null: false}, organization_id: {_is_null: false}}'
              : ''
          }
          ${
            type.length > 0
              ? `device: {type: {_iregex: "${type.join('|')}"}}`
              : ''
          }
          ${
            params.onGoing
            ? "cleared: {_eq: true }"
            : ""
          }

          ${
            paramLocationsId.length > 0
              ? `fixture: {location: {id: {_in: [${paramLocationsId.join(',')}]}}}`
              : ''
          }

        }
    `;

    /**
      ${
          !paramDeviceId ?
            `fixture: {_and: {id: {_is_null: false}, ${paramLocationsId.length > 0 ? `location: {id: {_in: [${paramLocationsId.join(',')}]}}` : ''}}}`
          : ''
      }
     */

    // The count query must be the same as the original query
    // const countQuery = `
    //     where: {
    //       ${organizationAndPropertyQuery}
    //     }
    // `;
    try {
      const response = await firstValueFrom(
        this.apollo.query<{ arkiq_alert_history: AlertsObject[]; count: any[] }>(
          {
            query: gql`
              query ListAlerts {
                arkiq_alert_history(
                  limit: ${limit}
                  offset: ${offset}
                  ${query}
                ) {
                  alert_date_time
                  cleared
                  time_cleared
                  alert_type {
                    name
                  }
                  device {
                    id
                    type
                  }
                  fixture {
                    name
                    id
                    location {
                      name
                    }
                    type
                  }
                  organization {
                    id
                    name
                  }
                  property {
                    id
                    name
                    address_city
                  }
                }

                count: arkiq_alert_history(${query}){
                  device_id
                }
              }
            `,
          },
        ),
      );
      return {
        alerts: response.data.arkiq_alert_history,
        count: response.data.count.length,
      };
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }
}
