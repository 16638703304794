export enum UserType {
  ARKIQ = 'ARKIQ',
  // AFFILIATE = 'AFFILIATE',
  CUSTOMER = 'CUSTOMER',
}

export enum UserTypeLabel {
  ARKIQ = 'arkIQ',
  // AFFILIATE = 'Affiliate',
  CUSTOMER = 'Customer',
}
