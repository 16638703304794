import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Organization, OrganizationsService } from '@arkiq-portals/sdk';
import { InputComponent } from '@arkiq-portals/ui';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';

@Component({
  selector: 'app-select-property-organization',
  templateUrl: 'select-property-organization.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatTooltipModule,
    InputComponent,
  ],
})
export class SelectPropertyOrganizationComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() initialValue?: Organization | Partial<Organization> | null;
  @Input() isInvalid?: boolean;
  @Output() selectOrganization = new EventEmitter<Organization>();
  @Output() createOrganization = new EventEmitter<Partial<Organization>>();
  @Output() resetOrganization = new EventEmitter();

  public searchControl = new FormControl('');

  public isLoading = false;
  public organizations: Organization[] = [];

  private subscription = new Subscription();

  public selectedOrganization?: Organization;

  constructor(private organizationsService: OrganizationsService) {}

  public ngOnInit(): void {
    this.searchOrganizations();
    this.subscription.add(
      this.searchControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe(value => {
          this.searchOrganizations(value ?? '');
        }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {

    if(changes['isInvalid'] && changes['isInvalid'].currentValue === true){
      this.searchControl.markAllAsTouched()
      this.searchControl.setErrors({required: true})
    }

    if (changes['initialValue'] && changes['initialValue'].currentValue) {
      this.selectedOrganization = changes['initialValue']
        .currentValue as Organization;
      this.selectOrganization.emit(
        changes['initialValue'].currentValue as Organization,
      );
    }
  }

  private async searchOrganizations(search?: string) {
    try {
      this.isLoading = true;

      if (search) {
        this.organizations = await this.organizationsService.searchByName(
          search,
        );
      } else {
        const response = await this.organizationsService.list();
        this.organizations = response.organizations;
      }

      if (this.initialValue) {
        this.selectedOrganization = this.initialValue as Organization;
        this.selectOrganization.emit(this.initialValue as Organization);
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  }

  public onOrganizationSelected(event: MatAutocompleteSelectedEvent) {
    const organization = this.organizations.find(
      u => u.id === event.option.value,
    );

    if (organization) {
      this.selectedOrganization = organization;
      this.selectOrganization.emit(organization);
    } else {
      this.searchControl.setValue('');
    }
  }

  public handleCreateOrganization() {
    this.createOrganization.emit();
  }

  public handleRemoveSelection() {
    this.selectedOrganization = undefined;
    this.searchControl.setValue('');
    this.resetOrganization.emit();
  }
}
