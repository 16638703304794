<div *ngIf="isLoading" class="w-full h-full flex items-center justify-center">
  <arkiq-spinner />
</div>

<div *ngIf="!isLoading" class="w-full h-full flex justify-center">
  <div class="max-w-[527px] w-full bg-white h-full rounded-xl">
    <header class="flex items-center gap-2 py-5 px-6">
      <arkiq-icon-button icon="bi-chevron-left" (click)="handleGoBack()" />

      <h2 class="m-0 font-bold text-base">
        {{ user ? 'Edit User' : 'Add New User' }}
      </h2>
    </header>

    <app-select-user-photo-button
      [user]="user"
      (selectFile)="form.controls.photo.setValue($event)"
    />

    <main class="w-full flex flex-col gap-[30px] py-6">
      <section class="w-full flex flex-col gap-[30px] px-6">
        @if (isUserArkIq()) {
        <arkiq-select
          label="User Type"
          [required]="true"
          size="md"
          [rounded]="false"
          [control]="form.controls.type"
          [options]="userTypeOptions"
        />
        }

        <fieldset class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <arkiq-input
            label="First Name"
            [required]="true"
            placeholder="First Name"
            size="md"
            [rounded]="false"
            [control]="form.controls.firstName"
          />

          <arkiq-input
            label="Last Name"
            [required]="true"
            placeholder="Last Name"
            size="md"
            [rounded]="false"
            [control]="form.controls.lastName"
          />
        </fieldset>

        <fieldset class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <arkiq-input
            label="Email Address"
            [required]="true"
            placeholder="Email Address"
            size="md"
            [rounded]="false"
            [control]="form.controls.email"
          />

          <arkiq-input
            label="Phone Number"
            [required]="true"
            placeholder="Valid to receive SMS"
            size="md"
            [rounded]="false"
            [control]="form.controls.phoneNumber"
          />
        </fieldset>
      </section>

      <section class="w-full">
        <app-user-organization-role-form
          [user]="user"
          [form]="userRolesForm"
          [roles]="roles"
          (addProperty)="onAddProperty($event)"
        />
      </section>
    </main>

    <footer
      class="flex items-center justify-end gap-2 px-6 py-[18px] border-t border-t-[#E0E0E0]"
    >
      <arkiq-button
        text="Cancel"
        size="md"
        variant="tertiary"
        [rounded]="false"
        [disabled]="isSaving"
        (click)="handleGoBack()"
      />

      <arkiq-button
        [text]="user ? 'Save User' : 'Add User'"
        size="md"
        variant="primary"
        [rounded]="false"
        [disabled]="
          isSaving || (userRolesForm.value && userRolesForm.value.length === 0) || userRolesForm.invalid
        "
        [loading]="isSaving"
        (click)="handleSave()"
      />
    </footer>
  </div>
</div>
