import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RateAlert } from '../rate-alerts/rate-alerts.component';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rate-card',
  templateUrl: 'rate-card.component.html',
})
export class RateCardComponent implements OnInit {
  @Input({ required: true }) alert!: RateAlert;
  constructor() {}

  ngOnInit() {}
}
