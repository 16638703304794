import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonComponent,
  IconButtonComponent,
  InputComponent,
} from '../../components';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';

// export interface DialogDeleteLocationData extends Omit<TreeNode, 'isCollapsed' | 'data'> {}

export type DialogDeleteLocationData = any;

@Component({
  selector: 'arkiq-dialog-delete-location',
  templateUrl: './dialog-delete-location.component.html',
  standalone: true,
  imports: [CommonModule, IconButtonComponent, InputComponent, ButtonComponent],
})
export class DialogDeleteLocationComponent {
  public nameControl = new FormControl('', [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<DialogDeleteLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDeleteLocationData,
  ) {}

  public handleDeletion() {
    if (!(this.nameControl.value === this.data.text)) {
      alert('Name is not equal');
      return;
    }
    console.log('DELETE');
    this.dialogRef.close();
  }

  public get hasChildren() {
    return this.data.children.length > 0;
  }
}
