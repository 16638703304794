import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { SortOrder, UserRole, UsersService } from '@arkiq-portals/sdk';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import { generateCsv, getColumnsToExport } from '../../utils';
import { ButtonComponent, ButtonVariants } from '../button/button.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { InputComponent } from '../input/input.component';
import { SelectComponent, SelectOption } from '../select/select.component';
import {
  TableColumnHeaderButtonComponent,
  TableColumnHeaderFilter,
  TableColumnHeaderFilterType,
  TableColumnHeaderMenuCloseEvent,
} from './table-column-header-button/table-column-header-button.component';

export enum TableColumnType {
  TEXT = 'TEXT',
  ACTIONS = 'ACTIONS',
  PICTURE_AND_TEXT = 'PICTURE_AND_TEXT',
  PICTURE = 'PICTURE_AND_ALT',
  BADGE = 'BADGE',
  SHOW_MORE = 'SHOW_MORE',
}

export type TableColumnPictureAndAlt = {
  picture: string;
  alt: string;
  isHyperlink: boolean;
};

export type TableColumnPictureAndTextValue = {
  picture: string;
  pictureClasses?: string;
  text: string;
  textClasses?: string;
  valueClasses?: string;
  isHyperlink: boolean;
};

export enum BadgeIconPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export type TableColumnShowMore = {
  first: string;
  more: Array<string | number>;
};

export type TableColumnBadge = {
  icon: `bi-${string}`;
  iconPosition?: BadgeIconPosition;
  badgeStatus?: string;
};

export type TableColumnActionButton<TData = any> = {
  type: 'icon-button' | 'default-button';
  variant?: ButtonVariants;
  text?: string;
  textFn?: (data: TData) => string;
  icon?: `bi-${string}`;
  tooltip?: string;
  handler: (data: TData) => void;
  allowedRoles?: UserRole[];
};

export type TableColumn<TData = any> = {
  id?: string;
  sortable?: boolean;
  type: TableColumnType;
  label: string;
  headerClasses?: string;
  headerPicture?: string;
  valueKey?: keyof TData;
  valueFn?: (data: TData) => string;
  pictureAndTextValueFn?: (data: TData) => TableColumnPictureAndTextValue;
  valueClasses?: string;
  valueClassesFn?: (data: TData) => string;
  pictureAndAlt?: (data: TData) => TableColumnPictureAndAlt;
  actionButtons?: TableColumnActionButton<TData>[];
  badge?: (data: TData) => TableColumnBadge;
  allowedRoles?: UserRole[];
  showMoreObject?: (data: TData) => TableColumnShowMore;
  filter?: TableColumnHeaderFilter;
  columnLink?: (data: TData) => { url: string; state: any; disabled?: boolean; };
};

export type TableHeaderButton = {
  type: 'icon-button' | 'default-button';
  variant?: ButtonVariants;
  text?: string;
  icon?: `bi-${string}`;
  tooltip?: string;
  handler: () => void;
  allowedRoles?: UserRole[];
};

export type TableHeaderFilter = {
  text: string;
  options: SelectOption[];
  control: FormControl<any>;
};

export type TableRowClickedEvent<TData> = {
  rowIndex: number;
  data: TData;
};

export type TableSortChangeEvent = {
  columnId: string;
  order: SortOrder;
};

export enum ClearFiltersTypes  {
  BUTTON = "BUTTON",
  TEXT = "TEXT"
}

@Component({
  selector: 'arkiq-table',
  templateUrl: 'table.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTooltipModule,
    ButtonComponent,
    InputComponent,
    IconButtonComponent,
    SelectComponent,
    TableColumnHeaderButtonComponent,
  ],
  styles: [":host{flex: 1;}"]
})
export class TableComponent<TData = any> implements OnInit, OnDestroy {
  @Input() label = 'Item(s)';
  @Input() isLoading = false;
  @Input() columns: TableColumn<TData>[] = [];
  @Input() data: TData[] = [];
  @Input() enableSearch = false;
  @Input() searchInputLabel = 'Search by ID';
  @Input() enableDownloadCsv = false;
  @Input() enableClearAllFilters = false;
  @Input() buttons: TableHeaderButton[] = [];
  @Input() totalPages = 0;
  @Input() totalItems = 0;
  @Input() hideTitle = false;
  @Input() hideItemsPerPageToggle = false;
  @Input() selectionMode: 'NONE' | 'SINGLE' | 'MULTIPLE' = 'NONE';
  @Input() rolesAllowedToSelect = Object.values(UserRole);
  @Input() paginationText = ""
  @Input() customHeaderClasses = ""
  @Input() maxHeightPageClasses = ""
  @Input() clearFiltersType: ClearFiltersTypes = ClearFiltersTypes.BUTTON
  @Input() showPaginationText = true;

  @Output() searchChanged = new EventEmitter<string>();
  @Output() rowClicked = new EventEmitter<TableRowClickedEvent<TData>>();
  @Output() rowsLimitChanged = new EventEmitter<number>();
  @Output() pageChanged = new EventEmitter<number>();
  @Output() selectedRowsChanged = new EventEmitter<TData[]>();
  @Output() allRowsSelected = new EventEmitter();
  @Output() sortOrderChanged = new EventEmitter<TableSortChangeEvent>();
  @Output() clearAllFilters = new EventEmitter();

  public USER_ROLES = UserRole;
  public CLEAR_ALL_FILTER_BUTTON_TYPE = ClearFiltersTypes

  public rowsLimitControl = new FormControl('50');
  public pageControl = new FormControl(1);
  public searchControl = new FormControl('');
  public pageInputControl = new FormControl(1);

  private subscriptions = new Subscription();

  public selectedRows: TData[] = [];

  constructor(
    private usersService: UsersService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.searchControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe(value => this.searchChanged.emit(value ?? '')),
    );

    this.subscriptions.add(
      this.pageInputControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe(value => this.pageInputChange(value)),
    );

    this.subscriptions.add(
      this.rowsLimitControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe(value => this.rowsLimitChanged.emit(Number(value))),
    );

    this.subscriptions.add(
      this.pageControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe(value => this.pageChanged.emit(Number(value))),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public handleExportAsCsv() {
    const columnsToExport = getColumnsToExport(this.columns);

    const encodedUri = generateCsv({
      columns: columnsToExport,
      data: this.data ?? [],
    });

    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `table-data-${Date.now()}.csv`);
    link.click();
  }

  public onLabelCheckboxClicked(event) {
    event.stopPropagation();
  }

  public handleRowClicked(rowIndex: number, data: TData) {
    this.rowClicked.emit({ rowIndex, data });
  }

  public handleRowSelected(row: TData) {
    const isAlreadySelected = !!this.selectedRows.find(
      item => item['id'] === row['id'],
    );

    switch (this.selectionMode) {
      case 'SINGLE':
        this.selectedRows = isAlreadySelected ? [] : [row];
        break;

      case 'MULTIPLE':
        this.selectedRows = isAlreadySelected
          ? this.selectedRows.filter(item => item['id'] !== row['id'])
          : [...this.selectedRows, row];
        break;
    }

    this.selectedRowsChanged.emit(this.selectedRows);
  }

  public handleSelectAllRows() {
    if (this.selectionMode !== 'MULTIPLE') {
      return;
    }

    if (
      (this.selectedRows.length > 0 &&
        this.selectedRows.length < this.data.length) ||
      this.selectedRows.length === 0
    ) {
      this.selectedRows = [...this.data];
    } else {
      this.selectedRows = [];
    }

    // this.selectedRowsChanged.emit(this.selectedRows);
    this.allRowsSelected.emit()
  }

  public handleSort(column: TableColumn<TData>, order: SortOrder) {
    if (!column.sortable || !column.id || this.isLoading) {
      return;
    }

    this.sortOrderChanged.emit({ columnId: column.id, order });
    this.selectedRowsChanged.emit(this.selectedRows);
  }

  public validateUserPermission(
    allowedRoles: UserRole[] = this.ALL_USER_ROLES,
  ) {
    return this.usersService.validateUserPermission(allowedRoles);
  }

  public isRowSelected(row: TData): boolean {
    return !!this.selectedRows.find(item => item['id'] === row['id']);
  }

  public get pages() {
    if (this.totalPages < 5) {
      const pages: number[] = [];

      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }

      return pages;
    }

    if (Number(this.pageControl.value) < 5) {
      return [1, 2, 3, 4, 5];
    }

    if (Number(this.pageControl.value) > this.totalPages - 3) {
      return [
        this.totalPages - 4,
        this.totalPages - 3,
        this.totalPages - 2,
        this.totalPages - 1,
        this.totalPages,
      ];
    }

    return [
      Number(this.pageControl.value) - 2,
      Number(this.pageControl.value) - 1,
      Number(this.pageControl.value),
      Number(this.pageControl.value) + 1,
      Number(this.pageControl.value) + 2,
    ];
  }

  public nextPage() {
    const currentPage = this.pageControl.value;
    if (
      !currentPage ||
      currentPage === this.totalPages ||
      this.totalPages === 0
    )
      return;
    this.pageControl.setValue(currentPage + 1);
  }

  public previousPage() {
    const currentPage = this.pageControl.value;
    if (!currentPage || currentPage <= 1 || this.totalPages === 0) return;
    this.pageControl.setValue(currentPage - 1);
  }

  public pageInputChange(value) {
    const currentPage = this.pageControl.value || 1;
    if (value === currentPage || this.totalPages === 0) return;

    if (Number(value) > this.totalPages || Number(value) < 1) {
      this.pageInputControl.patchValue(this.pageControl.value);
      return;
    }

    this.pageControl.setValue(Number(value));
  }

  public onColumnHeaderMenuClosed(
    column: TableColumn,
    event: TableColumnHeaderMenuCloseEvent,
  ) {
    const columnIndex = this.columns.findIndex(
      curColumn => curColumn.id === column.id,
    );

    const filter = this.columns[columnIndex]
      .filter as TableColumnHeaderFilter;

    if (
      this.columns[columnIndex].filter &&
      this.columns[columnIndex].filter?.type === TableColumnHeaderFilterType.MULTIPLE_SELECT
    ) {
      filter.options = filter.options.map(option => {
        option.isSelected = event.options.includes(option.value);
        return option;
      });

      this.columns[columnIndex].filter = filter;

      filter.control.setValue(event.options);
    }

    if (
      this.columns[columnIndex].filter &&
      this.columns[columnIndex].filter?.type === TableColumnHeaderFilterType.DATE_RANGE
    ) {
      // (filter.control as FormGroup).controls['startDate'].setValue(event.startDate);
      // (filter.control as FormGroup).controls['endDate'].setValue(event.startDate);
      (filter.control as FormGroup).patchValue({
        startDate: event.startDate,
        endDate: event.endDate,
      });

      console.log('change value', filter.control.value);
    }

    if (event.sort) {
      this.handleSort(column, event.sort);
    }
  }

  public getSelectedFiltersCount(column: TableColumn) {
    if (!column.filter) {
      return 0;
    }

    return column.filter.options.filter(option => option.isSelected).length;
  }

  public get ALL_USER_ROLES() {
    return Object.values(this.USER_ROLES);
  }

  public handleClearAllFilters() {
    for (const column of this.columns) {
      if (column.filter?.options.length) {
        for (const option of column.filter.options) {
          option.isSelected = false;
        }
        column.filter.control.setValue([], { emitEvent: false });
      }
    }
    this.searchControl.setValue('');
    this.clearAllFilters.emit();
  }

  public get paginationTextFormatted() {
    if (!this.showPaginationText) {
      return '';
    }

    const itemCounter =  this.totalItems || this.data.length
    if (this.paginationText) return `${this.paginationText}: ${itemCounter}`
    return `Filtered Results: ${itemCounter}`
  }

  public showHeader() {
    return !this.hideTitle || this.enableSearch || this.enableDownloadCsv || this.enableClearAllFilters || this.buttons.length > 0
  }

  public handleGoToColumnLink(column: TableColumn<TData>, row: TData) {
    if (!column.columnLink) {
      return;
    }

    const { url, state, disabled } = column.columnLink(row);

    if (disabled) {
      return;
    }

    this.router.navigateByUrl(url, { state });
  }

  public getCellTooltip(column: TableColumn<TData>, item: TData): string {
    let text = '';
    let prefix = '';

    if (column.columnLink && !column.columnLink(item).disabled) {
      prefix = 'Go to ';
    }

    if (column.valueFn) {
      text = column.valueFn(item);
    }

    if (column.valueKey) {
      text = item[column.valueKey] as string;
    }

    return prefix + text;
  }
}
