import { Route } from '@angular/router';
import { AuthComponent } from './auth.component';
import { AuthForgotPasswordEmailComponent } from './pages/forgot-password-email/forgot-password-email.component';
import { AuthForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { AuthResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AuthSignInComponent } from './pages/sign-in/sign-in.component';

export const routes: Route[] = [
  {
    path: '',
    pathMatch: 'prefix',
    component: AuthComponent,
    // canActivate: [IsLoggedInGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'sign-in',
      },
      {
        path: 'sign-in',
        component: AuthSignInComponent,
      },
      {
        path: 'forgot-password',
        component: AuthForgotPasswordComponent,
      },
      {
        path: 'forgot-password-email',
        component: AuthForgotPasswordEmailComponent,
      },
      {
        path: 'reset-password',
        component: AuthResetPasswordComponent,
      },
    ],
  },
];
