<div *ngIf="isLoading" class="w-full py-10 flex items-center justify-center">
  <arkiq-spinner />
</div>

<div *ngIf="!isLoading" class="w-full border-t border-t-[#E0E0E0] flex flex-col gap-6 p-6 pb-0">
  <header class="font-semibold text-xl required">
    Assign Role(s)
  </header>

  <main class="w-full flex flex-col gap-6">
    <app-user-organization-role-form-line
      *ngFor="let control of form.controls; let index = index;"
      [organizations]="organizations"
      [enableRemoveButton]="index > 0"
      [form]="control"
      (addProperty)="onAddProperty($event)"
      (removeLine)="onRemoveLine(index)"
      [roles]="roles"
    />
  </main>

  <footer class="pt-6 border-t border-t-black/20 border-dashed">
    <arkiq-button
      text="Assign Another Role"
      size="md"
      [rounded]="false"
      variant="outline-primary"
      (click)="handleAddAnotherRole()"
    />
  </footer>
</div>
