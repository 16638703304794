import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DevicesOverviewService, PropertyLayout, PropertyLayoutTreeItem } from '@arkiq-portals/sdk';
import {
  ButtonComponent,
  IconButtonComponent,
  InputComponent,
} from '../../components';
import { MatTooltipModule } from '@angular/material/tooltip';

export type DialogRemoveLocationParams = {
  node: PropertyLayoutTreeItem
};

@Component({
  selector: 'arkiq-dialog-remove-location',
  templateUrl: './dialog-remove-location.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatTooltipModule,
    IconButtonComponent,
    InputComponent,
    ButtonComponent,
  ],
})
export class DialogRemoveLocationComponent implements OnInit {
  public node!: PropertyLayoutTreeItem;
  public locationsIds: number[] = []
  public deviceCounter: number = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogRemoveLocationParams,
    public dialogRef: MatDialogRef<DialogRemoveLocationComponent>,
    public deviceOverviewSevices: DevicesOverviewService
  ) {}

  ngOnInit(): void {
    this.node = {
      ...this.data.node,
      isCollapsed: true
    }

    this.locationsIds = this.getAllSubLocationsIds(this.node)
    this.getDeviceCount()
  }


  public async getDeviceCount() {
    const deviceAmount = await this.deviceOverviewSevices.deviceCountByLocationId(this.locationsIds)
    this.deviceCounter = deviceAmount
  }

  public cancelDeletion() {
    this.dialogRef.close({ confirm: false })
  }
  public confirmDeletion() {
    this.dialogRef.close({ confirm: true })
  }

  public nodeHasChild(node: PropertyLayoutTreeItem): boolean {
    return node.children.length > 0
  }

  public nodeName(node:PropertyLayout):string {
    return node.l_name
  }

  public changeCollapse() {
    this.node.isCollapsed = !this.node.isCollapsed
  }

  public generateArray(number) {
    return new Array(number)
  }

  private getAllSubLocationsIds(node: PropertyLayoutTreeItem): number[] {
    const ids: number[] = [];

    ids.push(node.l_id);

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        const childIds = this.getAllSubLocationsIds(child);
        ids.push(...childIds);
      }
    }

    return ids;
  }
}
