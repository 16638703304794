import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ButtonComponent, HeaderComponent, InputComponent, Tab, TabsComponent } from '@arkiq-portals/ui';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: 'users.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderComponent,
    TabsComponent,
    InputComponent,
    ButtonComponent,
  ],
})
export class UsersComponent {
  public tabs: Tab[] = [
    { route: '/users', text: 'View Users' },
    { route: '/users/manage-roles-permissions', text: 'Manage Roles Permissions' },
  ];
}
