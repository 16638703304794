<div class="bg-white flex flex-col rounded-[20px] max-w-[480px] w-full">
  <header class="p-7">
    <h4 class="text-2xl font-bold">View Device Keys</h4>
    <p>Enter the OTP code to View/Edit device keys</p>
  </header>

  <main
    class="rounded-xl border border-[#00000026] p-4 flex flex-col justify-center items-cente gap-4 mx-auto"
  >
    <div class="flex items-center justify-between w-full gap-4">
      <i
        class="bi bi-key-fill border border-[#0000001A] rounded-[7px] text-[28px] text-[#AAA] p-2"
      ></i>
      <span class="font-bold">DevEUI</span>
      <span>XXXX-XXXX-XXXX-XXXX</span>
    </div>

    <div class="flex items-center justify-between w-full gap-4">
      <i
        class="bi bi-key-fill border border-[#0000001A] rounded-[7px] text-[28px] text-[#AAA] p-2"
      ></i>
      <span class="font-bold">AppEUI</span>
      <span>XXXX-XXXX-XXXX-XXXX</span>
    </div>

    <div class="flex items-center justify-between w-full gap-4">
      <i
        class="bi bi-key-fill border border-[#0000001A] rounded-[7px] text-[28px] text-[#AAA] p-2"
      ></i>
      <span class="font-bold">AppKEY</span>
      <span>XXXX-XXXX-XXXX-XXXX</span>
    </div>
  </main>

  <footer class="border-y border-[#E0E0E0] p-7 mt-9">
    <p class="font-semibold text-sm">An OTP code has been sent to [Number]</p>
    <label
      for="otpcode"
      class="w-full focus-within border border-black/15 p-4 text-sm flex items-center rounded-lg mt-3"
    >
      <input
        type="text"
        placeholder="OTP Code"
        class="w-full outline-none text-base bg-transparent"
      />
      <button id="otpcode" class="text-primary underline text-sm ml-auto">
        Resend
      </button>
    </label>
  </footer>

  <div class="flex items-center justify-between p-7">
    <arkiq-button
      text="Cancel"
      variant="tertiary"
      size="md"
      [rounded]="false"
      (click)="dialogRef.close()"
    />
    <arkiq-button
      text="Confirm"
      variant="primary"
      size="md"
      [rounded]="false"
      (click)="handleConfirmDeviceKeysModal()"
    />
  </div>
</div>
