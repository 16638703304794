import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { format, startOfDay, startOfMonth, subDays, subMonths } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import { UsersService } from './users.service';

export type TotalCounterResponse = {
  alerts: {
    aggregate: {
      count: number;
    };
  };
  organizations: {
    aggregate: {
      count: number;
    };
  };
  properties: {
    aggregate: {
      count: number;
    };
  };
  devices: {
    aggregate: {
      count: number;
    };
  };

  lastMonthOrganizations: {
    aggregate: {
      count: number;
    };
  };

  lastMonthProperties: {
    aggregate: {
      count: number;
    };
  };
};

export type AlertsResponse = {
  time: string;
  device: {
    properties: {
      address_city: string;
      address_state: string;
      address_street: string;
      fixture: {
        name: string;
      };
    };
  };
  organization_id: string;
  property_id: string;
  type: string;
  alert_type: {
    name: string;
  }
};

export type DevicesRateAlerts = {
  temperature: {
    aggregate: {
      count: number;
    };
  };
  tamper: {
    aggregate: {
      count: number;
    };
  };
  leak: {
    aggregate: {
      count: number;
    };
  };
  humidity: {
    aggregate: {
      count: number;
    };
  };
  total: {
    aggregate: {
      count: number;
    };
  };
};

export type PropertyAlertsCounter = {
  name: string;
  photo: string;
} & DevicesRateAlerts;

export type RateAlertsResponse = {
  name: string;
  photo: string;
  alerts: {
    tamper: number;
    temperature: number;
    leak: number;
    humidity: number;
    total: number;
  }
};

@Injectable({ providedIn: 'root' })
export class HomeDashboardService {
  private get ISOString() {
    return "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
  }
  constructor(private apollo: Apollo, private usersService: UsersService) {}

  private get lastMonthISOString(): string {
    const today = new Date();
    const lastMonth = subMonths(today, 1);
    const firstDayOfLastMonth = startOfMonth(lastMonth);
    const formattedDate = format(firstDayOfLastMonth, this.ISOString);

    return formattedDate;
  }

  private get lastWeekISOString(): string {
    const today = new Date();
    const lastWeek = subDays(today, 7);
    const firstHour = startOfDay(lastWeek);
    const formattedDate = format(firstHour, this.ISOString);
    return formattedDate;
  }

  public async totalCounter(): Promise<TotalCounterResponse> {
    try {
      const hasZeroOrganization =
        this.usersService.loggedUserOrganizations.length === 0;
      const user = this.usersService.loggedUser;
      const isUserCustomer = this.usersService.isLoggedUserCustomer;
      const emptyResponse = {
        alerts: {
          aggregate: {
            count: 0,
          },
        },
        organizations: {
          aggregate: {
            count: 0,
          },
        },
        properties: {
          aggregate: {
            count: 0,
          },
        },
        devices: {
          aggregate: {
            count: 0,
          },
        },
        lastMonthOrganizations: {
          aggregate: {
            count: 0,
          },
        },
        lastMonthProperties: {
          aggregate: {
            count: 0,
          },
        },
      };
      if (!user) {
        return emptyResponse;
      }
      if (isUserCustomer && hasZeroOrganization) {
        return emptyResponse;
      }

      const lastMonthQuery = `created_at: {
        _gte: "${this.lastMonthISOString}"
      }`;

      const organizationsIds = this.usersService.loggedUserOrganizations.join(',');
      const propertiesIds = this.usersService.loggedUserProperties.join(',');

      const organizationIdWhere = `organization_id: {_in: [${organizationsIds}]}`;
      const onlyDevicesFromUserOrganizations = `organization_id: {_in:[${organizationsIds}]}`

      const query = `query DashboardCounter {
        organizations: front_organizations_aggregate  {
          aggregate {
            count
          }
        }

        lastMonthOrganizations: front_organizations_aggregate (where: {
          ${lastMonthQuery}

        })  {
          aggregate {
            count
          }
        }
        properties: front_properties_aggregate {
          aggregate {
            count
          }
        }

        lastMonthProperties: front_properties_aggregate (where: {
          ${lastMonthQuery}
        }) {
          aggregate {
            count
          }
        }

        devices: arkiq_devices_overview_aggregate  {
          aggregate {
            count
          }
        }

        alerts: arkiq_alert_history_aggregate {
          aggregate {
            count
          }
        }
      }
      `;
      const response = await firstValueFrom(
        this.apollo.query<TotalCounterResponse>({
          query: gql`
            ${query}
          `,
        }),
      );
      return response.data;
    } catch (error: any) {
      console.log('erro totalCounter', error);
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async alerts(): Promise<AlertsResponse[]> {
    try {
      const hasZeroOrganization =
        this.usersService.loggedUserOrganizations.length === 0;
      const user = this.usersService.loggedUser;
      const isUserCustomer = this.usersService.isLoggedUserCustomer;

      if (!user) {
        return [];
      }

      if (isUserCustomer && hasZeroOrganization) {
        return [];
      }
      const organizationsIds =
        this.usersService.loggedUserOrganizations.join(',');
      const organizationsByUser = isUserCustomer
        ? `_in: [${organizationsIds}]`
        : '';

      const response = await firstValueFrom(
        this.apollo.query<{ last_alerts: AlertsResponse[] }>({
          query: gql`
            query LastAlertsQuery {
              last_alerts: arkiq_alert_history(
                limit: 10
                order_by: { alert_date_time: desc_nulls_last }
                where: {
                  device: {
                    organization_id: {
                      _is_null: false,
                      ${organizationsByUser}
                    }

                  }
                  alert_date_time: {
                    _gte: "${this.lastWeekISOString}"
                  }
                }

              ) {
                id
                time: alert_date_time
                alert_type {
                  name
                }
                organization_id
                property_id
                device {
                  properties {
                    address_city
                    address_state
                    address_street
                    fixture {
                      name
                    }
                  }
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
        }),
      );
      return response.data.last_alerts;
    } catch (error: any) {
      console.log('erro alerts', error);
      throw new Error(error?.error?.message || error?.message | error);
    }
  }

  public async rateAlerts(): Promise<RateAlertsResponse[]> {
    try {
      const hasZeroOrganization =
        this.usersService.loggedUserOrganizations.length === 0;
      const user = this.usersService.loggedUser;
      const isUserCustomer = this.usersService.isLoggedUserCustomer;

      if (!user) {
        return [];
      }

      if (isUserCustomer && hasZeroOrganization) {
        return [];
      }
      const organizationsIds =
        this.usersService.loggedUserOrganizations.join(',');
      const organizationsByUser = isUserCustomer
        ? `{organizationId: {_in:  [${organizationsIds}]}}`
        : '{}';

      const response = await firstValueFrom(
        this.apollo.query<{
          rate_alerts: PropertyAlertsCounter[];
        }>({
          query: gql`
          query RateAlerts {
            rate_alerts: front_properties(where: ${organizationsByUser}) {
              name
              photo
              tamper: alerts_aggregate(where: {alert_type: {name: {_ilike: "%tamper%"}}}) {
                aggregate {
                  count
                }
              }

              temperature: alerts_aggregate(where: {alert_type: {name:  {_ilike: "%temperature%"} }}) {
                aggregate {
                  count
                }
              }


              humidity: alerts_aggregate(where: {alert_type: {name:  {_ilike: "%humidity%"} }}) {
                aggregate {
                  count
                }
              }

              leak: alerts_aggregate(where: {alert_type: {name: {_ilike: "%leak%"} }}) {
                aggregate {
                  count
                }
              }

              total: alerts_aggregate {
                aggregate {
                  count
                }
              }
            }
          }

          `,
          fetchPolicy: 'no-cache',
        }),
      );

      return response.data.rate_alerts.map(alert => ({
        name: alert.name,
        photo: alert.photo,
        alerts: {
          tamper: alert.tamper.aggregate.count ?? 0,
          temperature: alert.temperature.aggregate.count ?? 0,
          leak: alert.leak.aggregate.count ?? 0,
          humidity: alert.humidity.aggregate.count ?? 0,
          total: alert.total.aggregate.count ?? 0,
        },
      }));
    } catch (error: any) {
      console.log('rateAlerts', error);
      throw new Error(error?.error?.message || error?.message | error);
    }
  }
}
