@if(isLoading) {
<div class="w-full h-full items-center justify-center gap-2">
  <arkiq-spinner />
  <span class="text-gray-500">Loading device data...</span>
</div>
} @else {
<section class="flex flex-col items-center gap-y-5">
  <div
    class="flex justify-center lg:justify-evenly items-center w-full gap-x-4 flex-wrap gap-y-4"
  >
    <arkiq-text-card-icon
      title="Current Connectivity"
      leftIcon="bi-sim-fill"
      description="SIM"
      subtitle="(Primary: WiFi)"
    />
    <arkiq-text-card-icon
      title="Current Power Source"
      leftIcon="bi-battery-full"
      description="Battery"
      subtitle="(Primary: Outlet)"
      iconClasses="-rotate-90"
    />
    <div
      class="py-[10px] px-7 shadow-lg rounded-md flex flex-col gap-9 shrink-0"
    >
      <header class="flex items">
        <p class="text-base font-semibold text-center">
          Gateway Funcionality
          <button
            class="text-[#00B4FF] text-sm font-medium underline"
            (click)="handleUpgradeGatewayTextClick()"
          >
            (Upgrade Gateway)
          </button>
        </p>
      </header>
      <main class="flex gap-2 justify-between">
        <div class="grid grid-rows-2 gap-y-2">
          <span class="font-semibold text-[#000] min-w-[115px] self-end"
            >Connectivity</span
          >
          <span class="font-semibold text-[#000] min-w-[115px] self-end"
            >Power Source</span
          >
        </div>
        <div class="grid grid-cols-3 grid-rows-2 gap-y-2">
          <span class="text-[#999] flex flex-col items-center">
            WiFi
            <i class="bi bi-check text-[#35C84D] text-[22px]"></i>
          </span>
          <span class="text-[#999] flex flex-col items-center">
            SIM
            <i class="bi bi-check text-[#35C84D] text-[22px]"></i>
          </span>
          <span class="text-[#999] flex flex-col items-center">
            Cable
            <i class="bi bi-x text-[#D85B53] text-[22px]"></i>
          </span>
          <span class="text-[#999] flex flex-col items-center">
            Battery
            <i class="bi bi-check text-[#35C84D] text-[22px]"></i>
          </span>
          <span class="text-[#999] flex flex-col items-center">
            Outlet
            <i class="bi bi-check text-[#35C84D] text-[22px]"></i>
          </span>
          <span class="text-[#999] flex flex-col items-center">
            Solar
            <i class="bi bi-check text-[#35C84D] text-[22px]"></i>
          </span>
        </div>
      </main>
    </div>
  </div>

  <arkiq-table [columns]="tableColumns" [data]="data" [hideTitle]="true" />
</section>
}

<div class="grid row-2"></div>
