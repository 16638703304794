@if (isLoading) {
<div class="w-full h-[500px] flex items-center justify-center gap-2">
  <arkiq-spinner />
  <p class="text-gray-500">Loading...</p>
</div>
} @else { @if (selectedNodePath.length > 0) {
<div
  class="min-h-[280px] bg-white shadow-lg flex flex-col rounded-[18px] xl:col-span-1"
>
  <header
    class="border-b border-b-[#E0E0E0] border-dashed flex items-center gap-[6px] px-5 py-3"
  >
    <section
      class="flex-1 flex items-center gap-[6px]"
      [matTooltip]="fullSelectedNodePathLabels.join(' > ')"
    >
      @for (nodeLabel of compactSelectedNodePathLabels; track nodeLabel; let isLast = $last) {
        <span
          class="font-semibold text-[14px] px-2 py-1 rounded-[5px]"
          [ngClass]="[isLast ? 'text-primary bg-primary/10' : '']"
        >
          {{ nodeLabel }}
        </span>

        @if (!isLast) {
          <i class="bi bi-chevron-right text-[10px] text-[#BBBBBB]"></i>
        }
      }
    </section>

    <section
      *hasRole="[USER_ROLES.ORGANIZATION_MASTER]"
      class="flex items-center gap-[6px]"
    >
      <arkiq-button
        variant="outline-primary"
        text="View Alerts"
        size="md"
        [rounded]="false"
      />

      <app-add-fixture-or-location-button
        (addLocation)="handleAddLocation()"
        (addFixture)="handleAddFixture()"
      />
    </section>
  </header>

  <section
    class="w-full flex items-center justify-around py-3 border-b border-b-[#E0E0E0]"
  >
    <div class="flex items-center gap-1">
      <span>Total Fixtures:</span>
      <span>{{ totalSubFixtures }}</span>
    </div>

    <div class="flex items-center gap-1">
      <i class="bi bi-check-circle text-state-completed text-[19px]"></i>
      <span>Protected:</span>
      <span>{{ totalSubProtectedFixtures }}</span>
    </div>

    <div class="flex items-center gap-1">
      <i class="bi bi-x-circle text-state-cancel text-[19px]"></i>
      <span>Unprotected:</span>

      <span class="text-state-cancel font-medium">
        {{ totalSubUnprotectedFixtures }}
      </span>
    </div>
  </section>

  <main class="w-full flex flex-col gap-4 p-5">
    @if (isFixturesCollapsed) {
    <div
      class="p-4 w-full flex items-center justify-between border border-[#E0E0E0] rounded-[18px]"
    >
      <div class="flex items-center gap-3">
        <i class="bi bi-wrench text-[#888888] text-[20px]"></i>

        <span class="font-bold">Fixtures</span>

        <div
          class="size-[28px] border border-black/10 rounded-[8px] flex items-center justify-center"
        >
          <span class="font-semibold text-sm text-[#AAAAAA]">
            {{ fixturesTableData.length }}
          </span>
        </div>

        @if(fixturesWithoutDeviceId > 0 ) {
          <div class="flex items-center gap-1 text-state-cancel">
            <span class="text-sm font-semibold">{{ fixturesWithoutDeviceId }}</span>
            <i class="bi bi-wrench text-[12px]"></i>
          </div>
        }
      </div>

      <arkiq-icon-button
        icon="bi-chevron-down"
        tooltipText="Expand"
        (click)="isFixturesCollapsed = false"
      />
    </div>
    } @else {
    <div
      class="border border-[#E0E0E0] rounded-[18px] max-h-[500px] overflow-y-auto"
    >
      <arkiq-table
        selectionMode="NONE"
        label="Fixtures"
        [columns]="fixturesTableColumns"
        [data]="fixturesTableData"
        [buttons]="fixturesTableHeaderButtons"
        [enableSearch]="true"
        [hideItemsPerPageToggle]="true"
        [showPaginationText]="false"
        (searchChanged)="onFixtureSearch($event)"
        searchInputLabel="Search by Fixture"
        [isLoading]="isFixtureLoading"
      />
    </div>
    } @if (isSubLocationsCollapsed) {
    <div
      class="p-4 w-full flex items-center justify-between border border-[#E0E0E0] rounded-[18px]"
    >
      <div class="flex items-center gap-3">
        <i class="bi bi-wrench text-[#888888] text-[20px]"></i>

        <span class="font-bold">Sub-Locations</span>

        <div
          class="size-[28px] border border-black/10 rounded-[8px] flex items-center justify-center"
        >
          <span class="font-semibold text-sm text-[#AAAAAA]">
            {{ subLocationsTableData.length }}
          </span>
        </div>

        @if(subLocationsWithoutDeviceId) {
          <div class="flex items-center gap-1 text-state-cancel">
            <span class="text-sm font-semibold">{{ subLocationsWithoutDeviceId }}</span>
            <i class="bi bi-wrench text-[12px]"></i>
          </div>
        }
      </div>

      <arkiq-icon-button
        icon="bi-chevron-down"
        tooltipText="Expand"
        (click)="isSubLocationsCollapsed = false"
      />
    </div>
    } @else {
    <div
      class="border border-[#E0E0E0] rounded-[18px] max-h-[500px] overflow-y-auto"
    >
      <arkiq-table
        selectionMode="NONE"
        label="Sub-Locations"
        [columns]="subLocationsTableColumns"
        [data]="subLocationsTableData"
        [buttons]="subLocationsTableHeaderButtons"
        [enableSearch]="true"
        [hideItemsPerPageToggle]="true"
        (rowClicked)="onSubLocationClicked($event)"
        [showPaginationText]="false"
        searchInputLabel="Search by Location"
        [isLoading]="isSubLocationsLoading"
        (searchChanged)="onSubLocationSearch($event)"

      />
    </div>
    }
  </main>
</div>
} @else {
<div class="min-h-[280px] flex items-center justify-center text-gray-400">
  Nothing selected
</div>
} }
