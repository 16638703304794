import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User, UserRole, UserRoleLabel, UsersService } from '@arkiq-portals/sdk';
import { Subscription } from 'rxjs';
import { ButtonComponent } from '../button/button.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { InputComponent } from '../input/input.component';
import { SelectComponent, SelectOption } from '../select/select.component';

@Component({
  selector: 'arkiq-add-organization-master-form',
  templateUrl: 'add-organization-master-form.component.html',
  standalone: true,
  imports: [CommonModule, InputComponent, ButtonComponent, IconButtonComponent, SelectComponent],
})
export class AddOrganizationMasterFormComponent implements OnInit, OnDestroy {
  @Input() isRoleSelectionShowing = false;
  @Input() userToEdit?: Partial<User>;

  @Output() saveForm = new EventEmitter<Partial<User>>();
  @Output() cancelForm = new EventEmitter();
  @Output() resetForm = new EventEmitter();
  @Output() formTouchedAndValid = new EventEmitter<boolean>();
  @Output() showSelectManaginUsers = new EventEmitter();

  public isSaving = false;

  private subscriptions = new Subscription()
  private touched = false;
  public roleOptions!: SelectOption[];
  public lock = false;

  constructor(private usersService: UsersService) {}

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  public ngOnInit(): void {
    this.selectRoleBasedOnUserType();

    this.subscriptions.add(
      this.form.valueChanges.subscribe((values) => {
        if(Object.values(values).every(value => value?.length == 0)) {
          this.form.markAsPristine()
          this.form.markAsUntouched()
          this.touched = false
          this.formTouchedAndValid.emit(this.formFieldsIsEmptyOrValid())
        } else {
          if (!this.touched) {
            this.formTouchedAndValid.emit(this.formFieldsIsEmptyOrValid())
          }
          this.touched = true
        }
      })
    );

    this.updateRoleValidator();

    if (this.userToEdit) {
      this.form.patchValue({
        firstName: this.userToEdit.firstName,
        lastName: this.userToEdit.lastName,
        email: this.userToEdit.email,
        phoneNumber: this.userToEdit.phoneNumber,
      });

      this.form.controls.email.disable();
    }
  }

  private formFieldsIsEmptyOrValid() {
    const isEmpty = Object.values(this.form.value).every(value => !value);
    const isValid = this.form.valid;
    return isEmpty || isValid
  }

  public form = new FormGroup({
    firstName: new FormControl({ value: '', disabled: this.lock }, [
      Validators.required,
    ]),
    lastName: new FormControl({ value: '', disabled: this.lock }, [
      Validators.required,
    ]),
    email: new FormControl({ value: '', disabled: this.lock }, [
      Validators.required,
      Validators.email,
    ]),
    phoneNumber: new FormControl({ value: '', disabled: this.lock }, [
      Validators.pattern(/^\+?[0-9 ]+-?[0-9 ]*$/),
      Validators.required,
    ]),
    role: new FormControl({value:'', disabled: this.lock})
  });

  public async handleSave() {
    if (this.form.invalid) {
      return;
    }

    try {
      this.isSaving = true;

      if (this.userToEdit && this.userToEdit.id) {
        this.userToEdit.firstName = this.form.value.firstName ?? this.userToEdit.firstName;
        this.userToEdit.lastName = this.form.value.lastName ?? this.userToEdit.lastName;
        this.userToEdit.email = this.form.value.email ?? this.userToEdit.email;
        this.userToEdit.phoneNumber = this.form.value.phoneNumber ?? this.userToEdit.phoneNumber;

        await this.usersService.update(
          this.userToEdit.id as number,
          this.userToEdit,
        );
      }

      this.saveForm.emit(this.form.value as Partial<User>);
      this.form.controls.email.disable();
      this.form.controls.firstName.disable();
      this.form.controls.lastName.disable();
      this.form.controls.phoneNumber.disable();
      this.lock = true;
      this.formTouchedAndValid.emit(true);

      this.isSaving = false;
    } catch (error) {
      this.isSaving = false;
      console.error(error);
    }
  }

  public handleCancel() {
    this.formTouchedAndValid.emit(true)
    this.cancelForm.emit();
  }

  public selectAssignUser() {
    this.formTouchedAndValid.emit(true)
    this.showSelectManaginUsers.emit()
  }

  public handleEdit() {
    this.lock = false;
    this.form.controls.email.enable();
    this.form.controls.firstName.enable();
    this.form.controls.lastName.enable();
    this.form.controls.phoneNumber.enable();
  }

  public handleResetForm() {
    this.lock = false;
    this.form.setValue({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      role: ''
    });
    this.form.enable()
  }

  public async selectRoleBasedOnUserType() {
    this.roleOptions = [UserRole.PROPERTY_MASTER,UserRole.CUSTOMER].map(role => {
        return {
          label: UserRoleLabel[role],
          value: role,
        };
      });
  }

  public updateRoleValidator():void {
    this.isRoleSelectionShowing && this.form.controls.role.setValidators( [Validators.required])
  }
}
