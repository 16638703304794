import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { Fixture, FixturesService, FixtureType } from '@arkiq-portals/sdk';
import {
    ButtonComponent,
    IconButtonComponent,
    InputComponent,
    SelectComponent,
    SelectOption,
} from '../../components';
import {
    DialogAlertComponent,
    DialogAlertParams,
    DialogAlertVariant,
} from '../dialog-alert/dialog-alert.component';

export type DialogAddFixtureParams = {
  propertyId: number;
  locationId: number;
  fixture?: Fixture;
};

@Component({
  selector: 'arkiq-dialog-add-fixture',
  templateUrl: './dialog-add-fixture.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    IconButtonComponent,
    InputComponent,
    SelectComponent,
    ButtonComponent,
  ],
})
export class DialogAddFixtureComponent implements OnInit {
  public fixture?: Fixture;

  public isSaving = false;

  public form = new FormGroup({
    type: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
  });

  public types: SelectOption[] = Object.values(FixtureType).map(type => ({
    label: type,
    value: type,
  }));

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogAddFixtureParams,
    public dialogRef: MatDialogRef<DialogAddFixtureComponent>,
    private fixturesService: FixturesService,
    private matDialog: MatDialog,
  ) {
    this.fixture = this.data?.fixture;
  }

  public ngOnInit(): void {
    if (this.fixture) {
      this.form.patchValue({
        type: this.fixture.type,
        name: this.fixture.name,
      });
    }
  }

  public async handleSaveFixture(): Promise<void> {
    try {
      this.isSaving = true;

      let fixture: Fixture;

      if (this.fixture) {
        this.fixture.name = this.form.value.name ?? this.fixture.name;
        this.fixture.type = (this.form.value.type as FixtureType) ?? this.fixture.type;
        fixture = this.fixture;

        await this.fixturesService.update(fixture.id, fixture);
      } else {
        fixture = await this.fixturesService.create({
          type: this.form.value.type,
          name: this.form.value.name,
          property_id: this.data.propertyId,
          location_id: this.data.locationId,
        } as Partial<Fixture>);
      }

      this.isSaving = false;

      this.dialogRef.close({ fixture });
    } catch (error) {
      this.isSaving = false;

      console.error(error);

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to save fixture',
          text: error,
        } as DialogAlertParams,
      });
    }
  }
}
