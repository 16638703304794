import { Inject, Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { DeviceSmartValveView } from "../views";
import { firstValueFrom } from "rxjs";
import { Environment, ENVIRONMENT } from "../sdk.module";
import { HttpClient } from "@angular/common/http";
import { SortOrder } from "../types";

export type ListDevicesSmartValveParams = {
  limit?: number;
  page?: number;
  search?: string;
  order?: SortOrder;
  orderBy?: string;
  startDate?: string;
  endDate?: string;
};

export type ListDevicesSmartValveQueryResult = {
  arkiq_devices_history_smart_valve_view: DeviceSmartValveView[];
  arkiq_devices_history_smart_valve_view_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export type ListDevicesSmartValveResponse = {
  devices: DeviceSmartValveView[];
  totalItems: number;
};

const GET_DEVICES_TOILET_SENSOR_QUERY = (params: ListDevicesSmartValveParams) => {
  const limit = params.limit ?? 10;
  const page = params.page ?? 1;
  const search = params.search ?? '';
  const order_by = params.orderBy ?? 'date_time'
  const order = params.order ?? 'desc_nulls_last'
  const startDate = params.startDate ? params.startDate :  new Date(1900).toISOString()
  const endDate = params.endDate ? params.endDate : new Date().toISOString()
  const offset = (page - 1) * limit;
  let query = search
    ? `
      order_by: {${order_by}: ${order}}
      limit:${limit} offset:${offset}
    `
    : `order_by: {${order_by}: ${order}} limit:${limit} offset:${offset}`;

  let where = ``
  where+=search

  where+= `
      date_time: {
        _gte: "${startDate}",
        _lte: "${endDate}",
      }
  `

  const aggregateQuery = `order_by: {${order_by}: ${order}}`;


  return gql`
    query ListDevicesSmartValve {
      arkiq_devices_history_smart_valve_view(${query} where:{${where}}) {
        date_time
        device_id
        valve_status
        water_pressure
        water_temperature
        consumption_rate
        cumulative_consumption
        power_source
        f_cnt
        last_rssi
        last_snr
      }
      arkiq_devices_history_smart_valve_view_aggregate(${aggregateQuery} where: {${where}}) {
        aggregate {
          count
        }
      }
    }
  `;
};

@Injectable({ providedIn: 'root' })
export class DevicesSmartValveService {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private apollo: Apollo,
    private http: HttpClient,
  ) {}

  public async list(params?: ListDevicesSmartValveParams): Promise<ListDevicesSmartValveResponse> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<ListDevicesSmartValveQueryResult>({
          query: GET_DEVICES_TOILET_SENSOR_QUERY(params ?? {}),
        })
      );

      return {
        devices: response.data.arkiq_devices_history_smart_valve_view,
        totalItems: response.data.arkiq_devices_history_smart_valve_view_aggregate.aggregate.count,
      };
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async listByDeviceId(deviceId: string, params?: ListDevicesSmartValveParams): Promise<ListDevicesSmartValveResponse> {
    try {
      const search = `device_id: {_eq: "${deviceId}"}`;

      const response = await firstValueFrom(
        this.apollo.query<ListDevicesSmartValveQueryResult>({
          query: GET_DEVICES_TOILET_SENSOR_QUERY({ ...params, search }),
        })
      );

      return {
        devices: response.data.arkiq_devices_history_smart_valve_view,
        totalItems: response.data.arkiq_devices_history_smart_valve_view_aggregate.aggregate.count,
      };
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async getById(deviceId: string): Promise<DeviceSmartValveView> {
    try {
      const search = `device_id: {_eq: "${deviceId}"}`;

      const response = await firstValueFrom(
        this.apollo.query<ListDevicesSmartValveQueryResult>({
          query: GET_DEVICES_TOILET_SENSOR_QUERY({ search }),
        })
      );

      return response.data.arkiq_devices_history_smart_valve_view[0];
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async openValve(deviceId: string): Promise<void> {
    try {
      const url = `${this.environment.arkiqApiUrl}/Downlink/OpenCloseValve?deviceId=${deviceId}&openValve=true`;

      await firstValueFrom(this.http.get(url));
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async closeValve(deviceId: string): Promise<void> {
    try {
      const url = `${this.environment.arkiqApiUrl}/Downlink/OpenCloseValve?deviceId=${deviceId}&openValve=false`;

      await firstValueFrom(this.http.get(url));
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }
}
