import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DeviceOverview, Organization, Property } from '@arkiq-portals/sdk';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  selector: 'home-card',
  templateUrl: 'home-card.component.html',
})
export class HomeCardComponent {
  @Input({ required: true }) title: string = '';
  @Input({ required: true }) value: number = 0;
  @Input({ required: true }) monthValue: number = 0;
  @Input({ required: true }) routeTo: string = '';
  @Input() icon!: `bi-${string}`;

  public organizations!: Organization[];
  public properties!: Property[];
  public devices!: DeviceOverview[];

  constructor() {}

  public valueFormatted(value) {
    return new Intl.NumberFormat('en-US').format(Number(value));
  }
}

// Total Devices
// Total Alerts
// Total Organizations
// Total Properties
