<div
  class="py-6 px-[38px] w-full h-full max-h-full flex flex-col gap-3 overflow-y-auto"
>
  <arkiq-header title="Alerts" />
  <section class="w-full flex justify-center">
    <arkiq-tabs [tabs]="tabs" />
  </section>
  <main
    class="flex-1 flex flex-col max-h-[920px] sm:max-h-none sm:overflow-y-hidden rounded-xl bg-white"
  >
    <div
      class="flex flex-wrap items-center gap-4 py-[26px] px-[38px] min-h-[80px]"
    >
      <div class="flex items-center gap-2 mr-auto">
        <h3 class="text-xl font-bold text-nowrap">
          {{ isInOngoingTab ? 'Ongoing Alerts' : 'All Alerts' }}
        </h3>
        @if(alertsQuantity) {
        <span
          class="text-[12px] text-[#344054] border border-[#D0D5DD] rounded-md py-[2px] px-[6px]"
          >{{ alertsQuantity }}</span
        >
        }
      </div>

      <p class="flex items-center text-[#121212] text-lg font-bold">
        <i class="bi bi-briefcase-fill text-[#CCC] size-5 mr-1"></i>
        Organization
        <arkiq-table-column-header-button
          [isSortable]="true"
          (closeMenu)="onOrganizationFilterSelection($event)"
          [filter]="organizationHeaderFilter"
        />
      </p>
      <p class="flex items-center text-[#121212] text-lg font-bold">
        <i class="bi bi-buildings-fill text-[#CCC] size-5 mr-1"></i>
        Property
        <arkiq-table-column-header-button
          [isSortable]="true"
          (closeMenu)="onPropertyFilterSelection($event)"
          [filter]="propertyHeaderFilter"
        />
      </p>
      <p class="flex items-center text-[#121212] text-lg font-bold">
        <i class="bi bi-geo-alt-fill text-[#CCC] size-5 mr-1"></i>
        Location
        <arkiq-table-column-header-button
          [isSortable]="true"
          (closeMenu)="onLocationFilterSelection($event)"
          [filter]="locationHeaderFilter"
        />
      </p>
      <!-- <p class="flex items-center text-[#121212] text-lg font-bold">
        <i class="bi bi-calendar3 text-[#CCC] size-5 mr-1"></i>
        Date/Time
        <arkiq-table-column-header-button
          [isSortable]="true"
          (closeMenu)="onOrganizationFilterSelection($event)"
        />
      </p> -->
      <p class="flex items-center text-[#121212] text-lg font-bold">
        <i class="bi bi-wifi text-[#CCC] size-5 mr-1"></i>
        Device Type
        <arkiq-table-column-header-button
          [isSortable]="true"
          (closeMenu)="onDeviceTypeFilterSelection($event)"
          [filter]="deviceTypeHeaderFilter"
        />
      </p>
    </div>
    @if (isLoading) {
    <div class="flex flex-1 justify-center items-center">
      <arkiq-spinner />
    </div>
    } @else {
    <div
      class="flex items-center flex-wrap justify-around border-[#E0E0E0] border-y-[1px] py-[26px] px-[38px] min-h-[80px]"
    >
      <div class="flex items-center flex-wrap gap-6">
        <div class="flex flex-wrap gap-2 xl:gap-0 items-center">
          <button
            *ngFor="
              let item of headerFilterButtons;
              let index = index;
              let last = last
            "
            (click)="item.action()"
            class="flex items-center gap-2 px-2 py-3 shadow-sm rounded-md text-[12px] border-[#D0D5DD] border-[1px] transition-colors hover:bg-[#EDF1F5]"
            [ngClass]="{
              'xl:rounded-r-none': index === 0,
              'xl:rounded-none': index !== 0 && !last,
              'xl:rounded-l-none': last,
              'bg-[#D0D5dd]': verifyFilterSelected(item.value)
            }"
          >
            <i class="bi text-[20px]" [ngClass]="item.icon"></i>
            {{ item.text }}
            @if(item.alerts) {
            <span class="text-[#CE3838]"> {{ item.alerts }} </span>
            }
          </button>
        </div>

        <!-- <span
            class="invisible hidden xl:visible xl:block bg-[#DDD] h-full min-h-[40px] w-[2px] mx-2"
          ></span>
          <div class="flex flex-wrap items-center gap-3">
            <div class="flex items-center">
              <button
                *ngFor="
                  let item of headerLayoutView;
                  let index = index;
                  let last = last
                "
                onclick
                (click)="item.action()"
                class="flex items-center gap-2 font-semibold px-2 py-3 text-[12px] text-nowrap border-[#D0D5DD] border-y-[1px] border-l-[1px] transition-colors hover:bg-[#EDF1F5]"
                [ngClass]="{
                  'rounded-tl-xl rounded-bl-xl': index === 0,
                  'rounded-tr-xl rounded-br-xl border-r-[1px]': last,
                  'bg-[#D0D5DD]': item.value === selectedView
                }"
              >
                <i class="bi text-[20px]" [ngClass]="item.icon"></i>
                {{ item.text }}
              </button>
            </div>
          </div> -->
        <span
          class="invisible hidden 2xl:visible 2xl:block bg-[#DDD] h-full min-h-[40px] w-[2px] mx-2"
        ></span>
        <button
          class="text-primary underline text-lg px-2 py-3"
          (click)="clearFilters()"
        >
          Clear All Filters
        </button>
      </div>
    </div>
    @if(alerts.length > 0) {
    <div class="flex-1 flex-col overflow-y-auto custom-scrollbar">
      <div
        class="grid sm:grid-cols-[repeat(auto-fill,minmax(218px,1fr))] md:grid-cols-[repeat(auto-fill,minmax(380px,1fr))] justify-items-stretch gap-6 p-6 md:px-[38px] md:py-[26px] auto-rows-max"
      >
        @for(alert of alerts; track $index) {
        <app-device-card [alert]="alert" />
        }
      </div>
    </div>

    }@else {
    <div class="flex flex-1 justify-center items-center">
      <h3 class="font-semibold text-[#CCC] text-center text-2xl">No Alerts</h3>
    </div>
    } } @if(!isLoading){
    <footer
      class="bg-white rounded-b-xl flex items-center justify-between py-4 px-6 border-t border-t-[#E0E0E0] flex-col sm:flex-row gap-4"
    >
      <section class="flex items-center gap-[10px]">
        <span class="text-[#AAAAAA] font-medium text-sm">Results Per Page</span>

        <select
          class="h-[34px] min-w-[54px] border border-[#9D9D9D] rounded-[5px] text-center text-sm"
          [formControl]="rowsLimitControl"
        >
          <option value="5">5</option>
          <option value="10" selected>10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
        </select>
      </section>

      <p class="text-sm text-[#AAA] font-medium mx-auto">
        Filtered Results: @if(alertsQuantity){
        {{ alertsQuantity }}
        }
      </p>

      <section
        class="flex items-center gap-2"
        [ngClass]="{ 'invisible opacity-50': totalPages === 0 }"
      >
        <button
          class="px-3 py-2 text-[#6B7280] font-medium text-base flex items-center gap-2 border border-[#E5E7EB] rounded-lg hover:bg-slate-100 transition-colors"
          [ngClass]="{
            'cursor-not-allowed opacity-50': totalPages === 0 || page === 1
          }"
          (click)="previousPage()"
          [disabled]="totalPages === 0 || page === 1"
        >
          <span class="text-[#4B5563]">«</span> Previous
        </button>
        <input
          class="max-w-[38px] size-[38px] text-tertiary border border-[#E5E7EB] outline-none font-medium text-[15px] rounded text-center focus:text-black focus:ring-1 focus:ring-offset-1 focus:ring-primary focus:border-none focus:bg-slate-200 hover:bg-slate-100 transition-colors [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          [ngClass]="{
            'focus:ring-[#E5E7EB] cursor-not-allowed select-none focus:ring-offset-0':
              totalPages === 0
          }"
          [value]="page"
          [formControl]="pageInputControl"
          type="number"
          [disabled]="totalPages === 0"
          [readonly]="totalPages === 0"
        />
        <span class="text-[#6B7280] font-medium text-base"
          >of {{ totalPages === 0 ? 1 : totalPages }}</span
        >
        <button
          class="px-3 py-2 text-[#6B7280] font-medium text-base flex items-center gap-2 border border-[#E5E7EB] rounded-lg hover:bg-slate-100 transition-colors"
          [ngClass]="{
            'cursor-not-allowed opacity-50':
              totalPages === 0 || page === totalPages
          }"
          (click)="nextPage()"
          [disabled]="
            totalPages === 0 || page === totalPages || page + 1 > totalPages
          "
        >
          Next <span class="text-[#4B5563]">»</span>
        </button>
      </section>
    </footer>
    }
  </main>
</div>
