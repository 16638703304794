import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  Organization,
  OrganizationsService,
  UserRole,
} from '@arkiq-portals/sdk';
import {
  ButtonComponent,
  IconButtonComponent,
  SpinnerComponent,
} from '@arkiq-portals/ui';
import { OrganizationSummaryCardsComponent } from './components/organization-summary-cards/organization-summary-cards.component';
import { OrganizationDetailsTabsComponent } from './components/organization-details-tabs/organization-details-tabs.component';

@Component({
  selector: 'app-organization-details',
  templateUrl: 'organization-details.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SpinnerComponent,
    IconButtonComponent,
    ButtonComponent,
    OrganizationDetailsTabsComponent,
    OrganizationSummaryCardsComponent,
  ],
  styles: [`app-organization-details {display:flex; flex:1;}`],
  styleUrl: './organization-details.scss',
  encapsulation: ViewEncapsulation.None
})
export class OrganizationDetailsComponent implements OnInit {
  public organization!: Organization;

  public isLoading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private organizationsService: OrganizationsService,
    private location: Location,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    const routeParams = this.activatedRoute.snapshot.params;
    if ('id' in routeParams) {
      this.getOrganization(Number(routeParams['id']));
    }
  }

  private async getOrganization(organizationId: number) {
    try {
      this.isLoading = true;

      this.organization = await this.organizationsService.getById(
        organizationId,
      );

      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      alert(error);
    }
  }

  public handleGoBack() {
    this.location.back();
  }

  public handleOrganizationMasterClick() {
    const organizationMaster = this.organizationMaster;

    this.router.navigateByUrl(`/users/${organizationMaster?.id}`);
  }

  public handleEditOrganization() {
    this.router.navigateByUrl(`/organizations/${this.organization.id}/edit`);
  }

  public get organizationMaster() {
    const master = this.organization.users_organizations_roles?.find(
      userRole => userRole.role === UserRole.ORGANIZATION_MASTER,
    );

    return master?.user;
  }
}
