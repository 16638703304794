import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { Location } from '../models';
import { UsersService } from './users.service';


export type LocationWithFixtureCounter = Location & {
  fixture_counter: number
  unprotected_counter: number
  protected_counter: number
}
@Injectable({ providedIn: 'root' })
export class LocationsService {
  constructor(private apollo: Apollo, private usersService: UsersService) {}

  public async list(propertiesIds?: number[]): Promise<Location[]> {
    const isUserCustomer = this.usersService.isLoggedUserCustomer;
    const hasZeroProperties =
      this.usersService.loggedUserProperties.length === 0;
    const user = this.usersService.loggedUser;
    const userPropertiesIds = this.usersService.loggedUserProperties;

    if (!user) {
      return [];
    }

    if (isUserCustomer && hasZeroProperties) {
      return [];
    }
    const query = `
      where: {
        ${isUserCustomer ? `property_id: {_in: [${userPropertiesIds}]}` : ''}
      }
    `;

    try {
      const response = await firstValueFrom(
        this.apollo.query<{ front_locations: Location[] }>({
          query: gql`
            query ListLocations {
              front_locations(
                ${query}
              ) {
                id
                created_at
                updated_at
                name
                parent_id
                property_id
              }
            }
          `,
        }),
      );

      return response.data.front_locations;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async listLocationsByProperty(
    propertyId: number,
  ): Promise<Location[]> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<{ front_locations: Location[] }>({
          query: gql`
            query ListLocations {
              front_locations(where: {property_id: {_eq: ${propertyId}}}) {
                id
                created_at
                updated_at
                name
                parent_id
                property_id
                fixtures {
                  id
                  name
                  type
                  status
                  device_id
                  property_id
                  location_id
                  created_at
                  updated_at
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
        }),
      );

      return response.data.front_locations;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async listLocationsByParentId(
    parentLocationId: number,
    search?: string
  ): Promise<Location[]> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<{ front_locations: Location[] }>({
          query: gql`
            query ListLocations {
              front_locations(where: {
                parent_id: {_eq: ${parentLocationId}}
                ${
                  search
                  ? `name: {_ilike: "%${search}%"}`
                  : ''
                }
              }) {
                id
                created_at
                updated_at
                name
                parent_id
                property_id
                fixtures {
                  id
                  name
                  type
                  status
                  device_id
                  property_id
                  location_id
                  created_at
                  updated_at
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
        }),
      );

      return response.data.front_locations;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async getById(locationId: number): Promise<LocationWithFixtureCounter> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<{
          front_locations: Location[],
          fixture_counter: {
            fixtures_aggregate: {aggregate: {count: number}}
            unprotected: {aggregate: {count: number}}
          }[]
        }>({
          query: gql`
            query ListLocations {
              front_locations(where: {id: {_eq: ${locationId}}}) {
                id
                created_at
                updated_at
                name
                parent_id
                property_id
                fixtures {
                  id
                  name
                  type
                  status
                  device_id
                  property_id
                  location_id
                  created_at
                  updated_at
                }
              }

              fixture_counter: front_locations(where: {_or: [{id: {_eq: ${locationId}}}, {parent_id: {_eq: ${locationId}}}]}) {
                fixtures_aggregate {
                  aggregate {
                    count
                  }
                }

                unprotected: fixtures_aggregate(where: {device_id: {_is_null: true}}) {
                  aggregate {
                    count
                  }
                }
              }
            }
          `,
        }),
      );
      let unprotectedCounter = 0;
      const fixtureCounter = response.data.fixture_counter.reduce((acc,next) => {
        const count = next.fixtures_aggregate.aggregate.count
        unprotectedCounter += next.unprotected.aggregate.count
        return acc + count
      },0)
      const customResponse =  {
        ...response.data.front_locations[0],
        fixture_counter: fixtureCounter,
        unprotected_counter: unprotectedCounter,
        protected_counter: fixtureCounter - unprotectedCounter

      }
      return customResponse
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async getManyById(locationsIds: number[]): Promise<Location[]> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<{ front_locations: Location[] }>({
          query: gql`
            query ListLocations {
              front_locations(where: {id: {_in: [${locationsIds.join(',')}]}}) {
                id
                created_at
                updated_at
                name
                parent_id
                property_id
                fixtures {
                  id
                  name
                  type
                  status
                  device_id
                  property_id
                  location_id
                  created_at
                  updated_at
                }
              }
            }
          `,
        }),
      );

      return response.data.front_locations;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async create(data: Partial<Location>): Promise<Location> {
    try {
      const response = await firstValueFrom(
        this.apollo.mutate<{ insert_front_locations_one: Location }>({
          mutation: gql`
            mutation InsertLocation(
              $name: String = "${data.name}",
              $property_id: Int = ${data.property_id},
              $parent_id: Int = ${data.parent_id || null}
            ) {
              insert_front_locations_one(object: {
                name: $name,
                property_id: $property_id,
                parent_id: $parent_id
              }) {
                id
                created_at
                updated_at
                name
                parent_id
                property_id
              }
            }
          `,
        }),
      );

      return response.data?.insert_front_locations_one as Location;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async update(locationId: number, data: Partial<Location>) {
    try {
      await firstValueFrom(
        this.apollo.mutate<{ update_front_locations: { returning: object } }>({
          mutation: gql`
            mutation UpdateLocation($_eq: Int = ${locationId}, $name: String = "${data.name}") {
              update_front_locations(where: {id: {_eq: $_eq}}, _set: {name: $name}) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async delete(locationId: number): Promise<void> {
    try {
      await firstValueFrom(
        this.apollo.mutate({
          mutation: gql`
            mutation DeleteLocation {
              delete_front_locations(where: {id: {_eq: ${locationId}}}) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async deleteMany(locationsIds: number[]): Promise<void> {
    try {
      await firstValueFrom(
        this.apollo.mutate({
          mutation: gql`
            mutation DeleteManyLocation {
              delete_front_locations(
                where: {
                  id: {
                    _in: [${locationsIds.join(',')}]
                  }
                }
              ) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }
}
