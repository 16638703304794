import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'arkiq-spinner',
  templateUrl: 'spinner.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class SpinnerComponent {
  @Input() text = '';
  @Input() size: `${number}px` = '20px';

  constructor() { }
}
