import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FunctionsService,
  StorageService,
  User,
  UserOrganizationRole,
  UserOrganizationRoleService, UserRole, UserType,
  UserTypeLabel,
  UsersService
} from '@arkiq-portals/sdk';
import {
  ButtonComponent,
  DialogAlertComponent,
  DialogAlertParams,
  DialogAlertVariant,
  IconButtonComponent,
  InputComponent,
  SelectComponent,
  SelectOption,
  SpinnerComponent,
} from '@arkiq-portals/ui';
import { take } from 'rxjs';
import { SelectUserPhotoButtonComponent } from './select-user-photo-button/select-user-photo-button.component';
import { UserOrganizationRoleFormComponent } from './user-organization-role-form/user-organization-role-form.component';

@Component({
  selector: 'app-create-user',
  templateUrl: 'create-user.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    SelectUserPhotoButtonComponent,
    UserOrganizationRoleFormComponent,
    InputComponent,
    SelectComponent,
    ButtonComponent,
    SpinnerComponent,
    IconButtonComponent,
  ],
})
export class CreateUserComponent implements OnInit {
  public user?: User;

  public userTypeOptions: SelectOption[] = Object.values(UserType).map(userType => ({
    label: UserTypeLabel[userType],
    value: userType,
  }));

  public roles: UserRole[] = [
    UserRole.ORGANIZATION_MASTER,
    UserRole.PROPERTY_MASTER,
    UserRole.CUSTOMER,
  ];

  public form = new FormGroup({
    type: new FormControl(UserType.CUSTOMER, [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.pattern(/^\+?[0-9 ]+-?[0-9 ]*$/), Validators.required]),
    photo: new FormControl<File | null>(null),
  });

  public buildUserRoleForm = () =>
    new FormGroup({
      id: new FormControl(''),
      role: new FormControl('', [Validators.required]),
      organizationId: new FormControl('', [Validators.required]),
      propertyId: new FormControl('', []),
    });

  public userRolesForm = new FormArray([this.buildUserRoleForm()]);

  public isLoading = false;
  public isSaving = false;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    private functionsService: FunctionsService,
    private userOrganizationRoleService: UserOrganizationRoleService,
    private storageService: StorageService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    const routeParams = this.activatedRoute.snapshot.params;
    const state = this.location.getState() as any;

    if ('id' in routeParams) {
      this.getUser(Number(routeParams['id']))
        .then(() => {
          if (state && state.createdProperty) {
            this.userRolesForm.push(this.buildUserRoleForm());

            this.userRolesForm.at(-1).patchValue({
              organizationId: state.createdProperty.organizationId,
              propertyId: state.createdProperty.id,
              role: '',
            });
          }
        });
    }

    if (state && state.property) {
      this.userRolesForm.at(0).patchValue({
        organizationId: state.property.organizationId,
        propertyId: state.property.id,
        role: state.role || '',
      });
    }

    if (state && state.formData) {
      this.form.patchValue(state['formData']);
    }

    if (state && state.userRolesFormData && state.userRolesFormData.length) {
      for (const data of state.userRolesFormData) {
        this.userRolesForm.push(this.buildUserRoleForm());

        this.userRolesForm.at(-1).patchValue({
          organizationId: data.organizationId,
          propertyId: data.propertyId,
          role: data.role,
        });
      }
    }

    if (state && state.role) {
      this.userRolesForm.at(0).patchValue({ role: state.role });
      this.roles = [state.role];
    }
  }

  private populateForm(): void {
    if (!this.user) {
      return;
    }
    this.form.patchValue({
      email: this.user.email ?? '',
      firstName: this.user.firstName ?? '',
      lastName: this.user.lastName ?? '',
      phoneNumber: this.user.phoneNumber ?? '',
      type: this.user.type ?? '',
    });

    this.form.markAllAsTouched();
  }

  private async getUser(userId: number) {
    try {
      this.isLoading = true;

      this.user = await this.usersService.getById(userId);

      const userRoles = await this.userOrganizationRoleService.listByUserId(
        userId,
      );

      if (userRoles.length > 0) {
        this.userRolesForm.removeAt(0);
        for (let i = 0; i < userRoles.length; i++) {
          this.userRolesForm.push(this.buildUserRoleForm());
          this.userRolesForm.at(i).setValue({
            id: String(userRoles[i].id),
            organizationId: String(userRoles[i].organizationId),
            propertyId: String(userRoles[i].propertyId),
            role: userRoles[i].role,
          });
        }
      }

      this.populateForm();

      this.form.controls.email.disable();

      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      alert(error);
    }
  }

  public handleSave(): void {
    if (this.user) {
      this.editUser();
    } else {
      this.createUser();
    }
  }

  public async createUser() {
    try {
      if (this.form.invalid || !this.form.value || this.userRolesForm.invalid) {
        this.form.markAllAsTouched();
        this.userRolesForm.markAllAsTouched();
        return;
      }

      this.isSaving = true;

      const { uid: firebaseId } = await this.functionsService.registerUser(
        this.form.value.email as string,
        `${this.form.value.firstName} ${this.form.value.lastName}`,
      );

      let photoUrl = '';
      if (this.form.value.photo) {
        photoUrl = await this.storageService.uploadProfilePhoto(
          this.form.value.photo,
          firebaseId,
        );
      }

      const newUser = await this.usersService.create({
        ...this.form.value,
        firebaseId,
        photo: photoUrl,
      } as Partial<User>);

      const userRolesToSave = this.getUserRolesToSave();

      if (userRolesToSave.length > 0) {
        await Promise.all(
          userRolesToSave.map(userRole =>
            this.userOrganizationRoleService.create({
              ...userRole,
              userId: newUser.id,
            } as Partial<UserOrganizationRole>),
          ),
        );
      }

      this.isSaving = false;

      this.showSuccessAlert({
        variant: DialogAlertVariant.SUCCESS,
        title: 'Successfully Added',
        text: 'Email Invitation has been sent to the user to set the password.',
      });
    } catch (error) {
      this.isSaving = false;
      console.error(error);

      this.dialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to create user',
          text: error,
        } as DialogAlertParams,
      });
    }
  }

  public async editUser() {
    if (!this.user) {
      return;
    }

    try {
      if (this.form.invalid || !this.form.value || this.userRolesForm.invalid) {
        this.form.markAllAsTouched();
        this.userRolesForm.markAllAsTouched();
        return;
      }

      this.isSaving = true;

      let photoUrl = '';
      if (this.form.value.photo) {
        photoUrl = await this.storageService.uploadProfilePhoto(
          this.form.value.photo,
          this.user.firebaseId,
        );
      }

      await this.usersService.update(this.user.id, {
        ...this.form.value,
        firebaseId: this.user.firebaseId,
        photo: photoUrl || this.user.photo,
        email: this.user.email,
      } as Partial<User>);

      await this.userOrganizationRoleService.deleteByUserId(this.user.id);

      const userRolesToSave = this.getUserRolesToSave();

      if (userRolesToSave.length > 0) {
        await Promise.all(
          userRolesToSave.map(userRole => {
            delete userRole.id;
            return this.userOrganizationRoleService.create({
              ...userRole,
              userId: this.user?.id,
            } as Partial<UserOrganizationRole>);
          }),
        );
      }

      this.isSaving = false;

      this.showSuccessAlert({
        variant: DialogAlertVariant.SUCCESS,
        title: 'Successfully Saved',
        text: 'The user data was saved to the database.',
      });
    } catch (error) {
      this.isSaving = false;
      console.error(error);

      this.dialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to edit user',
          text: error,
        } as DialogAlertParams,
      });
    }
  }

  public onAddProperty(organizationId: string): void {
    this.userRolesForm.at(-1)?.patchValue({ organizationId });

    this.router.navigateByUrl(
      '/properties/create',
      {
        state: {
          organizationId,
          formData: this.form.value,
          userRolesFormData: this.userRolesForm.value,
          redirectTo: '/users/create',
        },
      },
    );
  }

  public showSuccessAlert(params: DialogAlertParams) {
    const ref = this.dialog.open(DialogAlertComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: params,
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => this.handleGoBack());
  }

  public handleGoBack() {
    this.location.back();
  }

  public isUserArkIq(): boolean {
    return this.usersService.loggedUser?.type === UserType.ARKIQ;
  }

  private getUserRolesToSave() {
    if (!this.userRolesForm.value.length) {
      return [];
    }

    const userRolesToSave: Partial<UserOrganizationRole>[] = [];

    for (const currentUserRole of this.userRolesForm.value) {
      if (
        !userRolesToSave.some(
          curr => curr.organizationId === currentUserRole.organizationId &&
            curr.propertyId === currentUserRole.propertyId &&
            curr.role === currentUserRole.role
        )
      ) {
        userRolesToSave.push(currentUserRole as Partial<UserOrganizationRole>);
      }
    }

    return userRolesToSave;
  }
}
