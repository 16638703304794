<ng-container *ngIf="!showAddManagingUserForm">
  <div class="w-full flex items-end gap-6">
    <div *ngIf="!selectedUser" class="w-full flex flex-col gap-1">
      <arkiq-input
        type="text"
        label="Select Managing User"
        placeholder="Select Managing User"
        [control]="searchControl"
        [rounded]="false"
        [required]="true"
        size="md"
        [matAutocomplete]="auto"
      />

      <mat-autocomplete
        (optionSelected)="onUserSelected($event)"
        [autoActiveFirstOption]="true"
        #auto="matAutocomplete"
      >
        <mat-option *ngFor="let user of users" [value]="user.id">
          <div class="w-full flex items-center gap-3">
            <img [src]="user.photo" [alt]="user.id" class="size-10 rounded-full object-cover" />
            <span class="font-bold">{{ user.firstName + ' ' + user.lastName }}</span>
          </div>
        </mat-option>
      </mat-autocomplete>
    </div>

    <div *ngIf="selectedUser" class="w-full flex items-center gap-3 p-2 border border-[#EEEEEE] rounded-[5px]">
      <section class="flex-1 flex items-center gap-3">
        <img [src]="selectedUser.photo" [alt]="selectedUser.id" class="size-10 rounded-full object-cover" />
        <span class="font-bold">{{ selectedUser.firstName + ' ' + selectedUser.lastName }}</span>
      </section>

      <button
        type="button"
        class="size-7 rounded-full hover:bg-black/5 transition-colors flex items-center justify-center"
        (click)="handleRemoveSelection()"
        matTooltip="Remove selection"
      >
        <i class="bi bi-x-lg text-[16px] text-[#AAAAAA]"></i>
      </button>
    </div>

    <div>
      <arkiq-select
        label="Role"
        [required]="true"
        size="md"
        [rounded]="false"
        [options]="roleOptions"
        [control]="roleControl"
      />
    </div>
  </div>

  <div class="w-full flex items-center justify-between pt-4">
    <div class="w-full flex items-start gap-4">
      <small *ngIf="isLoading" [ngClass]="isLoading ? 'text-gray-500' : 'text-white'" class="text-xs">
        Loading users...
      </small>

      <button
        type="button"
        class="underline text-primary font-semibold text-sm hover:opacity-75 transition-opacity"
        (click)="showAddManagingUserForm = true"
      >
        Add Managing User
      </button>
    </div>

    <div class="flex items-center gap-3">
      <arkiq-button
        text="Cancel"
        size="md"
        [rounded]="false"
        variant="tertiary"
        (click)="handleCancel()"
      />
      <arkiq-button
        text="Assign"
        size="md"
        [rounded]="false"
        [disabled]="!selectedUser || !roleControl.value"
        (click)="handleAssign()"
      />
    </div>
  </div>
</ng-container>

<ng-container *ngIf="showAddManagingUserForm">
  <arkiq-add-organization-master-form
    (cancelForm)="handleCancel()"
    (showSelectManaginUsers)="showAddManagingUserForm = false"
    (saveForm)="onAddManagingUserFormSaved($event)"
    (resetForm)="handleRemoveSelection()"
    [isRoleSelectionShowing]="true"
    (formTouchedAndValid)="isMastersTouchedAndValid($event)"
  />
</ng-container>
