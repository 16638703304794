import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DeviceType } from '@arkiq-portals/sdk';
import {
  ButtonComponent,
  IconButtonComponent,
  InputComponent,
  SelectComponent,
  SelectOption,
  SpinnerComponent,
} from '@arkiq-portals/ui';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    IconButtonComponent,
    SelectComponent,
    InputComponent,
    ButtonComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
  selector: 'device-create',
  templateUrl: 'device-create.component.html',
})
export class DeviceCreateComponent implements OnInit {
  public isLoading: boolean = false;

  public deviceTypeOptions: SelectOption[] = Object.keys(DeviceType).map(
    type => ({
      label: DeviceType[type],
      value: type,
    }),
  );

  public subscriptions = new Subscription();
  public form = new FormGroup({
    type: new FormControl<DeviceType | null>(null, [Validators.required]),
    serial: new FormControl('', [Validators.required]),
    date: new FormControl('', [Validators.required]),
    // devEUI: new FormControl('', [Validators.required]),
    // joinEUI: new FormControl('', [Validators.required]),
    // appKey: new FormControl('', [Validators.required]),
  });

  constructor(private location: Location) {}

  ngOnInit() {}

  public handleGoBack() {
    this.subscriptions.unsubscribe();

    this.location.back();
  }

  public createDevice() {
    console.log(this.form.value);
    console.log('Create Device');
  }
}
