<div
  class="w-full flex flex-col bg-white rounded-[6px] shadow-md p-[10px] min-h-[168px]"
  [matTooltip]="tooltipText"
>
  @if (title) {
    <header class="w-full flex items-center gap-2">
      <h1 class="font-semibold">{{ title }}</h1>

      @if (subTitle) {
        <span class="text-xs font-semibold text-[#888888]">{{ subTitle }}</span>
      }
    </header>
  }

  <main class="flex-1 w-full flex items-center justify-center">
    <p class="font-semibold text-[54px] truncate">{{ text }}</p>
  </main>
</div>
