import { BuildOrganization, Organization } from "./organization";
import { BuildProperty, Property } from "./property";
import { BuildUser, User } from "./user";
import { UserRole } from "./user-role";

export type UserOrganizationRole = {
  id: number;
  userId: number;
  organizationId: number;
  propertyId: number;
  role: UserRole;
  property?: Property;
  organization?: Organization;
  user?: User;
};

export function BuildUserOrganizationRole(data: any): UserOrganizationRole {
  return {
    id: data.id,
    userId: data.userId,
    organizationId: data.organizationId,
    propertyId: data.propertyId,
    role: data.role,
    property: data.property ? BuildProperty(data.property) : undefined,
    organization: data.organization ? BuildOrganization(data.organization) : undefined,
    user: data.user ? BuildUser(data.user) : undefined,
  };
}
