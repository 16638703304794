import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '@arkiq-portals/sdk';
import {
    DialogAlertComponent,
    DialogAlertParams,
    DialogAlertVariant,
} from '@arkiq-portals/ui';
import {
    ButtonComponent,
    CheckboxComponent,
    InputComponent,
    PasswordInputComponent,
} from '../../../../components';
import { AuthStepsComponent } from '../../components';

@Component({
  selector: 'arkiq-auth-sign-in',
  templateUrl: 'sign-in.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AuthStepsComponent,
    InputComponent,
    PasswordInputComponent,
    CheckboxComponent,
    ButtonComponent,
  ],
})
export class AuthSignInComponent {
  public isLoading = false;

  public form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    // password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    password: new FormControl('', [Validators.required]),
    rememberMe: new FormControl(false),
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private matDialog: MatDialog,
  ) {}

  public async handleSignIn() {
    if (!this.form.value.email || !this.form.value.password) {
      return;
    }

    try {
      this.isLoading = true;

      await this.authService.signIn({
        email: this.form.value.email,
        password: this.form.value.password,
      });

      this.router.navigateByUrl('');
    } catch (error) {
      console.error(error);

      this.showAlert({
        variant: DialogAlertVariant.ERROR,
        title: 'Unable to sign in',
        text: 'Please, check your credentials and try again',
      });
    } finally {
      this.isLoading = false;
    }
  }

  public showAlert(params: DialogAlertParams) {
    const ref = this.matDialog.open(DialogAlertComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: params,
    });

    return ref;
  }
}
