import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import {
  FunctionsService,
  Organization,
  OrganizationsService,
  PropertiesService,
  Property,
  SortOrder,
  User,
  UserOrganizationRole,
  UserRole,
  UserRoleLabel,
  UserRoleValues,
  UserType,
  UserTypeLabel,
  UsersService,
} from '@arkiq-portals/sdk';
import {
  ButtonComponent,
  DialogAlertComponent,
  DialogAlertParams,
  DialogAlertVariant,
  DialogConfirmActionComponent,
  InputComponent,
  SpinnerComponent, TableColumn,
  TableColumnHeaderFilterType,
  TableColumnHeaderOptions,
  TableColumnShowMore,
  TableColumnType,
  TableComponent,
  TableHeaderButton,
  TableRowClickedEvent,
  TableSortChangeEvent,
  TabsComponent
} from '@arkiq-portals/ui';
import { formatDate } from 'date-fns';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'app-users-list',
  templateUrl: 'users-list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    ButtonComponent,
    InputComponent,
    TableComponent,
    TabsComponent,
    SpinnerComponent
  ],
  styles: [':host {flex: 1; display: flex}'],
})
export class UsersListComponent implements OnInit, OnDestroy {
  public isLoading = false;

  public users: User[] = [];
  public totalPages = 1;
  public totalItems = 0;
  public selectedUsersLoading = false;
  public showSelectedUser = false;
  public USER_ROLES = UserRole;

  // public tabs: Tab[] = [
  //   { route: '/users', text: 'View Users' },
  //   {
  //     route: '/users/manage-roles-permissions',
  //     text: 'Manage Roles Permissions',
  //   },
  // ];

  public organizationsOptions: TableColumnHeaderOptions[] = [];
  public propertiesOptions: TableColumnHeaderOptions[] = [];

  public filterForm = new FormGroup({
    search: new FormControl(''),
    organizationId: new FormControl<string[]>([]),
    propertyId: new FormControl<string[]>([]),
    role: new FormControl<string[]>([]),
    orderBy: new FormControl('firstName'),
    order: new FormControl<SortOrder>('asc_nulls_first'),
    ids: new FormControl<string[]>([]),
    type: new FormControl<string[]>([]),
  });

  public columns: TableColumn<User>[] = [];

  public tableHeaderButtons: TableHeaderButton[] = [
    {
      type: 'default-button',
      text: 'Add User',
      icon: 'bi-plus',
      variant: 'primary',
      handler: () => this.handleAddUser(),
      allowedRoles: [UserRole.ORGANIZATION_MASTER],
    },
  ];

  public subscriptions = new Subscription();

  public page = 1;
  public limit = 50;

  public selectedUsers: User[] = [];

  constructor(
    private usersService: UsersService,
    private organizationsService: OrganizationsService,
    private propertiesService: PropertiesService,
    private functionsService: FunctionsService,
    private router: Router,
    private matDialog: MatDialog,
    private location: Location,
  ) {}

  public ngOnInit(): void {
    const state = this.location.getState();

    if (state && state['organizationId']) {
      this.filterForm.patchValue({
        organizationId: [state['organizationId']],
      });
    }

    if (state && state['propertyId']) {
      this.filterForm.patchValue({
        propertyId: [state['propertyId']],
      });
    }

    if (state && state['ids']) {
      this.filterForm.patchValue({
        ids: state['ids'],
      });
    }

    if (state && state['type']) {
      this.filterForm.patchValue({
        type: [state['type']],
      });
    }

    this.listUsers(undefined, true);

    this.subscriptions.add(
      this.filterForm.valueChanges.subscribe(() => this.listUsers(true)),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async listUsers(loadJustUsers = false, forceRefresh = false) {
    try {
      this.isLoading = true;

      this.users = [];



      const response = await this.usersService.list({
        page: this.page,
        limit: this.limit,
        search: this.filterForm.value.search,
        organizationId: this.filterForm.value.organizationId?.length
          ? this.filterForm.value.organizationId.map(Number)
          : null,
        propertyId: this.filterForm.value.propertyId?.length
          ? this.filterForm.value.propertyId.map(Number)
          : null,
        role: this.filterForm.value.role?.length
          ? (this.filterForm.value.role as UserRole[])
          : [],
        ids: this.filterForm.value.ids?.length
          ? this.filterForm.value.ids.map(Number)
          : null,
        type: this.filterForm.value.type?.length
          ? this.filterForm.value.type as UserType[]
          : null,
        orderBy: this.filterForm.value.orderBy || 'firstName',
        order: this.filterForm.value.order || 'asc_nulls_first',
        forceRefresh,
      });

      this.users = response.users;
      this.totalItems = response.totalItems;
      this.totalPages = Math.ceil(response.totalItems / this.limit);

      if (!loadJustUsers) {
        const [organizations, { properties }] = await Promise.all([
          this.organizationsService.list({ limit: 999999 }),
          this.propertiesService.list({ limit: 999999 }),
        ]);

        this.buildOrganizationOptions(organizations.organizations);
        this.buildPropertiesOptions(properties);

        this.buildTableColumns();
      }

      this.isLoading = false;
    } catch (error) {
      console.error(error);

      this.isLoading = false;

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to list users',
          text: error,
        } as DialogAlertParams,
      });
    }
  }

  private buildOrganizationOptions(organizations: Organization[]) {
    const options: TableColumnHeaderOptions[] = organizations.map(
      organization => ({
        label: organization.name,
        value: String(organization.id),
        isSelected: false,
      }),
    );

    this.organizationsOptions = options;
  }

  private buildPropertiesOptions(properties: Property[]) {
    const options: TableColumnHeaderOptions[] = properties.map(property => ({
      label: property.name,
      value: String(property.id),
      isSelected: false,
    }));

    this.propertiesOptions = options;
  }

  private buildTableColumns(): void {
    console.log(this.usersService.isLoggedUserCustomer)
    if(!this.usersService.isLoggedUserCustomer) {
      this.columns = [
        {
          id: 'name',
          sortable: true,
          type: TableColumnType.PICTURE_AND_TEXT,
          label: 'Name',
          pictureAndTextValueFn: user => ({
            text: user.firstName + ' ' + user.lastName,
            picture: user.photo,
            isHyperlink: true,
          }),
          headerClasses: 'text-left',
          valueClasses: 'text-left text-black',
          columnLink: user => ({ url: `/users/${user.id}`, state: {} }),
        },
        {
          id: 'type',
          sortable: true,
          type: TableColumnType.TEXT,
          label: 'Type',
          valueFn: user => UserTypeLabel[user.type],
          headerClasses: 'text-center justify-center',
          valueClasses: 'text-center',
          ...(
            !this.usersService.isLoggedUserCustomer
              ? {
                filter: {
                  type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
                  control: this.filterForm.controls.type,
                  options: [
                    { isSelected: false, label: UserTypeLabel.ARKIQ, value: UserType.ARKIQ },
                    { isSelected: false, label: UserTypeLabel.CUSTOMER, value: UserType.CUSTOMER },
                  ]
                }
              }
              : {}
          )
        },
        {
          id: 'role',
          type: TableColumnType.SHOW_MORE,
          label: 'Roles',
          showMoreObject: user => {
            if (
              user.users_organizations_roles &&
              user.users_organizations_roles.length
            ) {
              return this.createShowMoreRoles(user.users_organizations_roles);
            }
            return {
              first: 'N/A',
              more: [],
            };
          },
          filter: {
            type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
            options: Object.values(UserRole).map(userRole => ({
              label: UserRoleLabel[userRole],
              value: userRole,
              isSelected: false,
            })),
            control: this.filterForm.controls.role,
          },
          headerClasses: 'text-center justify-center',
          columnLink: user => ({url: `/users/${user.id}`, state: {}})
        },
        {
          id: 'property',
          type: TableColumnType.SHOW_MORE,
          label: 'Properties',
          showMoreObject: user => {
            if (
              user.users_organizations_roles &&
              user.users_organizations_roles.length
            ) {
              return this.createShowMoreProperties(
                user.users_organizations_roles,
              );
            }
            return {
              first: 'N/A',
              more: [],
            };
          },
          headerClasses: 'text-center justify-center',
          valueClasses: 'text-center text-black',
          filter: {
            type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
            options: this.propertiesOptions,
            control: this.filterForm.controls.propertyId,
          },
          columnLink: user => {
            const propertiesIds = this.returnPropertiesIds(user.users_organizations_roles)
            if(!propertiesIds) {
              return ({url: '/properties', state:{}})
            }
            if (propertiesIds.length === 1) {
              return ({
                url: `/properties/${propertiesIds[0]}`, state: {}
              })
            }
            return ({ url: `/properties`, state: {
            ids: this.returnPropertiesIds(user.users_organizations_roles)
          } })},
        },
        {
          type: TableColumnType.SHOW_MORE,
          id: 'organization',
          label: 'Organization',
          showMoreObject: user => {
            if (
              user.users_organizations_roles &&
              user.users_organizations_roles.length
            ) {
              return this.createShowMoreOrganizations(
                user.users_organizations_roles,
              );
            }
            return {
              first: 'N/A',
              more: [],
            };
          },
          headerClasses: 'text-center justify-center',
          valueClasses: 'text-center text-black ' ,
          filter: {
            type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
            options: this.organizationsOptions,
            control: this.filterForm.controls.organizationId,
          },
          columnLink: user =>{
            const organizationIds = this.returnOrganizationsIds(user.users_organizations_roles)
            if(!organizationIds) {
              return ({url: '/organizations', state:{}})
            }

            if (organizationIds.length === 1) {
              return ({
                url: `/organizations/${organizationIds[0]}`, state: {}
              })
            }

            return ({ url: `/organizations`, state: {
              ids:this.returnOrganizationsIds(user.users_organizations_roles)
            } })
        },
        },
        // {
        //   id: 'pending-communication',
        //   type: TableColumnType.TEXT,
        //   label: 'Pending Communication',
        //   valueFn: () => '-',
        //   headerClasses: 'text-center justify-center',
        //   valueClasses: 'text-center',
        // },
        {
          id: 'last-login',
          sortable: true,
          type: TableColumnType.TEXT,
          label: 'Last Login',
          valueFn: user =>
            user.lastLogin ? formatDate(user.lastLogin, 'MMM dd, yyyy') : '-',
          headerClasses: 'text-center',
          valueClasses: 'text-center',
        },
        {
          id: 'actions',
          type: TableColumnType.ACTIONS,
          label: 'Actions',
          allowedRoles: [UserRole.ORGANIZATION_MASTER],
          headerClasses: 'text-center justify-center',
          valueClasses: 'text-center',
          actionButtons: [
            {
              type: 'icon-button',
              icon: 'bi-pencil-square',
              handler: user => this.handleEditUser(user),
              tooltip: 'Edit user',
            },
            {
              type: 'icon-button',
              icon: 'bi-trash-fill',
              handler: user => this.handleDeleteUser(user),
              tooltip: 'Delete user',
            },
          ],
        },
      ];
      return
    }

    this.columns = [
      {
        id: 'name',
        sortable: true,
        type: TableColumnType.PICTURE_AND_TEXT,
        label: 'Name',
        pictureAndTextValueFn: user => ({
          text: user.firstName + ' ' + user.lastName,
          picture: user.photo,
          isHyperlink: true,
        }),
        headerClasses: 'text-left',
        valueClasses: 'text-left text-black',
        columnLink: user => ({ url: `/users/${user.id}`, state: {} }),
      },
      {
        id: 'property',
        type: TableColumnType.SHOW_MORE,
        label: 'Properties',
        showMoreObject: user => {
          if (
            user.users_organizations_roles &&
            user.users_organizations_roles.length
          ) {
            return this.createShowMoreProperties(
              user.users_organizations_roles,
            );
          }
          return {
            first: 'N/A',
            more: [],
          };
        },
        headerClasses: 'text-center justify-center',
        valueClasses: 'text-center text-black',
        filter: {
          type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
          options: this.propertiesOptions,
          control: this.filterForm.controls.propertyId,
        },
        columnLink: user => {
          const propertiesIds = this.returnPropertiesIds(user.users_organizations_roles)
          if(!propertiesIds) {
            return ({url: '/properties', state:{}})
          }
          if (propertiesIds.length === 1) {
            return ({
              url: `/properties/${propertiesIds[0]}`, state: {}
            })
          }
          return ({ url: `/properties`, state: {
          ids: this.returnPropertiesIds(user.users_organizations_roles)
        } })},
      },
      {
        type: TableColumnType.SHOW_MORE,
        id: 'organization',
        label: 'Organization',
        showMoreObject: user => {
          if (
            user.users_organizations_roles &&
            user.users_organizations_roles.length
          ) {
            return this.createShowMoreOrganizations(
              user.users_organizations_roles,
            );
          }
          return {
            first: 'N/A',
            more: [],
          };
        },
        headerClasses: 'text-center justify-center',
        valueClasses: 'text-center text-black ' ,
        filter: {
          type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
          options: this.organizationsOptions,
          control: this.filterForm.controls.organizationId,
        },
        columnLink: user =>{
          const organizationIds = this.returnOrganizationsIds(user.users_organizations_roles)
          if(!organizationIds) {
            return ({url: '/organizations', state:{}})
          }

          if (organizationIds.length === 1) {
            return ({
              url: `/organizations/${organizationIds[0]}`, state: {}
            })
          }

          return ({ url: `/organizations`, state: {
            ids:this.returnOrganizationsIds(user.users_organizations_roles)
          } })
      },
      },
      // {
      //   id: 'pending-communication',
      //   type: TableColumnType.TEXT,
      //   label: 'Pending Communication',
      //   valueFn: () => '-',
      //   headerClasses: 'text-center justify-center',
      //   valueClasses: 'text-center',
      // },
      {
        id: 'last-login',
        sortable: true,
        type: TableColumnType.TEXT,
        label: 'Last Login',
        valueFn: user =>
          user.lastLogin ? formatDate(user.lastLogin, 'MMM dd, yyyy') : '-',
        headerClasses: 'text-center',
        valueClasses: 'text-center',
      },
      {
        id: 'actions',
        type: TableColumnType.ACTIONS,
        label: 'Actions',
        allowedRoles: [UserRole.ORGANIZATION_MASTER],
        headerClasses: 'text-center justify-center',
        valueClasses: 'text-center',
        actionButtons: [
          {
            type: 'icon-button',
            icon: 'bi-pencil-square',
            handler: user => this.handleEditUser(user),
            tooltip: 'Edit user',
          },
          {
            type: 'icon-button',
            icon: 'bi-trash-fill',
            handler: user => this.handleDeleteUser(user),
            tooltip: 'Delete user',
          },
        ],
      },
    ];

  }

  public onClearFilters() {
    this.filterForm.setValue({
      organizationId: [],
      propertyId: [],
      role: [],
      search: '',
      order: 'asc_nulls_first',
      orderBy: 'firstName',
      ids: [],
      type: [],
    });
  }

  public handleAddUser(): void {
    this.router.navigateByUrl('/users/create');
  }

  public handleEditUser(user: User) {
    this.router.navigateByUrl(`/users/${user.id}/edit`, { state: { user } });
  }

  public handleDeleteUser(user: User) {
    const ref = this.matDialog.open(DialogConfirmActionComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        title: 'Are you sure?',
        text: `Are you sure you want to delete ${user.firstName} ${user.lastName}?`,
      },
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(async data => {
        if (data.confirm) {
          this._deleteUser(user.id, user.firebaseId);
        }
        this.showSelectedUser = false;
      });
  }

  public handleDeleteManyUsers() {
    if (!this.selectedUsers.length) {
      return;
    }

    const ref = this.matDialog.open(DialogConfirmActionComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        title: 'Are you sure?',
        text: `Are you sure you want to delete ${this.selectedUsers.length} users?`,
      },
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(async data => {
        if (data.confirm) {
          this._deleteManyUsers();
        }
      });
  }

  private async _deleteUser(userId: number, firebaseId: string) {
    if (userId === this.usersService.loggedUser?.id) {
      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.WARNING,
          title: 'You cannot delete yourself',
          text: 'You are trying to delete your own user',
        } as DialogAlertParams,
      });

      return;
    }

    try {
      this.isLoading = true;

      await Promise.all([
        this.usersService.delete(userId),
        this.functionsService.deleteUser(firebaseId),
      ]);

      await this.listUsers(undefined, true);

      this.isLoading = false;
    } catch (error) {
      console.error(error);

      this.isLoading = false;

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to delete user',
          text: error,
        } as DialogAlertParams,
      });
    }
  }

  private async _deleteManyUsers() {
    if (!this.selectedUsers.length) {
      return;
    }

    if (this.selectedUsers.find(user => user.id === this.usersService.loggedUser?.id)) {
      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.WARNING,
          title: 'You cannot delete yourself',
          text: 'You are trying to delete your own user',
        } as DialogAlertParams,
      });

      return;
    }

    try {
      this.isLoading = true;

      const usersIds = this.selectedUsers.map(user => user.id);
      const usersUids = this.selectedUsers.map(user => user.firebaseId);

      await Promise.all([
        this.usersService.deleteMany(usersIds),
        this.functionsService.deleteManyUsers(usersUids),
      ]);

      await this.listUsers(undefined, true);

      this.selectedUsers = [];

      this.isLoading = false;
    } catch (error) {
      console.error(error);

      this.isLoading = false;

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to delete user',
          text: error,
        } as DialogAlertParams,
      });
    }
  }

  public onTableRowClicked(event: TableRowClickedEvent<User>) {
    this.router.navigateByUrl(`/users/${event.data.id}`, {
      state: { user: event.data },
    });
  }

  public onTableRowsSelected(users: User[]) {
    this.selectedUsers = users;
  }


  public async onAllRowsSelected() {
    if(this.showSelectedUser) {
      this.selectedUsers = []
      this.showSelectedUser = false;
      return
    }
    this.selectedUsers = [this.users[0]]
    this.showSelectedUser = true;
    this.selectedUsersLoading = true;
    const response = await this.usersService.list({
      page: this.page,
      limit: 9999,
      search: this.filterForm.value.search,
      organizationId: this.filterForm.value.organizationId?.length
        ? this.filterForm.value.organizationId.map(Number)
        : null,
      propertyId: this.filterForm.value.propertyId?.length
        ? this.filterForm.value.propertyId.map(Number)
        : null,
      role: this.filterForm.value.role?.length
        ? (this.filterForm.value.role as UserRole[])
        : [],
      ids: this.filterForm.value.ids?.length
        ? this.filterForm.value.ids.map(Number)
        : null,
      type: this.filterForm.value.type?.length
        ? this.filterForm.value.type as UserType[]
        : null,
      orderBy: this.filterForm.value.orderBy || 'firstName',
      order: this.filterForm.value.order || 'asc_nulls_first'
    });
    this.selectedUsers = response.users;
    this.selectedUsersLoading = false;
  }


  public onSearch(search: string) {
    this.filterForm.patchValue({ search });
  }

  public onTableRowsLimitChanged(newLimit: number): void {
    this.limit = newLimit;
    this.listUsers(true);
  }

  public onTablePageChanged(newPage: number): void {
    this.page = newPage;
    this.listUsers(true);
  }

  public onTableSortChanged(event: TableSortChangeEvent) {
    switch (event.columnId) {
      case 'name':
        this.filterForm.patchValue({
          orderBy: 'firstName',
          order: event.order,
        });
        break;

      case 'type':
        this.filterForm.patchValue({ orderBy: 'type', order: event.order });
        break;

      case 'last-login':
        this.filterForm.patchValue({
          orderBy: 'lastLogin',
          order: event.order,
        });
        break;
    }
  }

  private createShowMoreRoles(
    userOrganizationRoles: UserOrganizationRole[],
  ): TableColumnShowMore {
    if (userOrganizationRoles.length === 0) {
      return { first: '', more: [] };
    }

    const sortedUserRoles = userOrganizationRoles
      .sort((a, b) =>
        UserRoleValues[a.role] > UserRoleValues[b.role] ? -1 : 1,
      )
      .map(userOrganization => userOrganization.role);

    const filteredUserRoles = Array.from(new Set(sortedUserRoles));
    return {
      first: UserRoleLabel[filteredUserRoles[0]],
      more: filteredUserRoles.slice(1).map(role => UserRoleLabel[role]),
    };
  }


  private returnPropertiesIds(userOrganizationRoles?: UserOrganizationRole[]) {
    if(!userOrganizationRoles) return
    if(userOrganizationRoles.length === 0) {
      return []
    }
    const propertiesIds = userOrganizationRoles.map(organization => {
      if (!organization?.property?.id) return
      return organization.property.id
    } ).filter(Number)

    const filteredPropertiesIds = Array.from(new Set(propertiesIds))
    return filteredPropertiesIds
  }

  private returnOrganizationsIds(userOrganizationRoles?: UserOrganizationRole[]) {
    if(!userOrganizationRoles) return
    if(userOrganizationRoles.length === 0) {
      return
    }
    const organizationsIds = userOrganizationRoles.map(organization => {
      if (!organization.organizationId) return
      return organization.organizationId
    } ).filter(Number)

    const filteredOrganizationsIds = Array.from(new Set(organizationsIds))
    return filteredOrganizationsIds
  }
  private createShowMoreProperties(
    userOrganizationRoles: UserOrganizationRole[],
  ): TableColumnShowMore {
    if (userOrganizationRoles.length === 0) {
      return { first: '', more: [] };
    }
    const properties: Array<string> = [];
    userOrganizationRoles.forEach(organization => {
      if (!organization?.property?.name) return;
      properties.push(organization?.property?.name);
    });
    const filteredUserProperties = Array.from(new Set(properties));

    return {
      first: filteredUserProperties[0] || 'N/A',
      more: filteredUserProperties.slice(1),
    };
  }

  private createShowMoreOrganizations(
    userOrganizationRoles: UserOrganizationRole[],
  ): TableColumnShowMore {
    if (userOrganizationRoles.length === 0) {
      return { first: '', more: [] };
    }
    const organizations: Array<string> = [];
    userOrganizationRoles.forEach(organization => {
      if (!organization?.organization?.name) return;
      organizations.push(organization?.organization?.name);
    });
    const filteredUserOrganizations = Array.from(new Set(organizations));
    return {
      first: filteredUserOrganizations[0] || 'N/A',
      more: filteredUserOrganizations.slice(1),
    };
  }
}
