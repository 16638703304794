<div class="w-full h-full flex flex-col">
  <header
    class="w-full px-10 md:px-16 lg:px-28 2xl:px-44 py-8 md:py-14 flex flex-col gap-11 md:gap-14"
  >
    <!-- <arkiq-auth-steps /> -->

    <div class="w-full flex flex-col gap-5 items-center">
      <h1 class="m-0 font-semibold text-black text-4xl text-center">
        Define your password
      </h1>
    </div>
  </header>

  <main
    class="flex-1 items-center w-full px-14 md:px-40 lg:px-44 2xl:px-56 pb-14"
  >
    <form class="w-full flex flex-col gap-7">
      <arkiq-password-input
        label="Password"
        placeholder="Your password"
        [control]="form.controls.password"
        [invalid]="
          (form.controls.password.touched || form.controls.password.dirty) &&
          form.controls.password.invalid
        "
        [attr.disabled]="isLoading"
        size="lg"
        (keyup.enter)="handleResetPassword()"
      />

      <arkiq-password-input
        label="Password confirmation"
        placeholder="Confirm your password"
        [control]="form.controls.passwordConfirmation"
        [invalid]="
          (form.controls.passwordConfirmation.touched || form.controls.passwordConfirmation.dirty) &&
          form.controls.passwordConfirmation.invalid
        "
        [attr.disabled]="isLoading"
        size="lg"
        (keyup.enter)="handleResetPassword()"
      />

      @if (form.errors && form.errors['confirmed']) {
        <small class="text-state-cancel">
          Password does not match
        </small>
      }

      <arkiq-button
        text="Save password"
        [disabled]="form.invalid"
        [loading]="isLoading"
        (click)="handleResetPassword()"
      />
    </form>
  </main>
</div>
