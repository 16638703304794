<div class="w-screen h-screen grid grid-cols-1 lg:grid-cols-12">
  <main class="col-span-6 h-full bg-white">
    <router-outlet />
  </main>

  <aside class="col-span-6 h-full hidden lg:block">
    <img
      src="assets/background.png"
      alt="Background"
      class="object-cover w-full h-full"
    />
  </aside>
</div>
