import { BuildOrganization, BuildUserOrganizationRole, Organization, PropertyType, UserOrganizationRole } from '.';

export type Property = {
  id: number;
  type: PropertyType;
  name: string;
  address_street: string;
  address_city: string;
  address_state: string;
  address_zip_code: string;
  organizationId: number;
  photo: string;
  created_at: Date;
  updated_at: Date;
  users_organizations_roles?: UserOrganizationRole[];
  organization?: Organization;
  root_location_id: number;
};

export function BuildProperty(data: any): Property {
  return {
    id: data.id,
    type: data.type ?? '',
    name: data.name ?? '',
    address_street: data.address_street ?? '',
    address_city: data.address_city ?? '',
    address_state: data.address_state ?? '',
    address_zip_code: data.address_zip_code ?? '',
    organizationId: data.organizationId,
    photo: data.photo ? data.photo : `https://ui-avatars.com/api/?background=random&name=${data.name}`,
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
    users_organizations_roles: data.users_organizations_roles?.length > 0
      ? data.users_organizations_roles.map((userOrganizationRole: any) => BuildUserOrganizationRole(userOrganizationRole))
      : [],
    organization: data.organization ? BuildOrganization(data.organization) : undefined,
    root_location_id: data.root_location_id,
  };
}
