import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import {
    Organization,
    OrganizationsService,
    SortOrder,
    UserRole
} from '@arkiq-portals/sdk';
import {
    ButtonComponent,
    ClearFiltersTypes,
    DialogConfirmActionComponent,
    InputComponent,
    TableColumn,
    TableColumnHeaderFilterType,
    TableColumnHeaderOptions,
    TableColumnType,
    TableComponent,
    TableHeaderButton,
    TableRowClickedEvent,
    TableSortChangeEvent,
} from '@arkiq-portals/ui';
import { debounceTime, distinctUntilChanged, Subscription, take } from 'rxjs';

@Component({
  selector: 'app-organizations-list',
  templateUrl: 'organizations-list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    ButtonComponent,
    InputComponent,
    TableComponent,
  ],
  styles: [`:host {display:flex; flex:1;}`]
})
export class OrganizationsListComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public organizations: Organization[] = [];
  public page = 1;
  public limit = 50;
  public totalItems = 0;
  public totalPages = 1;
  public CLEAR_FILTERS_BUTTON_TYPE = ClearFiltersTypes.TEXT

  public columns: TableColumn<Organization>[] = [];
  public subscriptions = new Subscription();

  public filterForm = new FormGroup({
    search: new FormControl(''),
    masters: new FormControl<string[]>([]),
    organizationsIds: new FormControl<string[]>([]),
    names: new FormControl<string[]>([]),
    orderBy: new FormControl('name'),
    order: new FormControl<SortOrder>('asc_nulls_first'),
  });

  public masterOptions: TableColumnHeaderOptions[] = [];
  public organizationNamesOptions: TableColumnHeaderOptions[] = [];

  public tableHeaderButtons: TableHeaderButton[] = [
    {
      type: 'default-button',
      text: 'Add Organization',
      icon: 'bi-plus',
      variant: 'primary',
      handler: () => this.handleAddOrganization(),
      allowedRoles: [UserRole.ADMIN],
    },
  ];

  constructor(
    private organizationsService: OrganizationsService,
    private router: Router,
    private dialog: MatDialog,
    private location: Location
  ) {}

  public ngOnInit() {

    const state = this.location.getState()

    if (state && state['ids']) {
      const organizationsIds = state['ids']
      this.filterForm.patchValue({
        organizationsIds
      })
    }
    this.listOrganizations(false);
    this.subscriptions.add(
      this.filterForm.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(400))
        .subscribe(() => this.listOrganizations()),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async listOrganizations(loadJustOrganizations = true) {
    try {
      this.isLoading = true;
      this.organizations = [];
      const response = await this.organizationsService.list({
        page: this.page,
        limit: this.limit,
        search: this.filterForm.value.search,
        names: this.filterForm.value.names,
        masters: this.filterForm.value.masters,
        orderBy: this.filterForm.value.orderBy || 'firstName',
        order: this.filterForm.value.order || 'asc_nulls_first',
        organizationsIds: this.filterForm.value.organizationsIds?.length
          ? this.filterForm.value.organizationsIds.map(Number)
          : undefined,
      });

      this.organizations = response.organizations;
      this.totalItems = response.totalItems;
      this.totalPages = Math.ceil(response.totalItems / this.limit);

      if (!loadJustOrganizations) {
        this.buildOrganizationMasterOptions();
        this.buildOrganizationNamesOptions();
      }
      this.buildTableColumns();

      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      alert(error);
    }
  }
  public buildOrganizationNamesOptions() {
    const names = this.organizations.map(organization => {
      return {
        value: String(organization.id),
        label: `${organization.name}`,
        isSelected: false,
      };
    });

    const formattedNames: TableColumnHeaderOptions[] = names.filter(
      (master, index, self): master is TableColumnHeaderOptions =>
        !!master &&
        index === self.findIndex(other => other?.value === master.value),
    );

    this.organizationNamesOptions = formattedNames;
  }
  public buildOrganizationMasterOptions() {
    const masters = this.organizations.map(organization => {
      if (!organization.users_organizations_roles) return undefined;
      const master = organization.users_organizations_roles.find(
        userRole => userRole.role === UserRole.ORGANIZATION_MASTER,
      );
      if (!master || !master.user) return undefined;

      return {
        value: String(master.user.id),
        label: `${master.user.firstName} ${master.user.lastName}`,
        isSelected: false,
      };
    });

    const formattedMasters: TableColumnHeaderOptions[] = masters.filter(
      (master, index, self): master is TableColumnHeaderOptions =>
        !!master &&
        index === self.findIndex(other => other?.value === master.value),
    );

    this.masterOptions = formattedMasters;
  }
  public buildTableColumns() {
    this.columns = [
      {
        type: TableColumnType.PICTURE_AND_TEXT,
        label: 'Name',
        id: 'name',
        sortable: true,
        pictureAndTextValueFn: organization => ({
          text: organization.name,
          picture: `https://ui-avatars.com/api/?background=random&name=${organization.name}`,
          isHyperlink: true,
        }),
        headerClasses: 'text-left !justify-start',
        valueClasses: 'text-left text-black',
        filter: {
          type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
          options: this.organizationNamesOptions,
          control: this.filterForm.controls.names,
        },
        columnLink: organization => ({
          url: `/organizations/${organization.id}`,
          state: {},
        }),
      },
      {
        type: TableColumnType.PICTURE_AND_TEXT,
        label: 'Organization Master',
        sortable: true,
        id: 'organization-master',
        pictureAndTextValueFn: organization => {
          const master = organization.users_organizations_roles?.find(
            userRole => userRole.role === UserRole.ORGANIZATION_MASTER,
          )?.user;

          if (master) {
            return {
              text: `${master.firstName} ${master.lastName}`,
              picture: master.photo,
              isHyperlink: true,
            };
          } else {
            return {
              picture: 'https://ui-avatars.com/api/?background=random&name=NN',
              text: '-',
              isHyperlink: true,
            };
          }
        },
        headerClasses: 'text-center !justify-start',
        valueClasses: 'text-center text-black',
        filter: {
          type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
          options: this.masterOptions,
          control: this.filterForm.controls.masters,
        },
        columnLink: organization => {
          const master = organization.users_organizations_roles?.find(
            userRole => userRole.role === UserRole.ORGANIZATION_MASTER,
          )?.userId;

          return {
            url: `/users/${master}`,
            state: {},
          }
        },
      },
      {
        type: TableColumnType.TEXT,
        label: 'Properties',
        sortable: true,
        id: 'properties',
        valueFn: organization => String(organization.properties?.length ?? 0),
        headerClasses: 'text-center justify-center',
        valueClasses: 'text-center text-black',
        columnLink: organization => ({
          url: `/organizations/${organization.id}/properties`,
          state: {},
        }),
      },
      {
        type: TableColumnType.TEXT,
        label: 'Users',
        sortable: true,
        id: 'users',
        valueFn: organization => this.getOrganizationUsersCount(organization),
        headerClasses: 'text-center justify-center',
        valueClasses: 'text-center text-black',
        columnLink: organization => {
          const usersIds = Array.from(
            new Set(organization.users_organizations_roles?.map(userRole => userRole.userId))
          );

          return {
            url: '/users',
            state: { ids: usersIds },
            disabled: !usersIds.length,
          };
        },
      },
      {
        type: TableColumnType.TEXT,
        label: 'Devices',
        sortable: true,
        id: 'devices',
        valueFn: organization =>
          organization.devices_details_aggregate?.aggregate.count,
        headerClasses: 'text-center justify-center',
        valueClasses: 'text-center text-black',
        columnLink: organization => ({
          url: '/devices',
          state: { organizationId: organization.id },
        }),
      },
      {
        type: TableColumnType.TEXT,
        label: 'Alerts',
        sortable: true,
        id: 'alerts',
        valueFn: organization => organization.alerts_aggregate?.aggregate.count,
        headerClasses: 'text-center justify-center',
        valueClasses: 'text-center text-black',
        columnLink: organization => ({
          url: '/alerts',
          state: { organizationId: organization.id },
        }),
      },
      {
        type: TableColumnType.TEXT,
        label: 'Devices Online',
        // sortable: true,
        // id: 'devices-online',
        valueFn: organization =>
          organization.devices_online?.aggregate.count ?? 0,
        headerClasses: 'text-center justify-center',
        valueClasses: 'text-center text-black',
        columnLink: organization => ({
          url: '/devices',
          state: { organizationId: organization.id, status: 'online' },
        }),
      },
      {
        type: TableColumnType.ACTIONS,
        label: 'Actions',
        allowedRoles: [UserRole.ORGANIZATION_MASTER],
        headerClasses: 'text-center justify-center',
        valueClasses: 'text-center text-black',
        actionButtons: [
          {
            type: 'icon-button',
            icon: 'bi-pencil-square',
            handler: organization => this.handleEditOrganization(organization),
            tooltip: 'Edit organization',
          },
          {
            type: 'icon-button',
            icon: 'bi-trash-fill',
            handler: organization =>
              this.handleDeleteOrganization(organization),
            tooltip: 'Delete organization',
          },
        ],
      },
    ];
  }

  public handleAddOrganization(): void {
    this.router.navigateByUrl('/organizations/create');
  }

  public handleEditOrganization(organization: Organization) {
    this.router.navigateByUrl(`/organizations/${organization.id}/edit`, {
      state: { organization },
    });
  }

  public handleDeleteOrganization(organization: Organization) {
    const ref = this.dialog.open(DialogConfirmActionComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        title: 'Are you sure?',
        text: `Are you sure you want to delete ${organization.name}?`,
      },
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(async data => {
        if (data.confirm) {
          this._deleteOrganization(organization.id);
        }
      });
  }

  private async _deleteOrganization(organizationId: number) {
    try {
      this.isLoading = true;

      await this.organizationsService.delete(organizationId);
      await this.listOrganizations();

      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      alert(error);
    }
  }

  public onSearch(search: string) {
    this.filterForm.patchValue({ search });
  }

  public onOrganizationClicked(event: TableRowClickedEvent<Organization>) {
    const organization = event.data;
    this.router.navigateByUrl(`/organizations/${organization.id}`, {
      state: { organization },
    });
  }

  public onClearFilters() {
    this.filterForm.setValue({
      masters: [],
      names: [],
      order: 'asc_nulls_first',
      orderBy: 'name',
      search: '',
      organizationsIds: []
    });
  }

  public onTableRowsLimitChanged(newLimit: number): void {
    this.limit = newLimit;
    this.listOrganizations(true);
  }

  public onTablePageChanged(newPage: number): void {
    this.page = newPage;
    this.listOrganizations(true);
  }

  public onTableSortChanged(event: TableSortChangeEvent) {
    switch (event.columnId) {
      case 'name':
        this.filterForm.patchValue({
          orderBy: 'name',
          order: event.order,
        });
        break;
      case 'organization-master':
        this.filterForm.patchValue({
          orderBy: 'organization-master',
          order: event.order,
        });
        break;
      case 'properties':
        this.filterForm.patchValue({
          orderBy: 'properties',
          order: event.order,
        });
        break;
      case 'users':
        this.filterForm.patchValue({
          orderBy: 'users',
          order: event.order,
        });
        break;
      case 'devices':
        this.filterForm.patchValue({
          orderBy: 'devices',
          order: event.order,
        });
        break;
      case 'alerts':
        this.filterForm.patchValue({
          orderBy: 'alerts',
          order: event.order,
        });
        break;
      case 'devices-online':
        this.filterForm.patchValue({
          orderBy: 'devices-online',
          order: event.order,
        });
        break;
      case 'alerts':
          this.filterForm.patchValue({
            orderBy: 'alerts',
            order: event.order,
          });
          break;
    }
  }

  public getOrganizationUsersCount(organization: Organization) {
    if (!organization.users_organizations_roles) {
      return '0';
    };

    const userIds = organization.users_organizations_roles.map(
      user_organization_roles => user_organization_roles.userId,
    );
    const filteredUserId = new Set(userIds).size.toString();
    return filteredUserId;
  }
}
