<div *ngIf="isLoading" class="w-full h-full flex items-center justify-center">
  <arkiq-spinner />
</div>

<div *ngIf="!isLoading" class="w-full h-full flex justify-center">
  <div class="w-full bg-white h-full rounded-xl">
    <header class="flex items-center gap-2 py-5 px-6">
      <arkiq-icon-button (click)="handleGoBack()" icon="bi-chevron-left" />

      <h2 class="m-0 font-bold text-base">
        {{ organization ? 'Edit Organization' : 'Add New Organization' }}
      </h2>
    </header>

    <main class="w-full gap-[30px] pt-6 grid grid-cols-1 lg:grid-cols-2">
      <section class="w-full flex flex-col border-r border-r-[#E0E0E0]">
        <div
          class="flex flex-col gap-[30px] pb-6 px-6 border-b border-b-[#E0E0E0]"
        >
          <arkiq-input
            [control]="form.controls.name"
            [required]="true"
            [rounded]="false"
            label="Organization Name"
            placeholder="Organization Name"
            size="md"
          />

          <arkiq-text-area
            [control]="form.controls.description"
            [rounded]="false"
            label="Description (Optional)"
            placeholder="Description (Optional)"
            size="md"
          />
        </div>

        <div class="p-6 flex flex-col gap-[30px]">
          <h3 class="m-0 font-bold text-base">Organization Master User</h3>

          <arkiq-select-organization-master
            (createUser)="form.controls.master.setValue($event)"
            (resetUser)="form.controls.master.setValue(null)"
            (selectUser)="form.controls.master.setValue($event)"
            [initialValue]="form.value.master"
            addBtnLabel="Add organization master"
            (masterFormTouchedAndValid)="isMastersTouchedAndValid($event)"
          />
        </div>
      </section>

      <section class="w-full pb-6 px-6 lg:pl-0">
        @if (shouldCreateProperty) {
          <app-add-organization-property-form
            [data]="propertyToEdit"
            (saveProperty)="onSaveProperty($event)"
            (discardAction)="discardPropertyForm()"
          />
        } @if (!shouldCreateProperty) {
          <div class="w-full">
            <div class="flex flex-col gap-4 pb-6 border-b border-[#E0E0E0]">
              <h3 class="font-bold text-base">Properties {{propertyQuantity}}</h3>
              @if(propertiesToSave.length > 0) {
                <app-organization-property-card
                  [properties]="propertiesToSave"
                  (edit)="editProperty($event)"
                  (delete)="deleteProperty($event)"
                />
              } @else {
                <div
                  class="w-full bg-[#F2F2F2] flex items-center justify-center py-8 rounded-xl"
                >
                  <span class="text-[#727272] text-base font-light">
                    You didn't add any properties yet
                  </span>
                </div>
              }
            </div>
            <div class="flex justify-end mt-6">
              <arkiq-button
                text="Create New Property"
                size="md"
                variant="outline-primary"
                [rounded]="false"
                (click)="toggleCreateNewProperty()"
              />
            </div>
          </div>
        }
      </section>
    </main>

    <footer
      class="flex items-center justify-end gap-2 px-6 py-[18px] border-t border-t-[#E0E0E0]"
    >
      <arkiq-button
        (click)="handleGoBack()"
        [disabled]="isSaving"
        [rounded]="false"
        size="md"
        text="Cancel"
        variant="tertiary"
      />

      <arkiq-button
        (click)="handleSave()"
        [disabled]="isSaving"
        [loading]="isSaving"
        [rounded]="false"
        [text]="organization ? 'Save Organization' : 'Add Organization'"
        size="md"
        variant="primary"
      />
    </footer>
  </div>
</div>
