import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '@arkiq-portals/ui';

@Component({
  selector: 'app-devices',
  templateUrl: 'devices.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent],
})

export class DevicesComponent {}
