<div *ngIf="isLoading" class="w-full h-full flex items-center justify-center">
  <arkiq-spinner />
</div>

<div *ngIf="!isLoading" class="w-full flex flex-col gap-[26px]">
  <app-organization-summary-cards [organization]="organization" />

  <!-- <div class="border-2 border-dashed border-black/20 w-full h-[300px] flex items-center justify-center bg-white shadow-lg rounded-[20px]">
    <span class="opacity-30">[Chart here]</span>
  </div> -->
</div>
