import { ArkIQDevice } from './arkiq-device';
import { BuildOrganization, Organization } from './organization';
import { Property } from './property';

export type Device = {
  id: string;
  type: DeviceType;
  organization: Organization;
  organizationId: string;
  property: Property;
  propertyId: string;
  lastAlert: Date;
  alertInLast7Days: string;
  status: DeviceStatus;
  batteryLevel: DeviceBatteryLevel;
  device_details?: ArkIQDevice;
};

export function BuildDevice(data: any): Device {
  return {
    id: data.id,
    type: data.type,
    organizationId: data.organizationId,
    organization: BuildOrganization(data.organization),
    propertyId: data.porpertyId,
    property: data.property,
    lastAlert: new Date(data.lastAlert),
    alertInLast7Days: data.alertInLast7Days ?? 0,
    status: data.status,
    batteryLevel: DeviceBatteryLevel[String(data.batteryLevel).toUpperCase()],
  };
}

export enum DeviceType {
  LEAK_SENSOR = 'Leak Sensor',
  SMART_VALVE = 'Smart Valve',
  TOILET_SENSOR = 'Toilet Sensor',
  GATEWAY = 'Gateway',
}

export enum DeviceTypeLabel {
  DEVICE = '¿Type?',
}

export enum DeviceStatus {
  OFFLINE = 'offline',
  ONLINE = 'online',
}

export enum DeviceStatusLabel {
  OFFLINE = 'Offline',
  ONLINE = 'Online',
}

export enum DeviceBatteryLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}
export enum DeviceBatteryLevelLabel {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export enum DeviceBatteryLevelIcon {
  LOW = 'assets/low-battery-level.svg',
  MEDIUM = 'assets/medium-battery-level.svg',
  HIGH = 'assets/high-battery-level.svg',
}

export enum DeviceAlertType {
  ALL = 'ALL',
  ALERT = 'ALERT',
  NOTALERTED = 'NOTALERTED',
}

export enum DeviceAlertTypeLabel {
  ALL = 'ALL',
  ALERT = '¿alert?',
  NOTALERTED = 'NOT ALERTED',
}

export type Alerts = {
  alertType: DeviceAlertType;
  date: Date;
};

export enum Inventories {
  ALL = 'ALL',
  ARKIQ = 'arkIQ',
  CUSTOMERS = 'Customers',
}

export enum DeviceCategory {
  ALL = 'ALL',
  INSTALLED = 'INSTALLED',
  INVENTORY = 'INVENTORY',
}

export function BuildAlert(data: any): Alerts {
  return {
    alertType: data.alertType,
    date: new Date(data.date),
  };
}
