import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '@arkiq-portals/ui';

@Component({
  selector: 'app-organizations',
  templateUrl: 'organizations.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent]
})
export class OrganizationsComponent {}
