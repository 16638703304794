import { Location as AngularLocation, CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTreeModule } from '@angular/material/tree';
import { ActivatedRoute } from '@angular/router';
import {
  Location,
  PropertiesService,
  Property,
  PropertyLayoutTreeItem,
} from '@arkiq-portals/sdk';
import {
  ButtonComponent,
  DialogAddLocationComponent,
  DialogAddLocationParams,
  SpinnerComponent,
} from '@arkiq-portals/ui';
import { take } from 'rxjs';
import { PropertyDetailsLayoutTreeComponent } from './property-details-layout-tree/property-details-layout-tree.component';
import { PropertyDetailsNodeDetailsComponent } from './property-details-node-details/property-details-node-details.component';

@Component({
  selector: 'app-property-details-layout',
  templateUrl: 'property-details-layout.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatTreeModule,
    MatProgressBarModule,
    MatDialogModule,
    SpinnerComponent,
    PropertyDetailsLayoutTreeComponent,
    PropertyDetailsNodeDetailsComponent,
    ButtonComponent,
  ],
})
export class PropertyDetailsLayoutComponent implements OnInit {
  @ViewChild(PropertyDetailsLayoutTreeComponent)
  treeRef!: PropertyDetailsLayoutTreeComponent;

  public propertyId!: number;
  public property!: Property;

  public isLoading = false;

  public selectedNodePath: PropertyLayoutTreeItem[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private propertiesService: PropertiesService,
    private matDialog: MatDialog,
    private location: AngularLocation,
  ) {}

  public async ngOnInit(): Promise<void> {
    const routeParams = this.activatedRoute.parent?.snapshot.params;

    if (routeParams?.['id']) {
      this.propertyId = Number(routeParams['id']);
      await this.getProperty(this.propertyId);
    }

    const state = this.location.getState() as any;

    if (state && state.location) {
      this.onNavigateToSubLocation(state.location);
    }
  }

  public async getProperty(propertyId: number, forceRefresh = false) {
    try {
      this.isLoading = true;

      this.property = await this.propertiesService.getById(
        propertyId,
        forceRefresh,
      );

      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      alert(error);
    }
  }

  public onNodePathChanged(path: PropertyLayoutTreeItem[]) {
    this.selectedNodePath = path;
  }

  public onNavigateToSubLocation(event: Location) {
    this.treeRef.forceNodePathChange(event, this.selectedNodePath);
  }

  public handleAddLocation() {
    const ref = this.matDialog.open(DialogAddLocationComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        property: this.property,
        parentLocationPath: undefined,
        disableParentLocation: true,
      } as DialogAddLocationParams,
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data && data.reload) {
          this.getProperty(this.propertyId, true);
        }
      });
  }

  public onUpdateTree(items: PropertyLayoutTreeItem[]) {
    this.treeRef.forceUpdateNodesTree(items);
  }
}
