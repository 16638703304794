<div class="bg-white rounded-[20px] w-[486px] max-w-[90vw] flex flex-col">
  <header class="flex items-center justify-between w-full px-6 py-4 border-b border-b-black/10">
    <h1 class="m-0 font-bold text-lg">
      Create Organization
    </h1>

    <arkiq-icon-button
      tooltipText="Close dialog"
      icon="bi-x-lg"
      (click)="handleClose()"
    />
  </header>

  <main class="w-full flex flex-col gap-6 px-6 py-4">
    <arkiq-input
      label="Name"
      size="md"
      [rounded]="false"
      [required]="true"
      [control]="form.controls.name"
    />

    <arkiq-text-area
      label="Description (optional)"
      size="md"
      [rounded]="false"
      [control]="form.controls.description"
    />

    <arkiq-select-organization-master
      (selectUser)="form.controls.master.setValue($event)"
      (createUser)="form.controls.master.setValue($event)"
      (resetUser)="form.controls.master.setValue(null)"
      (masterFormTouchedAndValid)="isMastersTouchedAndValid($event)"
    />
  </main>

  <footer class="w-full flex items-center justify-between px-6 py-4 border-t border-t-black/10">
    <arkiq-button
      text="Cancel"
      variant="tertiary"
      size="md"
      [rounded]="false"
      (click)="handleClose()"
    />

    <arkiq-button
      text="Create"
      size="md"
      [rounded]="false"
      [loading]="isSaving"
      [disabled]="form.invalid || !isMasterValid"
      (click)="handleCreate()"
    />
  </footer>
</div>
