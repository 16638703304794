import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import {
  BuildPropertyLayout,
  BuildPropertyLayoutTree,
  PropertyLayout,
  PropertyLayoutTreeItem,
} from '../models';
import { FunctionsService } from './functions.service';

export type CreatePropertyLayoutDto = {
  propertyId: number;
  deviceId?: number;
  path: string;
};

const GET_PROPERTY_LAYOUT_QUERY = (propertyId: number) => {
  return gql`
    query GetPropertyLayout {
      sql_property_layout(args: {property_id: ${propertyId}}) {
        l_created_at
        l_id
        l_name
        l_parent_id
        l_property_id
        l_updated_at
        pl_device_id
        pl_id
        pl_path
        pl_property_id
      }
    }
  `;
};

const GET_PROPERTY_LAYOUT_QUERY_BY_LOCATION = (locationId: number, propertyId: number) => {
  return gql`
    query GetPropertyLayout {
      sql_property_layout(where: {l_id: { _eq: ${locationId} }}, args: {property_id: ${propertyId}}) {
        l_created_at
        l_id
        l_name
        l_parent_id
        l_property_id
        l_updated_at
        pl_device_id
        pl_id
        pl_path
        pl_property_id
      }
    }
  `;
};

export type ListPropertyLayoutResponse = {
  data: PropertyLayout[];
  tree: PropertyLayoutTreeItem[];
};

@Injectable({ providedIn: 'root' })
export class PropertyLayoutService {
  constructor(
    private apollo: Apollo,
    private functionsService: FunctionsService,
  ) {}

  public async listByPropertyId(
    propertyId: number,
    propertyRootLocationId: number,
  ): Promise<ListPropertyLayoutResponse> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<{ sql_property_layout: PropertyLayout[] }>({
          query: GET_PROPERTY_LAYOUT_QUERY(propertyId),
          fetchPolicy: 'no-cache',
        }),
      );

      const data = response.data.sql_property_layout
        .filter(pl => pl.l_id !== propertyRootLocationId)
        .map(BuildPropertyLayout);

      return {
        data,
        tree: BuildPropertyLayoutTree(data),
      };
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async listByLocationId(
    locationId: number,
    propertyId: number,
    propertyRootLocationId: number,
  ): Promise<ListPropertyLayoutResponse> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<{ sql_property_layout: PropertyLayout[] }>({
          query: GET_PROPERTY_LAYOUT_QUERY_BY_LOCATION(locationId, propertyId),
          fetchPolicy: 'no-cache',
        }),
      );

      const data = response.data.sql_property_layout
        .filter(pl => pl.l_id !== propertyRootLocationId)
        .map(BuildPropertyLayout);

      return {
        data,
        tree: BuildPropertyLayoutTree(data),
      };
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async create(data: CreatePropertyLayoutDto): Promise<void> {
    try {
      await this.functionsService.insertPropertyLayout(
        data.propertyId,
        data.path,
        data.deviceId,
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async deleteMany(locationsIds: number[]): Promise<void> {
    try {
      await firstValueFrom(
        this.apollo.mutate({
          mutation: gql`
            mutation DeleteManyPropertyLayout {
              delete_front_property_layout(
                where: {
                  id: {
                    _in: [${locationsIds.join(',')}]
                  }
                }
              ) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }
}
