<button
  type="button"
  class="flex items-center gap-3 outline-none"
  (click)="handleChangeValue()"
>
  <div
    class="size-5 rounded-[5px] border flex items-center justify-center"
    [ngClass]="checked ? 'bg-primary border-primary text-white' : 'bg-transparent border-tertiary'"
  >
    <i *ngIf="checked" class="bi bi-check2 text-[12px]"></i>
  </div>

  <span *ngIf="label" class="text-sm font-medium">{{ label }}</span>
</button>
