import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Property } from '@arkiq-portals/sdk';

@Component({
  selector: 'app-property-details-tabs',
  templateUrl: 'property-details-tabs.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class PropertyDetailsTabsComponent {
  @Input() property!: Property;

  public tabs = [
    // { text: 'Summary', route: 'summary' },
    { text: 'Users', route: 'users' },
    { text: 'Property Layout', route: 'layout' },
  ];

  constructor(private router: Router) {}

  public get currentRoute() {
    const route = this.router.url;
    return route.split('/').at(-1) ?? '';
  }
}
