<div *ngIf="isLoading" class="w-full h-full flex items-center justify-center">
  <arkiq-spinner />
</div>

<div
  *ngIf="!isLoading"
  class="w-full gap-[26px] p-6 flex flex-col xl:grid xl:grid-cols-3"
>
  <div
    class="min-h-[280px] bg-white shadow-lg flex flex-col rounded-[18px] xl:col-span-1 max-h-[700px] overflow-hidden"
  >
    <header class="border-b border-b-[#E0E0E0] flex items-center justify-between p-4">
      <h4 class="font-bold text-[14px]">Locations</h4>

      <arkiq-button
        (click)="handleAddLocation()"
        [rounded]="false"
        size="md"
        text="Add location"
      />
    </header>

    <main class="w-full h-full max-h-[500px] overflow-y-auto custom-scrollbar">
      <app-property-details-layout-tree
        (nodePathChange)="onNodePathChanged($event)"
        (reload)="getProperty(propertyId, true)"
        [property]="property"
      />
    </main>
  </div>

  <app-property-details-node-details
    (navigateToSubLocation)="onNavigateToSubLocation($event)"
    (reload)="getProperty(propertyId, true)"
    [property]="property"
    [selectedNodePath]="selectedNodePath"
    (updateTree)="onUpdateTree($event)"
    class="col-span-2"
  />
</div>
