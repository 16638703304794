import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent, TabsComponent } from '@arkiq-portals/ui';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent],
})
export class PropertiesComponent {}
