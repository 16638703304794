import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Organization } from '@arkiq-portals/sdk';

@Component({
  selector: 'app-organization-details-tabs',
  templateUrl: 'organization-details-tabs.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class OrganizationDetailsTabsComponent {
  @Input() organization!: Organization;

  public tabs = [
    { text: 'Summary', route: 'summary' },
    { text: 'Properties', route: 'properties' },
    // { text: 'Devices', route: 'devices' },
  ];

  constructor(private router: Router) {}

  public get currentRoute() {
    const route = this.router.url;
    return route.split('/').at(-1) ?? '';
  }
}
