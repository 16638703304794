<div class="bg-white rounded-5 w-full max-w-[486px] h-full">
  <header class="relative px-6 pt-6">
    <arkiq-icon-button
      tooltipText="Close dialog"
      icon="bi-x-lg"
      (click)="cancelDeletion()"
      class="absolute top-2 right-2 text-lg"
    />
    <h3 class="text-[#F00] font-bold text-2xl">Warning!</h3>
    <p class="text-[15px]">You are about to delete the following location(s):</p>
  </header>
  <main class="px-6 my-3">
    <div class="w-full rounded-xl border border-black/15 p-4 custom-scrollbar overflow-y-auto h-64">
      <div class="flex flex-col gap-2"
        [ngClass]="{'gap-2': node.children.length > 0}"
      >
        <button class="flex items-center gap-2 group w-full" (click)="changeCollapse()"
        >
          <span class="truncate">{{nodeName(node)}}</span>
          @if(node.children.length > 0 ) {
            <i class="bi text-[#BBB] flex item-center"
              [ngClass]="[node.isCollapsed ? 'bi-chevron-right' : 'bi-chevron-down']"
            ></i>
          }
          @if(node.isCollapsed && node.children.length > 0) {
            <span class="text-primary bg-primary-accent text-ms font-semibold px-2 py-1 truncate rounded group-hover:underline">{{nodeName(node.children[0])}}</span>
          }
        </button>
        <div class="flex flex-col">
            @if (!node.isCollapsed && node){
              @for (node of node.children; track $index; let index = $index) {
                <ng-container
                *ngTemplateOutlet="
                nodeButton;
                context: { node: node, deep: 1}
                "
                ></ng-container>
              }}
        </div>
      </div>

    </div>
    <p class="text-sm mt-2">This action will unassign <span class="font-bold">{{deviceCounter}}</span> device(s), and will delete all contained fixtures and sub-locations.</p>
  </main>
  <!-- <div class="p-6 border-t col-span-2 border-t-black/15">
    <p class="font-semibold text-sm">An OTP code has been s ent to [Number]</p>
    <label
      class="w-full focus-within border border-black/15 p-4 text-sm flex items-center rounded-lg mt-3 focus-within:ring-0 focus-within:outline-none focus-within:border-primary"
    >
      <input
        class="w-full outline-none text-base bg-transparent"
        placeholder="OTP Code"
        type="text"
      />
      <button class="text-primary underline text-sm ml-auto" id="otpcode">
        Resend
      </button>
    </label>
  </div> -->
  <footer class="flex justify-between items-center border-t border-t-black/10 px-6 py-5">
    <button
      class="bg-[#EEE] text-[#666] font-semibold rounded-[7px] h-[42px] leading-5 px-7 py-3 hover:shadow-md transition-shadow"
      (click)="cancelDeletion()"
    >
      Cancel
    </button>

    <button
      class="bg-[#F00] text-white font-semibold rounded-[7px] h-[42px] leading-5 px-7 py-3 hover:shadow-md transition-shadow"
      (click)="confirmDeletion()"
    >
      Confirm
    </button>
  </footer>
</div>


<ng-template #nodeButton let-node="node" let-deep="deep">
  <div class="flex"
  >
    @for( n of generateArray(deep); track $index; let index = $index) {
      <div
        class="border-l border-dashed border-black/15 w-6"
        [ngClass]="{'w-4': index === deep - 1}"
      ></div>
    }

    <span
      class="flex-1 flex items-center p-2 hover:bg-gray-200 rounded-[9px] transition-all truncate"
      >
      {{ node.l_name }}
    </span>
  </div>

  @if (node.children.length > 0 && !node.isCollapsed) {
    @for (childNode of node.children; track $index; let index = $index) {
      <ng-container
        *ngTemplateOutlet="
          nodeButton;
          context: {
            node: childNode,
            deep: deep + 1,
          }
        "
      ></ng-container>
    }
  }
</ng-template>
