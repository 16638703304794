import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DeviceGatewayView, DeviceOverview } from '@arkiq-portals/sdk';
import { TextCardIconComponent } from '@arkiq-portals/ui';

import {
    DialogAlertComponent,
    DialogAlertParams,
    DialogAlertVariant,
    SpinnerComponent,
    TableColumn,
    TableColumnType,
    TableComponent,
} from '@arkiq-portals/ui';
import { MINUTES_DIFFERENCE_UTC_TO_EST } from 'apps/admin/src/utils';

import { formatDate, subMinutes } from 'date-fns';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    SpinnerComponent,
    TableComponent,
    TextCardIconComponent,
  ],
  selector: 'app-device-gateway-grid',
  templateUrl: './device-gateway-grid.component.html',
})
export class DeviceGatewayGridComponent implements OnInit {
  @Input({ required: true }) device!: DeviceOverview;

  public isLoading = false;

  public data: DeviceGatewayView[] = [];

  public tableColumns: TableColumn<DeviceGatewayView>[] = [
    {
      type: TableColumnType.TEXT,
      label: 'Date/Time',
      valueFn: row => this.formattedDateForTimezone(row.date_time),
    },
    {
      type: TableColumnType.TEXT,
      label: 'Valve Status',
      valueFn: row => (row.status ? 'Online' : 'Offline'),
      valueClassesFn: row =>
        row.status ? 'text-badge-online-text' : 'text-[#F00]',
    },
    {
      type: TableColumnType.TEXT,
      label: 'Connection Type',
      valueFn: row => '80 psi',
    },
    {
      type: TableColumnType.TEXT,
      label: 'Power Source',
      valueFn: row => row.power_source ?? 'Outlet',
      valueClassesFn: row => 'text-[#888]',
    },
  ];

  constructor(private matDialog: MatDialog) {}

  ngOnInit(): void {
    this.loadData();
  }

  handleUpgradeGatewayTextClick() {
    console.log('handleUpgradeGatewayTextClick');
  }

  private formattedDateForTimezone(date) {
    const estDate = subMinutes(new Date(date), MINUTES_DIFFERENCE_UTC_TO_EST);
    const formattedDate = formatDate(estDate, 'MMM dd, yyyy HH:mm aa');

    return formattedDate;
  }

  public async loadData(): Promise<void> {
    try {
      this.isLoading = true;
      // TODO add fetch data
    } catch (error: any) {
      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          title: 'Unable to fetch device data',
          text: error.message || 'Unexpected error',
          variant: DialogAlertVariant.ERROR,
        } as DialogAlertParams,
      });
    } finally {
      this.isLoading = false;
    }
  }
}
