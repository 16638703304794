<div class="h-full rounded-[15px] shadow-md bg-white">
  <arkiq-table
    class="h-full"
    [columns]="tableColumns"
    [data]="history"
    [isLoading]="isLoading"
    [totalPages]="totalPages"
    [totalItems]="totalItems"
    (rowsLimitChanged)="onTableRowsLimitChanged($event)"
    (pageChanged)="onTablePageChanged($event)"
    (sortOrderChanged)="onSortOrderChange($event)"
    [hideTitle]="true"
    [paginationText]="isFilterApplied ? 'Filtered Results' : 'Total Results'"
    maxHeightPageClasses="max-h-[calc(100dvh-660px)] md:max-h-[calc(100dvh-465px)]"
  />
</div>
