<a
  class="flex flex-col h-full w-full border border-[#E4E7EC] rounded-xl transition-shadow shadow-sm hover:shadow-md"
  [routerLink]="['/', 'devices', alert.device.id]"
>
  <header class="p-5 gap-5 flex flex-wrap flex-col items-center justify-between md:flex-row md:flex-nowrap ">
    <div class="flex items-center gap-3">
      <img
        src="https://ui-avatars.com/api/?background=random&name={{
          formattedPropertyName
        }}"
        alt="Propety photo"
        class="size-[44px] rounded-full object-cover"
      />
      <div class="flex flex-col gap-1 mr-auto">
        <h4
          class="text-base font-semibold"
          [routerLink]="alert.property?.id ? '/properties/' + alert.property.id : '/properties'"
        >
          {{ alert.property?.name }}
        </h4>
        <span
          class="text-sm text-[#475467]"
          [routerLink]="'/organizations/' + alert.organization.id"
        >
          {{ alert.organization?.name }}
        </span>
      </div>
    </div>
    <div class="flex items-center gap-2 justify-center flex-1 md:justify-normal md:flex-none  flex-wrap">

      @switch (alertTypeLowerCase) { @case("tampering") {
      <i class="bi bi-hand-index-thumb text-[#121212] text-xl"></i>
      } @case("leak") {
      <i class="bi bi-droplet text-primary text-xl"></i>
      } @case("high temperature") {
      <i class="bi bi-thermometer-half text-[#FF9110] text-xl"></i>
      } @case("high humidity") {
      <i class="bi bi-moisture text-state-completed text-xl"></i>
      } @case("flow") {
        <i class="bi bi-droplet-fill text-blue-700 text-xl"></i>
      } @case("power outage") {
        <i class="bi bi-lightning-fill text-state-cancel text-xl"></i>
      } @default {
        <i class="bi bi-display text-black text-xl"></i>
      } } @if(formattedAlertType) {
      <span
        class="rounded-[999px] py-[5px] px-[10px] text-[10px] text-center"
        [ngClass]="[alert.cleared ? 'bg-[#D9ECD9] text-[#008000]' : 'text-red-600 bg-red-200']"
      >
        {{ formattedAlertType }}
      </span>
      }
    </div>
  </header>

  <div class="px-5 pb-5 flex flex-col flex-1 justify-between gap-5">
    <p class="flex items-center gap-2">
      <i class="bi bi-geo-alt text-[#6F6F6F] text-xl"></i>
      <span class="text-[#475467] text-sm font-medium">
        {{ formattedLocation }}
      </span>
    </p>
    <div class="flex justify-between">
      <p class="flex items-center gap-2">
        <i class="bi bi-wrench text-xl text-[#6F6F6F]"></i>
        <span class="text-[#475467] text-sm font-medium">
          {{ alert.fixture ? alert.fixture.type : "N/A" }}
        </span>
      </p>
      <p class="flex flex-col">
        <span class="uppercase text-[9px] text-[#101828] font-semibold">
          INITIATED
        </span>
        <span class="text-[#797D82] text-[11px]">
          {{ initiatedTime }}
        </span>
      </p>
      <!-- @if (alert.cleared) { -->
        <p class="flex flex-col">
          <span class="uppercase text-[9px] text-[#101828] font-semibold">
            {{ alert.cleared ? 'CLEARED BY' : 'LAST UPDATED' }}
          </span>
          <span class="text-[#797D82] text-[11px]">
            {{ alert.cleared ? clearedTime : '-' }}
          </span>
        </p>
      <!-- } -->
    </div>
  </div>
  <div
    class="border-t-[#E4E7EC] border-t-[1px] py-1 text-[#0192CE] text-center w-full transition-colors hover:bg-black/5"
  >
    Device Details
  </div>
</a>
