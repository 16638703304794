<select
  class="bg-[#0192CE29] py-3 px-5 rounded-full text-[#0192CE] font-medium flex items-center gap-2 cursor-pointer hover:opacity-75 transition-opacity appearance-none outline-none"
>
  <option
    *ngFor="let language of languages"
    [value]="languages"
  >
    {{ languages }}
  </option>
</select>
