<div
  class="py-6 px-[38px] w-full h-full max-h-full flex flex-col gap-8 overflow-y-auto"
>
  <arkiq-header title="DASHBOARD" />

  <main class="flex flex-col flex-1">
    @if(isLoading) {
      <div class="flex flex-1 bg-white justify-center items-center">
        <arkiq-spinner />
      </div>
    } @else {
      <div class="grid grid-cols-1 xl:grid-cols-[auto_380px] gap-3">
        <div
          class="grid gap-3 grid-cols-[repeat(auto-fill,minmax(300px,1fr))] auto-rows-max"
        >
          <home-card
            title="Total Devices"
            [value]="deviceInfo.value"
            [monthValue]="deviceInfo.monthValue"
            routeTo="devices"
            icon="bi-wifi"
          />
          <home-card
            title="Total Organizations"
            [value]="organizationInfo.value"
            [monthValue]="organizationInfo.monthValue"
            routeTo="organizations"
            icon="bi-building-fill"
          />
          <home-card
            title="Total Properties"
            [value]="propertyInfo.value"
            [monthValue]="propertyInfo.monthValue"
            routeTo="properties"
            icon="bi-buildings-fill"
          />
          <home-card
            title="Total Alerts"
            [value]="alertInfo.value"
            [monthValue]="alertInfo.monthValue"
            routeTo="alerts"
            icon="bi-exclamation-triangle-fill"
          />
        </div>
        <div
          class="grid gap-3 grid-rows-[repeat(auto-fill,minmax(200,300))] grid-cols-[repeat(auto-fill,minmax(300px,1fr))] md:auto-rows-max"
        >
          <app-last-alerts [alerts]="alerts" />
          <app-rate-alerts [alerts]="rateAlerts" />
        </div>
      </div>
    }
  </main>
</div>
