import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertCardComponent } from '../alert-card/alert-card.component';

export type LastAlert = {
  date: Date;
  address: string;
  time: string;
  fixture: string;
  alertType: string;
  organization_id: string;
  property_id: string;
};
@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, AlertCardComponent],
  selector: 'app-last-alerts',
  templateUrl: 'last-alerts.component.html',
})
export class LastAlertsComponent {
  @Input({ required: true }) alerts!: LastAlert[];
  constructor() {}
}
