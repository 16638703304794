import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'arkiq-checkbox',
  templateUrl: 'checkbox.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class CheckboxComponent implements OnInit {
  @Input() label = '';
  @Input() initialValue = false;
  @Output() changeValue = new EventEmitter();

  public checked = false;

  constructor() { }

  public ngOnInit(): void {
    this.checked = this.initialValue;
  }

  public handleChangeValue() {
    this.checked = !this.checked;
    this.changeValue.emit(this.checked);
  }
}
