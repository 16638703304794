import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { DeviceOverview, FixturesService } from '@arkiq-portals/sdk';

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import {
    ButtonComponent,
    DialogAlertComponent,
    DialogAlertParams,
    DialogAlertVariant,
    IconButtonComponent,
} from '@arkiq-portals/ui';

export type UninstallDevicesData = {
  selectedDevices: DeviceOverview[];
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonComponent,
    IconButtonComponent,
  ],
  selector: 'app-uninstall-device-modal',
  templateUrl: 'uninstall-device-modal.component.html',
  styleUrl: '../scrollbar-devices.scss',
})
export class UninstallDeviceModalComponent {
  public isSaving = false;
  public devices!: Array<DeviceOverview >
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UninstallDevicesData,
    private dialogRef: MatDialogRef<UninstallDeviceModalComponent>,
    private fixturesService: FixturesService,
    private matDialog: MatDialog,
  ) {
    if (!this.data?.selectedDevices.length) {
      this.dialogRef.close();
    }
    this.devices = this.data.selectedDevices
  }

  public handleCloseModal() {
    this.dialogRef.close();
  }

  public async confirmUninstall() {
    try {
      this.isSaving = true;

      const fixtures = await this.fixturesService.listByDeviceId(this.devices.map(device =>   device.id));
      console.log(fixtures)
      await this.fixturesService.removeDeviceIdFromMany(
        fixtures.map(fixture => fixture.id),
      );
      this.isSaving = false;

      this.dialogRef.close({ reload: true });
    } catch (error) {
      this.isSaving = false;
      console.error(error);

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to uninstall devices!',
          text: `(3) devices could not be uninstalled.`,
        } as DialogAlertParams,
      });
    }
  }

  public cancelUninstall() {
    this.handleCloseModal();
  }
}
