@if (isLoading) {
<div *ngIf="isLoading" class="w-full h-full flex items-center justify-center">
  <arkiq-spinner />
</div>
} @else {
<div class="w-full h-full bg-white rounded-xl flex flex-col">
  <header class="flex items-center gap-2 py-5 px-6">
    <arkiq-icon-button icon="bi-chevron-left" (click)="handleGoBack()" />

    <h2 class="m-0 font-bold text-base">Add New Device</h2>
  </header>

  <section
    class="w-full border-y border-y-[#E0E0E0] px-6 flex flex-1 flex-col sm:flex-row"
  >
    <div class="flex flex-col flex-1 py-[25px]">
      <!-- sm:pr-6 sm:border-r sm:border-[#E0E0E0] -->
      <h3 class="font-bold mb-4 text-xl">Device Information</h3>
      <div class="flex flex-col gap-5">
        <arkiq-select
          class="flex-1"
          label="Device Type"
          [required]="true"
          size="md"
          place
          [rounded]="false"
          [control]="form.controls.type"
          [options]="deviceTypeOptions"
        />
        <div class="flex flex-col gap-2">
          <p class="flex text-[15px] items-center font-semibold">
            ID (Serial #) <span class="text-[#F00]">*</span>
            <i
              class="bi bi-qr-code flex items-center justify-center border border-black/15 rounded size-8 text-xl text-[#666] ml-auto"
            ></i>
          </p>
          <arkiq-input
            type="text"
            [required]="true"
            label=""
            placeholder="ID (Serial #)"
            [control]="form.controls.serial"
            [rounded]="false"
            size="md"
          />
        </div>
        <div class="flex flex-col gap-2">
          <p>Received Date</p>
          <input
            class="focus-within:border-primary border border-[#EEEEEE] h-[34px] text-sm px-3 py-2 rounded-[5px] bg-transparent"
            type="date"
            [formControl]="form.controls.date"
          />
        </div>
      </div>
    </div>
    <!-- <div class="flex flex-col flex-1 py-[25px] sm:pl-6">
      <h3 class="font-bold mb-4 text-xl">Keys</h3>
      <div class="flex flex-col gap-5">
        <div class="flex flex-col gap-2">
          <p class="flex text-[15px] items-center font-semibold">
            DevEUI <span class="text-[#F00]">*</span>
            <i
              class="bi bi-qr-code flex items-center justify-center border border-black/15 rounded size-8 text-xl text-[#666] ml-auto"
            ></i>
          </p>
          <arkiq-input
            type="text"
            [required]="true"
            label=""
            placeholder="DevEUI"
            [control]="form.controls.devEUI"
            [rounded]="false"
            size="md"
          />
        </div>
        <div class="flex flex-col gap-2">
          <p class="flex text-[15px] items-center font-semibold">
            JoinEUI <span class="text-[#F00]">*</span>
            <i
              class="bi bi-qr-code flex items-center justify-center border border-black/15 rounded size-8 text-xl text-[#666] ml-auto"
            ></i>
          </p>
          <arkiq-input
            type="text"
            [required]="true"
            label=""
            placeholder="JoinEUI"
            [control]="form.controls.joinEUI"
            [rounded]="false"
            size="md"
          />
        </div>
        <div class="flex flex-col gap-2">
          <p class="flex text-[15px] items-center font-semibold">
            AppKEY <span class="text-[#F00]">*</span>
            <i
              class="bi bi-qr-code flex items-center justify-center border border-black/15 rounded size-8 text-xl text-[#666] ml-auto"
            ></i>
          </p>
          <arkiq-input
            type="text"
            [required]="true"
            label=""
            placeholder="AppKEY"
            [control]="form.controls.appKey"
            [rounded]="false"
            size="md"
          />
        </div>
      </div>
    </div> -->
  </section>
  <footer class="flex items-center justify-end p-4 gap-2">
    <arkiq-button
      text="Cancel"
      size="md"
      variant="tertiary"
      [rounded]="false"
      (click)="handleGoBack()"
    />
    <arkiq-button
      text="Create"
      size="md"
      variant="primary"
      [rounded]="false"
      [disabled]="!form.valid"
      (click)="createDevice()"
    />
  </footer>
</div>
}
