<div *ngIf="isLoading" class="w-full flex flex-col flex-1 items-center justify-center">
  <arkiq-spinner />
</div>

<div *ngIf="!isLoading" class="w-full flex flex-col flex-1 bg-white rounded-xl">
  <header class="flex items-center gap-2 py-5 px-6">
    <arkiq-icon-button icon="bi-chevron-left" (click)="handleGoBack()" />

    <h2 class="m-0 font-bold text-base mr-auto">Organization Details</h2>

    <arkiq-button
      variant="primary"
      [rounded]="false"
      text="Edit"
      size="md"
      (click)="handleEditOrganization()"
    ></arkiq-button>
  </header>

  <section
    class="w-full border-y border-y-[#E0E0E0] py-[30px] px-6 flex flex-col lg:flex-row gap-4 items-center justify-between"
  >
    <div class="w-full flex flex-1 items-center gap-5 max-w-[500px]">
      <div
        class="min-w-[91px] min-h-[91px] max-w-[91px] max-h-[91px] rounded-[20px] border border-black/10 flex items-center justify-center"
      >
        <i class="bi bi-briefcase-fill text-[31px] text-[#CCCCCC]"></i>
      </div>

      <div class="flex flex-col gap-3 flex-1 truncate">
        <span class="font-bold text-[22px] truncate">{{ organization.name }}</span>
        <span class="text-sm text-[#666666] truncate">
          {{ organization.description }}
        </span>
      </div>
    </div>

    <button
      *ngIf="organizationMaster"
      class="truncate max-w-[33%] border border-[#E0E0E0] rounded-lg p-3 flex items-center gap-4 hover:shadow-sm transition-shadow"
      (click)="handleOrganizationMasterClick()"
    >
      <div class="flex flex-1 truncate items-center gap-3">
        <img
          class="rounded-full size-8 object-cover"
          [src]="organizationMaster.photo"
          [alt]="
            organizationMaster.firstName + ' ' + organizationMaster.lastName
          "
        />

        <span class="block font-semibold flex-1 truncate">
          {{ organizationMaster.firstName + ' ' + organizationMaster.lastName }}
        </span>
      </div>

      <div class="border-[1.5px] border-black/15 rounded-[3px] py-2 px-3">
        <span class="text-sm font-semibold text-[#666666]">
          Organization Master
        </span>
      </div>
    </button>
  </section>

  <app-organization-details-tabs [organization]="organization" />

  <main class="w-full flex flex-1">
    <router-outlet></router-outlet>
  </main>
</div>
