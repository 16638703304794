import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Property,
  PropertyType,
  PropertyTypeLabel,
  User,
} from '@arkiq-portals/sdk';
import {
  ButtonComponent,
  InputComponent,
  SelectComponent,
  SelectOption,
  SelectOrganizationMasterComponent,
} from '@arkiq-portals/ui';
import { getUploadedImageURL } from 'apps/admin/src/utils';
import { v7 as idGenerator } from 'uuid';

export type SavePropertyEvent = {
  id: string;
  property: Partial<Property>;
  master: Partial<User>;
  photo?: File | string;
};

@Component({
  selector: 'app-add-organization-property-form',
  templateUrl: 'add-organization-property-form.component.html',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    SelectComponent,
    ButtonComponent,
    SelectOrganizationMasterComponent,
  ],
})
export class AddOrganizationPropertyFormComponent implements OnInit {
  @Input() data?: SavePropertyEvent;
  @Output() saveProperty = new EventEmitter<SavePropertyEvent>();
  @Output() discardAction = new EventEmitter();

  public isMastersValid = true;


  public form = new FormGroup({
    name: new FormControl<string | null>('', [Validators.required]),
    type: new FormControl<string | null>('', [Validators.required]),
    photo: new FormControl<File | null | string >(null, []),
    photoUrl: new FormControl<string | null>('', []),
    address_street: new FormControl<string | null>('', [Validators.required]),
    address_city: new FormControl<string | null>('', [Validators.required]),
    address_state: new FormControl<string | null>('', [Validators.required]),
    address_zip_code: new FormControl<string | null>('', [Validators.required]),
    master: new FormControl<User | Partial<User> | null>(this.data?.master ?? null ),
  });

  public get propertyTypeOptions(): SelectOption[] {
    return Object.values(PropertyType).map(type => ({
      value: type,
      label: PropertyTypeLabel[type],
    }));
  }

  public ngOnInit() {
    if (this.data) {
      this.form.patchValue({
        name: this.data.property.name,
        type: this.data.property.type,
        address_street: this.data.property.address_street,
        address_city: this.data.property.address_city,
        address_state: this.data.property.address_state,
        address_zip_code: this.data.property.address_zip_code,
        photoUrl: this.data.property.photo,
        photo: this.data.photo,
        master: this.data.master,
      });
    } else {
      this.form.patchValue({
        name: "",
        type: "",
        address_street: "",
        address_city: "",
        address_state: "",
        address_zip_code: "",
        photoUrl: "",
        photo: null,
        master: null,
      })
    }
  }

  public handleSave() {
    if (this.form.invalid) {
      return;
    }
    this.saveProperty.emit({
      property: {
        name: this.form.value.name ?? undefined,
        type: (this.form.value.type as PropertyType) ?? undefined,
        address_street: this.form.value.address_street ?? undefined,
        address_city: this.form.value.address_city ?? undefined,
        address_state: this.form.value.address_state ?? undefined,
        address_zip_code: this.form.value.address_zip_code ?? undefined,
        photo: this.form.value.photoUrl ?? undefined,
        id: this.data?.property.id ?? undefined
      },
      photo: this.form.value.photo || this.data?.photo,
      master: this.form.value.master as Partial<User> || this.data?.master,
      id: this.data?.property.id
        ? String(this.data?.property.id)
        : this.data?.id ?? idGenerator()
    });
  }

  public async handleUploadPicture(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      const url = await getUploadedImageURL(file);

      if (url) {
        this.form.controls.photoUrl.setValue(url);
      }

      this.form.controls.photo.setValue(file);
    }
  }

  public handleDiscard() {
    this.discardAction.emit();
  }

  public isMastersTouchedAndValid(event)  {
    this.isMastersValid = event
  }
}
