import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ButtonComponent } from '../../components';

export enum DialogAlertVariant {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export type DialogAlertParams = {
  variant: DialogAlertVariant;
  title: string;
  text: string;
};

@Component({
  selector: 'arkiq-dialog-alert',
  templateUrl: 'dialog-alert.component.html',
  standalone: true,
  imports: [CommonModule, MatDialogModule, ButtonComponent],
})
export class DialogAlertComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogAlertParams,
    public dialogRef: MatDialogRef<DialogAlertComponent>,
  ) {}

  public get titleColor(): string {
    switch (this.data.variant) {
      case DialogAlertVariant.INFO:
        return 'text-state-info';
      case DialogAlertVariant.SUCCESS:
        return 'text-state-completed';
      case DialogAlertVariant.WARNING:
        return 'text-state-warning';
      case DialogAlertVariant.ERROR:
        return 'text-state-cancel';
      default:
        return 'text-state-info';
    }
  }
}
