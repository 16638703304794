<label class="w-full focus-within:text-black text-tertiary flex flex-col gap-2">
  <span
    *ngIf="label"
    class="font-medium text-black"
    [ngClass]="[
      required ? 'required' : '',
      size === 'md' ? 'text-[15px]' : 'text-lg'
    ]"
  >
    {{ label }}
  </span>

  <div
    [ngClass]="[
      classes.base,
      classes[variant],
      invalid ? classes.invalid : '',
      sizes[size],
      iconPosition === 'right' ? 'flex-row' : 'flex-row-reverse',
      rounded ? 'rounded-full' : 'rounded-[5px]',
      control.invalid && (control.dirty || control.touched)
        ? 'border-state-cancel'
        : '',
      disabled ? 'bg-black/5' : 'bg-transparent'
    ]"
  >
    <select
      type="text"
      [formControl]="control"
      class="w-full outline-none text-sm bg-transparent"
      [attr.disabled]="disabled"
    >
      <option value="" disabled selected>Select</option>
      <option *ngFor="let option of options" [value]="option.value">
        {{ option.label }}
      </option>
    </select>

    <i
      *ngIf="icon"
      class="bi text-sm"
      [ngClass]="[icon, variant === 'secondary' ? 'text-[#717579]' : '']"
    ></i>
  </div>
</label>
