<div
  class="hidden md:flex w-[200px] lg:w-[277px] h-full pl-6 lg:pl-9 pr-8 lg:pr-11 py-6 flex-col gap-8 bg-white"
>
  <header
    class="w-full border-b-[0.5px] border-b-[#434343] flex items-center justify-center pb-4"
  >
    <img
      src="assets/ark-iq-logo.png"
      alt="arkIQ Logo"
      class="size-[64px] object-contain"
    />
  </header>

  <main class="w-full flex-1 flex flex-col gap-[14px]">
    <h2 class="m-0 text-sm text-[#BABABA] uppercase">Manage</h2>

    <ul class="w-full flex flex-col gap-3">
      <li
        *ngFor="let item of items"
        (click)="tabControl.setValue(item)"
        class="flex items-center gap-[14px] cursor-pointer p-4 rounded-lg transition-colors"
        [ngClass]="
          item.route === currentRoute
            ? 'bg-[#0192CE29] text-primary hover:bg-primary/30'
            : 'text-[#727272] hover:bg-black/5'
        "
      >
        <i class="bi text-[16px]" [ngClass]="item.icon"></i>
        <span class="font-medium text-sm">{{ item.text }}</span>
      </li>
    </ul>
  </main>

  <footer>
    <button
      class="flex w-full items-center gap-[14px] cursor-pointer p-4 rounded-lg transition-colors text-[#727272] hover:bg-black/5"
      (click)="handleSignOut()"
    >
      <i class="bi bi-power text-[16px]"></i>
      <span class="font-medium text-sm"> Logout </span>
    </button>
  </footer>
</div>

<div
  class="flex md:hidden items-center justify-center px-20 w-full h-[50px] bg-white shadow-md"
>
  <select
    class="w-full outline-none p-2 px-6 rounded-full bg-[#EBEBEB]"
    [formControl]="tabControl"
  >
    <option *ngFor="let item of items" [ngValue]="item">
      {{ item.text }}
    </option>
  </select>
</div>
