<div class="flex flex-wrap p-6 gap-6">
  <div
    class="flex flex-col flex-1 border border-[#E0E0E0] rounded-[12px] mt-4 max-h-[400px] min-w-[375px] overflow-hidden"
  >
    <header class="flex items-center px-4 py-3 border-b border-b-[#E0E0E0]">
      <h6 class="text-[#121212] font-bold text-[16px]">Managers</h6>
      <span
        *ngIf="managers"
        class="border-2 border-[#0000001A] text-[#AAA] text-[14px] font-bold rounded-lg px-2 py-1 text-center ml-2 mr-5"
        >{{ managers.length }}</span
      >

      <arkiq-input
        class="flex-1 max-w-[40%] ml-auto"
        icon="bi-search"
        iconPosition="right"
        placeholder="Search..."
        [rounded]="false"
        size="md"
        [control]="form.controls.managers"
      />

      <span class="w-[1px] h-full bg-[#DDD] mx-5"></span>

      <arkiq-button
        *hasRole="[USER_ROLES.ORGANIZATION_MASTER]"
        text="Add User"
        size="md"
        [rounded]="false"
        (click)="handleAddUser(roles.PROPERTY_MASTER)"
      />
    </header>
    <div class="overflow-y-auto custom-scrollbar">
      <table
        class="w-full max-w-full table-fixed max-h-[400px]"
        *ngIf="!isManagersLoading"
      >
        <thead class="bg-white border-b border-b-[#E0E0E0]">
          <th class="text-sm font-semibold text-[#252525] p-4 text-left">
            Name
          </th>
          <th class="text-sm font-semibold text-[#252525] p-4 text-center">
            Role
          </th>
          <th
            *hasRole="[USER_ROLES.ORGANIZATION_MASTER]"
            class="text-sm font-semibold text-[#252525] p-4 text-center"
          >
            Action
          </th>
        </thead>

        <tbody *ngIf="managers">
          <ng-container *ngFor="let manager of managers; let rowIndex = index">
            <tr
              class="bg-white hover:brightness-95 transition-all cursor-default [&:not(:first-child)]:border-t [&:not(:first-child)]:border-t-[#E0E0E0]"
              *ngIf="manager.user"
            >
              @for (column of columns; track $index) {
                <td
                  [ngClass]="[
                    'p-4 text-xs font-medium w-full',
                    column.valueClasses ?? ''
                  ]"
                  [ngSwitch]="column.type"
                  *hasRole="column.allowedRoles || ALL_USER_ROLES"
                >
                  <ng-container *ngSwitchDefault>
                    <ng-container
                      *ngTemplateOutlet="
                        defaultCellTemplate;
                        context: {
                          column: column,
                          item: manager,
                        }
                      "
                    ></ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="'PICTURE_AND_TEXT'">
                    <ng-container
                      *ngTemplateOutlet="
                        pictureAndTextCellTemplate;
                        context: column?.pictureAndTextValueFn(manager.user)
                      "
                    ></ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="'ACTIONS'">
                    <ng-container
                      *ngTemplateOutlet="
                        actionsCellTemplate;
                        context: {
                          buttons: column.actionButtons,
                          item: manager.user
                        }
                      "
                    ></ng-container>
                  </ng-container>
                </td>
              }
            </tr>
          </ng-container>
        </tbody>
      </table>
      <arkiq-spinner
        *ngIf="isManagersLoading"
        class="w-full flex justify-center items-center p-8"
      />
      <h6
        class="text-[#888] text-bold text-center p-4"
        *ngIf="!managers || managers.length === 0"
      >
        No Managers
      </h6>
    </div>
  </div>

  <div
    class="flex flex-col flex-1 border border-[#E0E0E0] rounded-[12px] mt-4 max-h-[400px] min-w-[375px] overflow-hidden"
  >
    <header class="flex items-center px-4 py-3 border-b border-b-[#E0E0E0]">
      <h6 class="text-[#121212] font-bold text-[16px]">Residents</h6>
      <span
        *ngIf="residents"
        class="border-2 border-[#0000001A] text-[#AAA] text-[14px] font-bold rounded-lg px-2 py-1 text-center ml-2 mr-5"
        >{{ residents.length }}</span
      >

      <arkiq-input
        class="flex-1 max-w-[40%] ml-auto"
        icon="bi-search"
        iconPosition="right"
        placeholder="Search..."
        [rounded]="false"
        size="md"
        [control]="form.controls.residents"
      />

      <span class="w-[1px] h-full bg-[#DDD] mx-5"></span>

      <arkiq-button
        *hasRole="[USER_ROLES.ORGANIZATION_MASTER]"
        text="Add User"
        size="md"
        [rounded]="false"
        (click)="handleAddUser(roles.CUSTOMER)"
      />
    </header>
    <div class="overflow-y-auto custom-scrollbar">
      <table
        class="w-full max-w-full table-fixed max-h-[400px]"
        *ngIf="!isResidentsLoading"
      >
        <thead class="bg-white border-b border-b-[#E0E0E0]">
          <th class="text-sm font-semibold text-[#252525] p-4 text-left">
            Name
          </th>
          <th class="text-sm font-semibold text-[#252525] p-4 text-center">
            Role
          </th>
          <th
            *hasRole="[USER_ROLES.ORGANIZATION_MASTER]"
            class="text-sm font-semibold text-[#252525] p-4 text-center"
          >
            Action
          </th>
        </thead>
        <tbody *ngIf="residents">
          <ng-container
            *ngFor="let resident of residents; let rowIndex = index"
          >
            <tr
              class="bg-white hover:brightness-95 transition-all cursor-default [&:not(:first-child)]:border-t [&:not(:first-child)]:border-t-[#E0E0E0]"
              *ngIf="resident.user"
            >
              @for (column of columns; track $index) {
                <td
                  [ngClass]="[
                    'p-4 text-xs font-medium w-full',
                    column.valueClasses ?? ''
                  ]"
                  [ngSwitch]="column.type"
                  *hasRole="column.allowedRoles || ALL_USER_ROLES"
                >
                  <ng-container *ngSwitchDefault>
                    <ng-container
                      *ngTemplateOutlet="
                      defaultCellTemplate;
                      context: {
                        column: column,
                        item: resident.user,
                      }
                    "
                    ></ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="'PICTURE_AND_TEXT'">
                    <ng-container
                      *ngTemplateOutlet="
                        pictureAndTextCellTemplate;
                        context: column?.pictureAndTextValueFn(resident.user)
                      "
                    ></ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="'ACTIONS'">
                    <ng-container
                      *ngTemplateOutlet="
                        actionsCellTemplate;
                        context: {
                          buttons: column.actionButtons,
                          item: resident.user
                        }
                      "
                    ></ng-container>
                  </ng-container>
                </td>
              }
            </tr>
          </ng-container>
        </tbody>
      </table>
      <arkiq-spinner
        *ngIf="isResidentsLoading"
        class="w-full flex justify-center items-center p-8"
      />
      <h6
        class="text-[#888] text-bold text-center p-4"
        *ngIf="!residents || residents.length === 0"
      >
        No Residents
      </h6>
    </div>
  </div>
</div>

<ng-template #defaultCellTemplate let-column="column" let-item="item">
  <div class="w-full">
    <span class="text-xs font-medium">
      {{ column.valueFn(item) }}
    </span>
  </div>
</ng-template>

<ng-template
  #pictureAndTextCellTemplate
  let-text="text"
  let-picture="picture"
  let-pictureClasses="pictureClasses"
  let-textClasses="textClasses"
  let-item="item"
>
  <div class="w-full truncate flex items-center gap-2">
    <img
      [src]="picture"
      [alt]="text"
      [ngClass]="[
        'size-[28px] rounded-full object-cover',
        pictureClasses ?? ''
      ]"
    />
    <span [ngClass]="['truncate', textClasses ?? '']">{{ text }}</span>
  </div>
</ng-template>

<ng-template #actionsCellTemplate let-buttons="buttons" let-item="item">
  <div class="w-full flex items-center justify-center gap-4">
    <arkiq-icon-button
      *ngFor="let button of buttons"
      [icon]="button.icon"
      [matTooltip]="button.tooltip ?? ''"
      (click)="$event.stopPropagation(); button.handler(item)"
    />
  </div>
</ng-template>
