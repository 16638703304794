import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ButtonComponent } from '../../../../components';
import { Router, RouterModule } from '@angular/router';
import { AuthStepsComponent } from '../../components';

@Component({
  selector: 'arkiq-forgot-password-email',
  templateUrl: 'forgot-password-email.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, ButtonComponent, AuthStepsComponent],
})
export class AuthForgotPasswordEmailComponent {
  constructor(private router: Router) {}

  handleGoBack() {
    this.router.navigateByUrl('/auth/sign-in');
  }
}
