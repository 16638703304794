<button
  class="flex justify-between items-center border border-[#F0F0F0] px-4 pl-2 py-2 rounded-xl hover:bg-slate-50 transition"
  type="button"
  (click)="handleGoToAlert()"
>
  <div
    class="w-[38px] h-[40px] min-w-[38px] flex flex-col items-center justify-center text-[#D7A339] text-base font-bold rounded bg-[url('/assets/calendar-icon.svg')] bg-no-repeat relative"
  >
    <span
      class="absolute -translate-x-1/2 -translate-y-1/2 top-[42%] left-[50%] text-[12px]"
    >
      {{ day() }}
    </span>
    <span
      class="absolute -translate-x-1/2 -translate-y-1/2 -bottom-3 left-[47%] text-[12px]"
    >
      {{ month() }}
    </span>
  </div>
  <img src="/assets/pinpoint-icon.svg" class="h-[26px] mx-1" />
  <div class="flex flex-col justify-between">
    <p class="text-base font-semibold text-black text-left max-w-[200px]">{{ address }}</p>
    <p class="flex items-center">
      <i class="bi bi-stopwatch text-[#0C0507] mr-1"></i>
      <span class="text-[#8D8D8D] font-normal mr-3">{{ time }} AM</span>

      <i class="bi bi-geo-alt text-[#0C0507] mr-1"></i>
      <span class="text-[#8D8D8D] font-normal">{{ fixture }}</span>
    </p>
  </div>
  <div class="flex flex-col items-center gap-2">
    <span
      class="rounded-full bg-primary-accent text-primary py-1 px-2 text-[10px] uppercase"
      >{{ alertType }}</span
    >
    <i class="bi bi-arrow-up-right text-primary text-2xl"></i>
  </div>
</button>
