import { Location as AngularLocation, CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NavigationEnd, Router } from '@angular/router';
import {
  AlertsObject,
  AlertsService,
  DeviceType,
  Location,
  LocationsService,
  Organization,
  OrganizationsService,
  PropertiesService,
  Property,
} from '@arkiq-portals/sdk';
import {
  DialogAlertComponent,
  DialogAlertParams,
  DialogAlertVariant,
  HeaderComponent,
  SpinnerComponent,
  Tab,
  TableColumnHeaderButtonComponent,
  TableColumnHeaderFilter,
  TableColumnHeaderFilterType,
  TableColumnHeaderOptions,
  TabsComponent,
} from '@arkiq-portals/ui';
import { debounceTime, distinctUntilChanged, filter, Subscription } from 'rxjs';
import { DeviceCardComponent } from './components/device-card/device-card.component';

interface HeaderFilterButtonsType {
  icon: `bi-${string}`;
  text: string;
  alerts?: string | number;
  action: () => void;
  value: FilterSelection;
}

interface ViewButtons
  extends Omit<HeaderFilterButtonsType, 'alerts' | 'value'> {
  value: ViewOptions;
}

enum FilterSelection {
  LEAK = 'leak',
  FLOW = 'flow',
  TEMPERATURE = 'temperature',
  HUMIDITY = 'humidity',
  TAMPERING = 'tampering',
  POWER_OUTAGE = 'power_outage',
}

enum ViewOptions {
  GRID = 'grid',
  LIST = 'list',
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    TabsComponent,
    DeviceCardComponent,
    MatSlideToggleModule,
    FormsModule,
    TableColumnHeaderButtonComponent,
    ReactiveFormsModule,
    SpinnerComponent,
  ],
  selector: 'app-alerts',
  templateUrl: 'alerts.component.html',
})
export class AlertsComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public filterForm = new FormGroup({
    organization: new FormControl(),
    property: new FormControl(),
    location: new FormControl(),
    dateTime: new FormControl(),
    deviceType: new FormControl(),
    deviceId: new FormControl('')
  });
  public rowsLimitControl = new FormControl('50');
  public pageInputControl = new FormControl(1);
  public page = 1;

  public totalPages = 0;

  public typeForm = new FormControl<FilterSelection[]>([]);
  public selectedView = ViewOptions.GRID;
  public headerFilterButtons: HeaderFilterButtonsType[] = [
    {
      icon: 'bi-droplet',
      text: 'Leak',
      value: FilterSelection.LEAK,
      action: () => this.filterAction(FilterSelection.LEAK),
    },
    {
      icon: 'bi-droplet-fill',
      text: 'Flow',
      value: FilterSelection.FLOW,
      action: () => this.filterAction(FilterSelection.FLOW),
    },
    {
      icon: 'bi-thermometer-half',
      text: 'Temperature',
      value: FilterSelection.TEMPERATURE,
      action: () => this.filterAction(FilterSelection.TEMPERATURE),
    },
    {
      icon: 'bi-moisture',
      text: 'Humidity',
      value: FilterSelection.HUMIDITY,
      action: () => this.filterAction(FilterSelection.HUMIDITY),
    },
    {
      icon: 'bi-hand-index-thumb',
      text: 'Tampering',
      value: FilterSelection.TAMPERING,
      action: () => this.filterAction(FilterSelection.TAMPERING),
    },
    {
      icon: 'bi-lightning-fill',
      text: 'Power Outage',
      value: FilterSelection.POWER_OUTAGE,
      action: () => this.filterAction(FilterSelection.POWER_OUTAGE),
    },
  ];

  public organizationHeaderFilter!: TableColumnHeaderFilter;
  public propertyHeaderFilter!: TableColumnHeaderFilter;
  public locationHeaderFilter!: TableColumnHeaderFilter;
  public deviceTypeHeaderFilter: TableColumnHeaderFilter = {
    type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
    options: this.buildDeviceTypeOptions(),
    control: this.filterForm.controls.deviceType,
  };

  public isShowingAllAlerts = true;

  public tabs: Tab[] = [
    {
      text: 'All Alerts',
      route: '/alerts',
    },
    {
      text: 'Ongoing Alerts ',
      route: '/alerts/ongoing',
    },
  ];

  public headerLayoutView: Omit<ViewButtons, 'alerts'>[] = [
    {
      icon: 'bi-grid-fill',
      text: 'Grid View',
      action: () => this.viewAction(ViewOptions.GRID),
      value: ViewOptions.GRID,
    },
    {
      icon: 'bi-list',
      text: 'List View',
      action: () => this.viewAction(ViewOptions.LIST),
      value: ViewOptions.LIST,
    },
  ];

  public alerts!: AlertsObject[];
  public alertsQuantity!: number;

  public subscriptions = new Subscription();

  constructor(
    private router: Router,
    private location: AngularLocation,
    private organizationService: OrganizationsService,
    private propertyService: PropertiesService,
    private alertsService: AlertsService,
    private locationsService: LocationsService,
    private matDialog: MatDialog
  ) {

  }

  ngOnInit() {

    const state = this.location.getState();
    if (state && state['organizationId']) {
      this.filterForm.patchValue({ organization: [state['organizationId']] });
    }

    if (state && state['propertyId']) {
      this.filterForm.patchValue({ property: [state['propertyId']] });
    }

    if(state && state['deviceId']) {
      this.filterForm.patchValue({deviceId: state['deviceId']})
    }

    this.listAlerts();

    this.subscriptions.add(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => {
          this.listAlerts(true);
        }),
    );

    this.subscriptions.add(
      this.pageInputControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe(value => {
          this.pageInputChange(value);
        }),
    );

    this.subscriptions.add(
      this.rowsLimitControl.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(500))
        .subscribe(() => {
          this.listAlerts(true);
        }),
    );

    this.subscriptions.add(
      this.typeForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
        this.pageInputControl.setValue(1)
        this.listAlerts(true);
      }),
    );
    this.subscriptions.add(
      this.filterForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
        this.pageInputControl.setValue(1)
        this.listAlerts(true);
      }),
    );
  }

  public filterAction(action: FilterSelection) {
    if (!this.typeForm.value) return;
    if (this.typeForm.value.includes(action)) {
      this.typeForm.setValue(
        this.typeForm.value.filter(view => view !== action),
      );
      return;
    }

    this.typeForm.patchValue([...this.typeForm.value, action]);
  }

  public viewAction(action: ViewOptions) {
    this.selectedView = action;
  }

  public async listAlerts(loadJustAlerts = false) {
    this.isLoading = true;
    try {
      const [organizations, { properties}, locations] = await Promise.all([
        await this.organizationService.list(),
        await this.propertyService.list({ limit: 999999 }),
        await this.locationsService.list(),
      ]);

      if (!loadJustAlerts) {
        this.buildOrganizationOptions(organizations.organizations);
        this.buildPropertiesOptions(properties);
        this.buildLocationsOptions(locations);
      }

      const hasLeak = this.typeForm.value
        ?.join('')
        .includes(FilterSelection.LEAK);
      const hasFlow = this.typeForm.value
        ?.join('')
        .includes(FilterSelection.FLOW);
      const hasTemperature = this.typeForm.value
        ?.join('')
        .includes(FilterSelection.TEMPERATURE);
      const hasHumidity = this.typeForm.value
        ?.join('')
        .includes(FilterSelection.HUMIDITY);
      const hasTampering = this.typeForm.value
        ?.join('')
        .includes(FilterSelection.TAMPERING);
      const hasPowerOutage = this.typeForm.value
        ?.join('')
        .includes(FilterSelection.POWER_OUTAGE);

      const response = await this.alertsService.list({
        leak: hasLeak,
        flow: hasFlow,
        temperature: hasTemperature,
        humidity: hasHumidity,
        tampering: hasTampering,
        power_outage: hasPowerOutage,
        limit: Number(this.rowsLimitControl.value) || 10,
        page: this.page || 1,
        type: this.filterForm.value.deviceType,
        organizationId: this.filterForm.value.organization,
        propertyId: this.filterForm.value.property,
        locationId: this.filterForm.value.location,
        onGoing: this.isInOngoingTab,
        deviceId: this.filterForm.value.deviceId ?? ''
      });

      this.alerts = response.alerts;
      this.alertsQuantity = response.count;
      const totalPages = Math.ceil(
        this.alertsQuantity / Number(this.rowsLimitControl.value),
      );

      this.totalPages = totalPages;
      this.page = 1
    } catch (error) {
      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to list alerts',
          text: error,
        } as DialogAlertParams,
      });
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  public onOrganizationFilterSelection(event) {
    const selectedOrganizations = event.options;
    this.filterForm.patchValue({
      organization: selectedOrganizations,
    });
  }

  public onLocationFilterSelection(event) {
    const selectedLocations = event.options;
    this.filterForm.patchValue({
      location: selectedLocations,
    });
  }

  public onPropertyFilterSelection(event) {
    const selectedProperties = event.options;
    this.filterForm.patchValue({
      property: selectedProperties,
    });
  }

  public onDeviceTypeFilterSelection(event) {
    const selectedDeviceType = event.options;
    this.filterForm.patchValue({
      deviceType: selectedDeviceType,
    });
  }

  public clearFilters() {
    this.typeForm.setValue([]);
    this.filterForm.patchValue({
      dateTime: [],
      deviceType: [],
      location: [],
      organization: [],
      property: [],
    });
  }

  public verifyFilterSelected(action: FilterSelection) {
    if (!this.typeForm.value) return;
    return this.typeForm.value.includes(action);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public nextPage() {
    const currentPage = this.page;
    if (
      !currentPage ||
      currentPage === this.totalPages ||
      this.totalPages === 0
    )
      return;
    this.pageInputControl.patchValue(currentPage + 1);
  }

  public previousPage() {
    const currentPage = this.page;
    if (!currentPage || currentPage <= 1 || this.totalPages === 0) return;

    this.pageInputControl.setValue(currentPage - 1);
  }

  public pageInputChange(value) {
    const currentPage = this.page || 1;
    if (value === currentPage || this.totalPages === 0) return;

    if (Number(value) > this.totalPages || Number(value) < 1) {
      this.pageInputControl.patchValue(this.page);
      return;
    }
    this.page = Number(value);
    this.listAlerts(true);
  }

  private buildPropertiesOptions(properties: Property[]) {
    const options: TableColumnHeaderOptions[] = properties.map(property => ({
      label: property.name,
      value: String(property.id),
      isSelected: false,
    }));

    this.propertyHeaderFilter = {
      type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
      options,
      control: this.filterForm.controls.property,
    };
  }

  private buildLocationsOptions(locations: Location[]) {
    const options: TableColumnHeaderOptions[] = locations.map(location => ({
      label: location.name,
      value: String(location.id),
      isSelected: false,
    }));

    this.locationHeaderFilter = {
      type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
      options,
      control: this.filterForm.controls.location,
    };
  }

  private buildDeviceTypeOptions() {
    return Object.keys(DeviceType).map(type => ({
      label: DeviceType[type],
      value: DeviceType[type],
      isSelected: false,
    }));
  }

  private buildOrganizationOptions(organizations: Organization[]) {
    const options: TableColumnHeaderOptions[] = organizations.map(
      organization => ({
        label: organization.name,
        value: String(organization.id),
        isSelected: false,
      }),
    );

    this.organizationHeaderFilter = {
      type: TableColumnHeaderFilterType.MULTIPLE_SELECT,
      options: options,
      control: this.filterForm.controls.organization,
    };
  }

  public get isInOngoingTab() {
    return this.router.url.includes('ongoing');
  }
}
