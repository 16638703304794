import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { DeviceOverview, DevicesOverviewService } from '@arkiq-portals/sdk';

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import {
    ButtonComponent,
    DialogAlertComponent,
    DialogAlertParams,
    DialogAlertVariant,
    IconButtonComponent,
} from '@arkiq-portals/ui';

export type ReturnDevicesData = {
  selectedDevices: DeviceOverview[];
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonComponent,
    IconButtonComponent,
  ],
  selector: 'app-return-device-modal',
  templateUrl: 'return-device-modal.component.html',
  styleUrl: '../scrollbar-devices.scss',
})
export class ReturnDeviceModalComponent {
  public isSaving = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReturnDevicesData,
    private dialogRef: MatDialogRef<ReturnDeviceModalComponent>,
    private devicesOverviewService: DevicesOverviewService,
    private matDialog: MatDialog,
  ) {
    if (!this.data?.selectedDevices.length) {
      this.dialogRef.close();
    }
  }

  public handleCloseModal() {
    this.dialogRef.close();
  }

  public async confirmReturn() {
    try {
      this.isSaving = true;

      const returnDevicesPromises = this.data.selectedDevices.map(
        device => this.devicesOverviewService.returnDevice(device.id),
      );

      await Promise.all(returnDevicesPromises);

      this.isSaving = false;

      this.dialogRef.close({ reload: true });
    } catch (error) {
      this.isSaving = false;
      console.error(error);

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to return devices',
          text: `(${this.data.selectedDevices.length}) devices returned`,
        } as DialogAlertParams,
      });
    }
  }

  public cancelReturn() {
    console.log('cancelReturn');
    this.handleCloseModal();
  }
}
