import { Route } from '@angular/router';

import { AuthGuard } from '@arkiq-portals/sdk';
import { AlertsComponent } from './pages/alerts/alerts.component';
import { DeviceCreateComponent } from './pages/devices/device-create/device-create.component';
import { DeviceDetailsComponent } from './pages/devices/device-details/device-details.component';
import { DeviceTransferComponent } from './pages/devices/device-transfer/device-transfer.component';
import { DevicesListComponent } from './pages/devices/devices-list/devices-list.component';
import { DevicesComponent } from './pages/devices/devices.component';
import { HomeComponent } from './pages/home/home.component';
import { CreateOrganizationComponent } from './pages/organizations/create-organization/create-organization.component';
import { OrganizationDetailsComponent } from './pages/organizations/organization-details/organization-details.component';
import { OrganizationDetailsDevicesComponent } from './pages/organizations/organization-details/pages/organization-details-devices/organization-details-devices.component';
import { OrganizationDetailsPropertiesComponent } from './pages/organizations/organization-details/pages/organization-details-properties/organization-details-properties.component';
import { OrganizationDetailsSummaryComponent } from './pages/organizations/organization-details/pages/organization-details-summary/organization-details-summary.component';
import { OrganizationsListComponent } from './pages/organizations/organizations-list/organizations-list.component';
import { OrganizationsComponent } from './pages/organizations/organizations.component';
import { CreatePropertyComponent } from './pages/properties/create-property/create-property.component';
import { PropertyDetailsLayoutComponent } from './pages/properties/properties-details/pages/property-details-layout/property-details-layout.component';
import { PropertyDetailsUserComponent } from './pages/properties/properties-details/pages/property-details-users/property-details-user.component';
import { PropertyDetailsComponent } from './pages/properties/properties-details/property-details.component';
import { PropertiesListComponent } from './pages/properties/properties-list/properties-list.component';
import { PropertiesComponent } from './pages/properties/properties.component';
import { RootComponent } from './pages/root.component';
import { CreateUserComponent } from './pages/users/create-user/create-user.component';
import { UserDetailsComponent } from './pages/users/user-details/user-details.component';
import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { UsersComponent } from './pages/users/users.component';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    loadChildren: () => import('@arkiq-portals/ui').then(m => m.auth.routes),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: RootComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'alerts',
        component: AlertsComponent,
        children: [
          {
            path: 'ongoing',
            component: AlertsComponent,
          },
        ],
      },
      {
        path: 'users',
        component: UsersComponent,
        children: [
          {
            path: '',
            component: UsersListComponent,
          },
          // {
          //   path: 'manage-roles-permissions',
          //   component: UsersListComponent,
          // },
          {
            path: 'create',
            component: CreateUserComponent,
          },
          {
            path: ':id/edit',
            component: CreateUserComponent,
          },
          {
            path: ':id',
            component: UserDetailsComponent,
          },
        ],
      },
      {
        path: 'organizations',
        component: OrganizationsComponent,
        children: [
          {
            path: '',
            component: OrganizationsListComponent,
          },
          {
            path: 'create',
            component: CreateOrganizationComponent,
          },
          {
            path: ':id/edit',
            component: CreateOrganizationComponent,
          },
          {
            path: ':id',
            component: OrganizationDetailsComponent,
            children: [
              {
                path: 'summary',
                component: OrganizationDetailsSummaryComponent,
              },
              {
                path: 'properties',
                component: OrganizationDetailsPropertiesComponent,
              },
              // {
              //   path: 'devices',
              //   component: OrganizationDetailsDevicesComponent,
              // },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'summary',
              },
            ],
          },
        ],
      },
      {
        path: 'properties',
        component: PropertiesComponent,
        children: [
          {
            path: '',
            component: PropertiesListComponent,
          },
          {
            path: 'create',
            component: CreatePropertyComponent,
          },
          {
            path: ':id/edit',
            component: CreatePropertyComponent,
          },
          {
            path: ':id',
            component: PropertyDetailsComponent,
            children: [
              // {
              //   path: 'summary',
              //   component: PropertyDetailsSummaryComponent,
              // },
              {
                path: 'users',
                component: PropertyDetailsUserComponent,
              },
              {
                path: 'layout',
                component: PropertyDetailsLayoutComponent,
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'users',
              },
            ],
          },
        ],
      },
      {
        path: 'devices',
        component: DevicesComponent,
        children: [
          {
            path: '',
            component: DevicesListComponent,
          },
          {
            path: 'installed',
            component: DevicesListComponent,
          },
          {
            path: 'inventory',
            component: DevicesListComponent,
          },
          {
            path: 'transfer',
            component: DeviceTransferComponent,
          },
          {
            path: 'create',
            component: DeviceCreateComponent,
          },
          {
            path: ':id',
            component: DeviceDetailsComponent,
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
];
