import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { ButtonComponent } from '../button/button.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { InputComponent } from '../input/input.component';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';
import { UserBadgeComponent } from '../user-badge/user-badge.component';

@Component({
  selector: 'arkiq-header',
  templateUrl: 'header.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputComponent,
    ButtonComponent,
    UserBadgeComponent,
    IconButtonComponent,
    LanguageSelectorComponent,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() title = '';
  @Input() hideSearchBar = true;
  @Output() searchChange = new EventEmitter<string>();

  public searchControl = new FormControl();

  private subscription = new Subscription();

  public ngOnInit(): void {
    this.subscription.add(
      this.searchControl.valueChanges.pipe(distinctUntilChanged()).subscribe(
        value => this.searchChange.emit(value),
      ),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}