import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  DeviceCategory,
  DeviceOverview,
  DeviceType,
  DevicesOverviewService,
  Organization,
  PropertyType,
  PropertyTypeLabel,
  UserRole,
  UserType,
  UserTypeLabel,
} from '@arkiq-portals/sdk';

@Component({
  selector: 'app-organization-summary-cards',
  templateUrl: 'organization-summary-cards.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule],
  styles:[`:host {display: flex; flex:1;}`]
})
export class OrganizationSummaryCardsComponent implements OnInit {
  @Input() organization!: Organization;

  public PROPERTY_TYPES = PropertyTypeLabel;
  public USER_TYPES = UserTypeLabel;
  public totalDevices = 0;
  public installedDevices = 0;
  public offlineDevices = 0;
  public DEVICE_TYPES = DeviceType;
  public devices: DeviceOverview[] = [];

  constructor(
    private deviceOverviewService: DevicesOverviewService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.devicesInfo();
  }

  public async devicesInfo() {
    const [installedDevices, devices, count] = await Promise.all([
      this.deviceOverviewService.getByOrganizationId(
        this.organization.id,
        { limit: 999999, category: DeviceCategory.INSTALLED },
      ),
      this.deviceOverviewService.getByOrganizationId(
        this.organization.id,
        { limit: 999999 },
      ),
      this.deviceOverviewService.countByOrganizationId(
        this.organization.id,
        { limit: 999999 },
      ),
    ]);

    this.totalDevices = count;
    this.devices = devices;
    this.installedDevices = installedDevices.length;
    this.offlineDevices = devices.filter(d => d.status === 'offline').length;
    // this.installedDevices = devices.reduce((acc, cur) => {
    //   if (cur.property_id && cur.organization_id) return acc + 1;
    //   return acc;
    // }, 0);
  }

  public get organizationProperties() {
    const properties = this.organization.properties ?? [];

    const total = properties.length;

    const totalByType = properties.reduce((acc, cur) => {
      if (!acc[cur.type]) {
        acc[cur.type] = 0;
      }
      acc[cur.type] += 1;
      return acc;
    }, {} as Record<PropertyType, number>);

    const totalCities = Array.from(
      new Set(properties.map(property => property.address_city)),
    ).length;
    const totalStates = Array.from(
      new Set(properties.map(property => property.address_state)),
    ).length;

    return { total, totalByType, totalCities, totalStates };
  }

  public get organizationUsers() {
    const users =
      this.organization.users_organizations_roles?.filter(
        userRole => userRole.user,
      ) ?? [];

    const rawCustomers = users.filter(user => user.role === UserRole.CUSTOMER);

    const customers = Array.from(new Set(rawCustomers.map(user => user.userId)));

    const total = Array.from(new Set(users.map(user => user.userId))).length;
    const totalByType =
      customers.length > 0
        ? {
            [UserType.CUSTOMER]: customers.length,
          }
        : {};
    return { total, totalByType };
  }

  public organizationDevices() {
    const deviceByType: { [key in DeviceType | string]: number } = {} as {
      [key in DeviceType]: number;
    };
    this.devices.forEach(device => {
      if (device.type.length === 0) {
        if (deviceByType['N/A']) {
          deviceByType['N/A'] += 1;
          return;
        }
        deviceByType['N/A'] = 1;
        return;
      }
      if (device.type in deviceByType) {
        deviceByType[device.type] += 1;
        return;
      }
      deviceByType[device.type] = 1;
    });

    return deviceByType;
  }

  public handleGoToOrganizationProperties(propertyType: string) {
    this.router.navigateByUrl(
      `/organizations/${this.organization.id}/properties`,
      { state: { propertyType } },
    );
  }

  public handleGoToUsersList() {
    this.router.navigateByUrl(
      '/users',
      { state: { organizationId: this.organization.id } },
    );
  }

  public handleGoToUsersListByRole(type: string) {
    this.router.navigateByUrl(
      '/users',
      { state: { organizationId: this.organization.id, type } },
    );
  }

  public handleGoToDevicesList() {
    this.router.navigateByUrl(
      '/devices',
      { state: { organizationId: this.organization.id } },
    );
  }

  public handleGoToInstalledDevicesList() {
    this.router.navigateByUrl(
      '/devices/installed',
      { state: { organizationId: this.organization.id } },
    );
  }

  public handleGoToInstalledOfflineDevicesList() {
    this.router.navigateByUrl(
      '/devices',
      { state: { organizationId: this.organization.id, status: 'offline' } },
    );
  }

  public handleGoToDevicesListByType(type: string) {
    this.router.navigateByUrl(
      '/devices',
      { state: { organizationId: this.organization.id, type } },
    );
  }

  // public organizationPropertiesId() {
  //   return this.organization.properties?.map(property => property.id);
  // }

  public get isOrganizationDevicesEmpty(): boolean {
    return Object.keys(this.organizationDevices()).length === 0;
  }
}
