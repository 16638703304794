import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { DeviceWaterLeakView } from '../views';
import { firstValueFrom } from 'rxjs';
import { SortOrder } from '../types';

export type ListDevicesWaterLeakParams = {
  limit?: number;
  page?: number;
  search?: string;
  order?: SortOrder;
  orderBy?: string;
  startDate?: string;
  endDate?: string;
};

export type ListDevicesWaterLeakQueryResult = {
  arkiq_devices_history_water_leak_view: DeviceWaterLeakView[];
  arkiq_devices_history_water_leak_view_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export type ListDevicesWaterLeakResponse = {
  devices: DeviceWaterLeakView[];
  totalItems: number;
};

const GET_DEVICES_WATER_LEAK_QUERY = (params: ListDevicesWaterLeakParams) => {
  const limit = params.limit ?? 10;
  const page = params.page ?? 1;
  const search = params.search ?? '';
  const order = params.order ?? 'desc_nulls_last';
  const orderBy = params.orderBy ?? 'date_time';


  const offset = (page - 1) * limit;

  const query = search
    ? `(order_by: {${orderBy}: ${order}} limit:${limit} offset:${offset} ${search})`
    : `(order_by: {${orderBy}: ${order}} limit:${limit} offset:${offset})`;

  const aggregateQuery = search
    ? `(order_by: {${orderBy}: ${order}} ${search})`
    : `(order_by: {${orderBy}: ${order}})`;

  return gql`
    query ListDevicesWaterLeak {
      arkiq_devices_history_water_leak_view${query} {
        date_time
        leak
        temperature
        humidity
        battery_level
        battery_voltage
        tampered
        f_cnt
        last_rssi
        last_snr
      }
      arkiq_devices_history_water_leak_view_aggregate${aggregateQuery} {
              aggregate {
                count
              }
            }
    }
  `;


};

@Injectable({ providedIn: 'root' })
export class DevicesWaterLeakService {
  constructor(private apollo: Apollo) {}

  public async list(
    params?: ListDevicesWaterLeakParams,
  ): Promise<ListDevicesWaterLeakResponse> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<ListDevicesWaterLeakQueryResult>({
          query: GET_DEVICES_WATER_LEAK_QUERY(params ?? {}),
        }),
      );

      return {
        devices: response.data.arkiq_devices_history_water_leak_view,
        totalItems: 0, //response.data.arkiq_devices_history_water_leak_view_aggregate.aggregate.count,
      };
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async listByDeviceId(
    deviceId: string,
    params: ListDevicesWaterLeakParams,
  ): Promise<ListDevicesWaterLeakResponse> {
    try {
      const startDate = params.startDate && params.startDate.length > 0  ? params.startDate : new Date(1900).toISOString()
      const endDate = params.endDate && params.endDate.length > 0 ? params.endDate :  new Date().toISOString()
      const search = `where: {
        device_id: {_eq: "${deviceId}"},
        date_time: {_gte: "${startDate}", _lte: "${endDate}"}

      }`;

      const response = await firstValueFrom(
        this.apollo.query<ListDevicesWaterLeakQueryResult>({
          query: GET_DEVICES_WATER_LEAK_QUERY({ ...params, search } ?? {}),
        }),
      );

      return {
        devices: response.data.arkiq_devices_history_water_leak_view,
        totalItems: response.data.arkiq_devices_history_water_leak_view_aggregate.aggregate.count,
      };
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async getById(deviceId: string): Promise<DeviceWaterLeakView> {
    try {
      const search = `where: {device_id: {_eq: "${deviceId}"}}`;

      const response = await firstValueFrom(
        this.apollo.query<ListDevicesWaterLeakQueryResult>({
          query: GET_DEVICES_WATER_LEAK_QUERY({ search }),
        }),
      );

      return response.data.arkiq_devices_history_water_leak_view[0];
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }
}
