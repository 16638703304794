import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router } from '@angular/router';

import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ENVIRONMENT, SdkModule } from '@arkiq-portals/sdk';
import * as Sentry from "@sentry/angular";
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { GraphQLModule } from './graphql/graphql.module';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: ENVIRONMENT, useValue: environment },
    provideFirebaseApp(() => initializeApp()),
    provideDatabase(() => getDatabase()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideAnimations(),
    importProvidersFrom(SdkModule), provideAnimationsAsync(),
    importProvidersFrom(GraphQLModule),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
