import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
    DeviceOverview,
    DevicesOverviewService,
    DeviceType,
    Fixture,
    FixturesService,
    UserRole,
} from '@arkiq-portals/sdk';
import {
    ButtonComponent,
    DialogAlertComponent,
    DialogAlertParams,
    DialogAlertVariant,
    DialogConfirmActionComponent,
    DialogConfirmActionParams,
    HasRoleDirective,
    IconButtonComponent,
    SpinnerComponent,
} from '@arkiq-portals/ui';
import { take } from 'rxjs';
import { ReturnDeviceModalComponent } from '../devices-list/components/return-device-modal/return-device-modal.component';
import { DeviceDetailsHeaderComponent } from './device-details-header/device-details-header.component';
import { DeviceGatewayGridComponent } from './device-gateway-grid/device-gateway-grid.component';
import { DeviceSmartValveTableComponent } from './device-smart-valve-table/device-smart-valve-table.component';
import { DeviceToiletSensorGridComponent } from './device-toilet-sensor-grid/device-toilet-sensor-grid.component';
import { DeviceWaterLeakHistoryTableComponent } from './device-water-leak-table/device-water-leak-table.component';

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    IconButtonComponent,
    ButtonComponent,
    SpinnerComponent,
    DeviceDetailsHeaderComponent,
    DeviceWaterLeakHistoryTableComponent,
    DeviceToiletSensorGridComponent,
    DeviceSmartValveTableComponent,
    DeviceGatewayGridComponent,
    HasRoleDirective,
  ],
  styles: [`:host {flex: 1} `]
})
export class DeviceDetailsComponent implements OnInit {
  @ViewChild(DeviceDetailsHeaderComponent) deviceDetailsHeaderRef!: DeviceDetailsHeaderComponent;

  public isLoading = false;
  public isUninstalling = false;

  public DEVICE_TYPE = DeviceType;
  public device!: DeviceOverview;
  public USER_ROLES = UserRole;
  public deviceId!: number;
  public deviceFixture?: Fixture;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private devicesOverviewService: DevicesOverviewService,
    private fixturesService: FixturesService,
    private matDialog: MatDialog,
  ) {}

  public ngOnInit(): void {
    const deviceId = this.activatedRoute.snapshot.paramMap.get('id');

    if (deviceId) {
      this.getDevice(deviceId);
    } else {
      const ref = this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to fetch device details',
          text: 'This device doesnt have a id, try again with a valid device',
        } as DialogAlertParams,
      });

      ref
        .afterClosed()
        .pipe(take(1))
        .subscribe(data => {
          this.handleGoBack();
        });
    }
  }

  public async getDevice(deviceId: number | string) {
    try {
      this.isLoading = true;
      this.device = await this.devicesOverviewService.getById(deviceId);
      const [fixture] = await this.fixturesService.listByDeviceId([this.device.id]);
      this.deviceFixture = fixture;
    } catch (error: any) {
      console.error(error);

      this.matDialog.open(DialogAlertComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          variant: DialogAlertVariant.ERROR,
          title: 'Unable to fetch device details',
          text: error?.error?.message || error?.message || error,
        } as DialogAlertParams,
      });
    } finally {
      this.isLoading = false;
    }
  }

  public handleGoBack() {
    this.location.back();
  }

  public handleReturnDevice(): void {
    const ref = this.matDialog.open(ReturnDeviceModalComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        selectedDevices: [this.device],
      },
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data && data.reload) {
          this.handleGoBack();
        }
      });
  }

  public handleUninstall(): void {
    const uninstall = async () => {
      try {
        this.isUninstalling = true;
        const fixtures = await this.fixturesService.listByDeviceId([this.device.id]);
        await this.fixturesService.removeDeviceIdFromMany(
          fixtures.map(fixture => fixture.id),
        );

        this.deviceFixture = undefined;
        this.deviceDetailsHeaderRef.deviceFixture = undefined;
        this.isUninstalling = false;
      } catch (error) {
        console.error(error);
        this.isUninstalling = false;
      }
    };

    const ref = this.matDialog.open(
      DialogConfirmActionComponent,
      {
        hasBackdrop: true,
        disableClose: false,
        data: {
          title: 'Confirm action',
          text: 'Are you sure you want to uninstall this device?'
        } as DialogConfirmActionParams,
      },
    );

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data && data.confirm) {
          uninstall();
        }
      });
  }
}
