import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'arkiq-language-selector',
  templateUrl: 'language-selector.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class LanguageSelectorComponent {
  public languages = ['EN'];
}
