<div *ngIf="!selectedOrganization" class="w-full flex flex-col gap-1">
  <arkiq-input
    type="text"
    label="Select Organization"
    placeholder="Select Organization"
    [control]="searchControl"
    [rounded]="false"
    [required]="true"
    size="md"
    [matAutocomplete]="auto"
  />

  <div class="w-full flex items-start justify-end">
    <small *ngIf="isLoading" [ngClass]="isLoading ? 'text-gray-500' : 'text-white'" class="text-xs">
      Loading organizations...
    </small>

    <button
      type="button"
      class="underline text-primary font-semibold text-sm hover:opacity-75 transition-opacity"
      (click)="handleCreateOrganization()"
    >
      Add organization
    </button>
  </div>

  <mat-autocomplete
    (optionSelected)="onOrganizationSelected($event)"
    [autoActiveFirstOption]="true"
    #auto="matAutocomplete"
  >
    <mat-option *ngFor="let organization of organizations" [value]="organization.id">
      <div class="w-full flex items-center gap-3">
        <span class="font-bold">{{ organization.name }}</span>
        <!-- <span class="text-xs text-gray-600">{{ organization.description }}</span> -->
      </div>
    </mat-option>
  </mat-autocomplete>
</div>

<div *ngIf="selectedOrganization" class="w-full flex items-center gap-3 p-2 border border-[#EEEEEE] rounded-[5px] truncate">
  <section class="flex-1 flex flex-col truncate">
    <span
      class="font-bold truncate"
      [matTooltip]="selectedOrganization.name"
    >
      {{ selectedOrganization.name }}
    </span>
    <!-- <span
      class="text-xs text-gray-600 truncate"
      [matTooltip]="selectedOrganization.description"
    >
      {{ selectedOrganization.description }}
    </span> -->
  </section>

  <button
    type="button"
    class="size-7 rounded-full hover:bg-black/5 transition-colors flex items-center justify-center"
    (click)="handleRemoveSelection()"
    matTooltip="Remove selection"
  >
    <i class="bi bi-x-lg text-[16px] text-[#AAAAAA]"></i>
  </button>
</div>
