export type Fixture = {
  id: number;
  name: string;
  type: FixtureType;
  status: string;
  device_id: string;
  location_id: number;
  property_id: number;
  created_at: string;
  updated_at: string;
  device: {
    status: string;
  }
};

export enum FixtureType {
  SINK = 'SINK',
  TOILET = 'TOILET',
  BATHTUB = "BATHTUB",
  AC_CLOSET = "AC CLOSET",
  WASHER_MACHINE = "WASHER MACHINE",
  DISHWASHER = "DISHWASHER",
  OTHER = "OTHER",
}
