import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { format } from 'date-fns';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-alert-card',
  templateUrl: 'alert-card.component.html',
})
export class AlertCardComponent {
  @Input({ required: true }) date!: Date;
  @Input({ required: true }) address!: string;
  @Input({ required: true }) time!: string;
  @Input({ required: true }) fixture!: string;
  @Input({ required: true }) alertType!: string;
  @Input({ required: true }) organization_id!:string;
  @Input({ required: true }) property_id!:string;

  constructor(
    private router: Router
  ) {}

  public day() {
    return format(this.date, 'dd');
  }

  public month() {
    return format(this.date, 'LLL');
  }

  public handleGoToAlert() {
    this.router.navigateByUrl("/alerts", {
      state: {
        organizationId: this.organization_id,
        propertyId: this.property_id
      }
    });

  }
}
