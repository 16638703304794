import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FunctionsService,
  Organization,
  OrganizationsService,
  PropertiesService,
  Property,
  PropertyType,
  PropertyTypeLabel,
  StorageService,
  User,
  UserOrganizationRoleService,
  UserRole,
  UserRoleLabel,
  UsersService,
  UserType,
} from '@arkiq-portals/sdk';
import {
  AddOrganizationMasterFormComponent,
  ButtonComponent,
  DialogAlertComponent,
  DialogAlertParams,
  DialogAlertVariant, DialogConfirmActionComponent, DialogConfirmActionParams, DialogCreateOrganizationComponent,
  IconButtonComponent,
  InputComponent,
  SelectComponent,
  SelectOption, SelectOrganizationMasterComponent,
  SpinnerComponent,
  TableComponent
} from '@arkiq-portals/ui';
import { take } from 'rxjs';
import { ManagingUser, SelectPropertyManagingUserComponent } from './select-property-managing-user/select-property-managing-user.component';
import { SelectPropertyOrganizationComponent } from './select-property-organization/select-property-organization.component';
import { SelectPropertyPhotoButtonComponent } from './select-property-photo-button/select-property-photo-button.component';

@Component({
  selector: 'app-create-property',
  templateUrl: './create-property.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatTooltipModule,
    SpinnerComponent,
    IconButtonComponent,
    InputComponent,
    ButtonComponent,
    SelectPropertyPhotoButtonComponent,
    SelectComponent,
    SelectOrganizationMasterComponent,
    SelectPropertyOrganizationComponent,
    TableComponent,
    SelectPropertyManagingUserComponent,
    AddOrganizationMasterFormComponent,
  ],
})
export class CreatePropertyComponent implements OnInit {
  public property?: Property;

  public isLoading = false;
  public isSaving = false;
  public isMasterValid = true
  public isManagerUserValid = true;
  public isOrganizationInvalid = false;
  public isPropertyMasterInvalid = false;
  public isManagingUserInvalid = false;

  public form = new FormGroup({
    name: new FormControl<string | null>('', [Validators.required]),
    type: new FormControl<string | null>('', [Validators.required]),
    address_street: new FormControl<string | null>('', [Validators.required]),
    address_city: new FormControl<string | null>('', [Validators.required]),
    address_state: new FormControl<string | null>('', [Validators.required]),
    address_zip_code: new FormControl<string | null>('', [Validators.required]),
    organization: new FormControl<Organization | Partial<Organization> | null>(null, [Validators.required]),
    photo: new FormControl<File | null>(null, []),
    photoUrl: new FormControl<string | null>('', []),
    master: new FormControl<User | Partial<User> | null>(null, [Validators.required]),
    managingUsers: new FormControl<ManagingUser[]>([], []),
  });

  public USER_ROLES = UserRoleLabel;
  public shouldAssignUsers = false;

  public userToEdit?: ManagingUser | Partial<ManagingUser>;
  public userToEditIndex?: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private storageService: StorageService,
    private propertiesService: PropertiesService,
    private userOrganizationRolesService: UserOrganizationRoleService,
    private usersService: UsersService,
    private functionsService: FunctionsService,
    private dialog: MatDialog,
    private organizationsService: OrganizationsService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    const routeParams = this.activatedRoute.snapshot.params;
    const state = this.location.getState() as object;
    if ('id' in routeParams) {
      this.getProperty(Number(routeParams['id']));
    }

    if ('organizationId' in state) {
      this.organizationsService
        .getById(Number(state['organizationId']))
        .then(organization => this.form.patchValue({ organization }));
    } else {
      this.organizationsService.list()
      .then(({organizations}) =>  {
        if (organizations.length === 0) return
        this.form.patchValue(({organization: organizations[0]}))
      })
    }


  }

  public handleGoBack(property?: Property): void {
    const state = this.location.getState() as object;

    if ('redirectTo' in state) {
      this.router.navigateByUrl(
        state['redirectTo'] as string,
        {
          state: { ...state, createdProperty: property },
        },
      );
    } else {
      this.location.back();
    }
  }

  private async getProperty(propertyId: number) {
    try {
      this.isLoading = true;

      this.property = await this.propertiesService.getById(propertyId);

      this.populateForm();

      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      alert(error);
    }
  }

  private populateForm() {
    if (!this.property) {
      return;
    }

    this.form.patchValue({
      name: this.property.name,
      type: this.property.type,
      photoUrl: this.property.photo,
      address_street: this.property.address_street,
      address_city: this.property.address_city,
      address_state: this.property.address_state,
      address_zip_code: this.property.address_zip_code,
    });

    const master = this.property.users_organizations_roles?.find(
      userRole => userRole.role === UserRole.PROPERTY_MASTER,
    );

    if (master?.user) {
      this.form.controls.master.setValue(master.user);
    }

    const organization = this.property.organization;

    if (organization) {
      this.form.controls.organization.setValue(organization);
    }

    if (this.property.users_organizations_roles && this.property.users_organizations_roles.length > 0) {
      this.form.controls.managingUsers.setValue(
        this.property.users_organizations_roles?.filter(userRole => {
          userRole.role === UserRole.ORGANIZATION_MASTER ||
          userRole.role === UserRole.PROPERTY_MASTER
        }).map(
          userRole => ({
            user: userRole.user as User,
            role: userRole.role,
          })
        ),
      );
    }
  }

  public handleCreateOrganization(): void {
    const ref = this.dialog.open(
      DialogCreateOrganizationComponent,
      {
        hasBackdrop: true,
        disableClose: false,
      },
    );

    ref.afterClosed().pipe(take(1)).subscribe(
      data => {
        if (data && data.organization) {
          this.form.controls.organization.setValue(data.organization);
          this.isOrganizationInvalid = false
        }
      }
    );
  }

  public onAssignManagingUser(managingUser: ManagingUser) {
    const index = (this.form.value.managingUsers ?? []).findIndex(
      user => user.user.id === managingUser.user.id
    );

    if (index > -1) {
      this.form.patchValue({
        managingUsers: this.form.value.managingUsers?.map(
          (curManagingUser, curIndex) => curIndex === index ? managingUser : curManagingUser
        )
      });
    } else {
      this.form.patchValue({
        managingUsers: [
          ...(this.form.value.managingUsers ?? []),
          managingUser,
        ],
      });
    }
  }

  public async handleSave() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.isOrganizationInvalid = true;
      this.isPropertyMasterInvalid = true
      this.isManagingUserInvalid = true;

      return;
    }

    try {
      this.isSaving = true;

      let photoUrl = '';
      console.log(this.form.value);
      if (this.form.value.photo) {
        photoUrl = await this.storageService.uploadPropertyPhoto(this.form.value.photo);
      }

      if (this.property?.id) {
        this.property = await this.propertiesService.update(
          this.property.id,
          {
            name: this.form.value.name ?? '',
            organizationId: this.form.value.organization?.id,
            address_street: this.form.value.address_street ?? '',
            address_city: this.form.value.address_city ?? '',
            address_state: this.form.value.address_state ?? '',
            address_zip_code: this.form.value.address_zip_code ?? '',
            photo: photoUrl,
            type: this.form.value.type as PropertyType,
          },
        );
      } else {
        this.property = await this.propertiesService.create({
          name: this.form.value.name ?? '',
          organizationId: this.form.value.organization?.id,
          address_street: this.form.value.address_street ?? '',
          address_city: this.form.value.address_city ?? '',
          address_state: this.form.value.address_state ?? '',
          address_zip_code: this.form.value.address_zip_code ?? '',
          photo: photoUrl,
          type: this.form.value.type as PropertyType,
        });
      }

      if(this.form.value.master) {
        await this.savePropertyMaster(this.form.value.organization as Organization, this.property);
      }
      if(this.form.value.managingUsers) {
        await this.saveManagingUsers(
          this.form.value.organization as Organization,
          this.property,
          this.form.value.managingUsers ?? [],
        );
      }
      const ref = this.dialog.open(
        DialogAlertComponent,
        {
          hasBackdrop: true,
          disableClose: false,
          data: {
            variant: DialogAlertVariant.SUCCESS,
            title: 'Successfully Saved',
            text: 'The property data was saved to the database.'
          } as DialogAlertParams,
        },
      );

      ref
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => this.handleGoBack(this.property));

      this.isSaving = false;
    } catch (error) {
      this.isSaving = false;
      console.error(error);

      this.dialog.open(
        DialogAlertComponent,
        {
          hasBackdrop: true,
          disableClose: false,
          data: {
            variant: DialogAlertVariant.ERROR,
            title: 'Error to save property',
            text: error as string,
          } as DialogAlertParams,
        },
      );
    }
  }

  private async savePropertyMaster(organization: Organization, property: Property) {
    const currentPropertyMaster = this.property?.users_organizations_roles
      ?.find(userRole => userRole.role === UserRole.PROPERTY_MASTER);

    if (currentPropertyMaster) {
      await this.userOrganizationRolesService.delete(currentPropertyMaster.id);
    }

    if (this.form.value.master?.id) {
      await this.userOrganizationRolesService.create({
        organizationId: organization.id,
        userId: this.form.value.master?.id,
        propertyId: property.id,
        role: UserRole.PROPERTY_MASTER
      });
    } else {
      const { uid: firebaseId } = await this.functionsService.registerUser(
        this.form.value.master?.email as string,
        `${this.form.value.master?.firstName} ${this.form.value.master?.lastName}`,
      );

      const propertyMasterUser = await this.usersService.create({
        firebaseId,
        email: this.form.value.master?.email,
        firstName: this.form.value.master?.firstName,
        lastName: this.form.value.master?.lastName,
        phoneNumber: this.form.value.master?.phoneNumber,
        type: UserType.CUSTOMER,
        photo: '',
      });

      await this.userOrganizationRolesService.create({
        organizationId: organization.id,
        userId: propertyMasterUser.id,
        propertyId: property.id,
        role: UserRole.PROPERTY_MASTER
      });
    }
  }

  private async saveManagingUsers(
    organization: Organization,
    property: Property,
    managingUsers: ManagingUser[],
  ) {
    if (managingUsers.length === 0) {
      return;
    }

    const newUsers: ManagingUser[] = await Promise.all(
      managingUsers
      .filter(managingUser => !managingUser.user.id)
      .map(
        managingUser => this.usersService.create({
          email: managingUser.user?.email,
          firstName: managingUser.user?.firstName,
          lastName: managingUser.user?.lastName,
          phoneNumber: managingUser.user?.phoneNumber,
          type: UserType.CUSTOMER,
          photo: '',
        }).then(user => ({ user, role: managingUser.role })),
      ),
    );

    const users = [
      ...newUsers,
      ...managingUsers.filter(managingUser => managingUser.user.id)
    ];

    const saveManagingUsersPromises = users.map(
      managingUser => {
        const userRole = property?.users_organizations_roles?.find(
          role => role.userId === managingUser.user.id && role.organizationId === organization.id
        );

        if (userRole) {
          return this.userOrganizationRolesService.update(
            userRole.id,
            {
              organizationId: organization.id,
              userId: managingUser.user.id,
              propertyId: property.id,
              role: managingUser.role,
            }
          );
        } else {
          return this.userOrganizationRolesService.create({
            organizationId: organization.id,
            userId: managingUser.user.id,
            propertyId: property.id,
            role: managingUser.role,
          });
        }
      }
    );

    await Promise.all(saveManagingUsersPromises);
  }

  public handleEditManagingUser(user: ManagingUser | Partial<ManagingUser>, index: number) {
    this.userToEdit = user.user;
    this.userToEditIndex = index;
  }

  public onEditManagingUser(user: Partial<User>) {
    this.form.patchValue({
      managingUsers: this.form.value.managingUsers?.map(
        (managingUser, index) => index === this.userToEditIndex
          ? {
            role: managingUser.role,
            user: {
              ...managingUser.user,
              firstName: user.firstName,
              lastName: user.lastName,
              phoneNumber: user.phoneNumber,
            }
          }
          : managingUser,
      ),
    });

    this.userToEdit = undefined;
    this.userToEditIndex = undefined;
  }

  public handleRemoveManagingUser(managingUser: ManagingUser, index: number) {
    const ref = this.dialog.open(
      DialogConfirmActionComponent,
      {
        hasBackdrop: true,
        disableClose: false,
        data: {
          title: 'Confirm removal',
          text: 'Are you sure you want to remove this managing user?'
        } as DialogConfirmActionParams,
      }
    );

    ref.afterClosed().pipe(take(1)).subscribe(data => {
      if (data && data.confirm) {
        this.form.patchValue({
          managingUsers: this.form.value.managingUsers?.filter(
            (curManagingUser, curIndex) => curIndex !== index,
          ),
        });
      }
    });
  }

  public get propertyTypeOptions(): SelectOption[] {
    return Object.values(PropertyType).map(propertyType => ({
      label: PropertyTypeLabel[propertyType],
      value: propertyType,
    }));
  }

  public isMastersTouchedAndValid(event) {
    this.isMasterValid = event
  }

  public isManagerTouchedAndValid(event){
    this.isManagerUserValid = event
  }
}
