<div class="bg-white rounded-[20px] max-w-screen w-[432px]">
  <header class="w-full flex items-center justify-between p-[25px] border-b border-b-black/10">
    <h1 class="m-0 text-center text-2xl font-bold">
      {{ fixture ? 'Edit Fixture' : 'Add Fixture' }}
    </h1>

    <arkiq-icon-button icon="bi-x-lg" (click)="dialogRef.close()" />
  </header>

  <main class="w-full flex flex-col gap-5 p-[25px]">
    <arkiq-select
      label="Type"
      [required]="true"
      [options]="types"
      [control]="form.controls.type"
      [rounded]="false"
      size="md"
      [disabled]="isSaving"
    />

    <arkiq-input
      label="Name"
      [required]="true"
      [rounded]="false"
      size="md"
      [control]="form.controls.name"
      [disabled]="isSaving"
    />
  </main>

  <footer class="w-full flex items-center justify-between border-t border-t-black/10 px-[25px] py-5">
    <arkiq-button
      variant="tertiary"
      text="Cancel"
      size="md"
      [rounded]="false"
      [disabled]="isSaving"
      (click)="dialogRef.close()"
    />

    <arkiq-button
      [text]="fixture ? 'Save' : 'Add'"
      size="md"
      [rounded]="false"
      [disabled]="isSaving || form.invalid"
      [loading]="isSaving"
      (click)="handleSaveFixture()"
    />
  </footer>
</div>
