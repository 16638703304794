<div class="bg-white flex flex-col gap-6 p-5 rounded-[20px] max-w-[432px] w-full overflow-hidden">
  <header class="w-full flex flex-col gap-2">
    <button
      (click)="dialogRef.close()"
      class="size-7 rounded-full hover:bg-black/5 transition-colors self-end flex items-center justify-center"
      type="button"
    >
      <i class="bi bi-x-lg text-[16px] text-[#AAAAAA]"></i>
    </button>

    <h1 [ngClass]="[titleColor]" class="m-0 text-center text-2xl font-bold">
      {{ data.title }}
    </h1>
  </header>

  <main class="font-medium text-[15px] text-center w-full px-5">
    {{ data.text }}
  </main>

  <footer class="flex flex-col gap-2 w-full items-center justify-center">
    <arkiq-button
      (click)="dialogRef.close()"
      [rounded]="false"
      size="md"
      text="Close"
      variant="tertiary"
    />
  </footer>
</div>
