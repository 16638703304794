import { CommonModule, Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UserRole } from '@arkiq-portals/sdk';
import { HasRoleDirective } from '../../directives';

export type Tab = {
  route: `/${string}`;
  text: string;
  allowedRoles?: UserRole[];
}

@Component({
  selector: 'arkiq-tabs',
  templateUrl: 'tabs.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, HasRoleDirective]
})
export class TabsComponent {
  @Input() tabs: Tab[] = [];

  public currentUrl = '';

  public ALL_USER_ROLES = Object.values(UserRole);

  constructor(private router: Router, private location: Location) {
    this.currentUrl = this.router.url;

    this.location.onUrlChange(url => {
      this.currentUrl = url;
    });
  }

  public get activeTabIndex() {
    return this.tabs.findIndex(tab => tab.route === this.currentUrl);
  }
}
