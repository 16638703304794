import { Injectable } from '@angular/core';
import { Fixture } from '@arkiq-portals/sdk';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class FixturesService {
  constructor(private apollo: Apollo) {}

  public async listByLocationId(locationId: number, search?: string): Promise<Fixture[]> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<{ front_fixtures: Fixture[] }>({
          query: gql`
            query ListFixturesByLocationId {
              front_fixtures(where: {
                  location_id: {_eq: ${locationId}}
                  ${search
                      ? `name: {_ilike: "%${search}%"}`
                      : ''
                  }

                }) {
                id
                name
                type
                status
                device_id
                device {
                  status
                }
                property_id
                location_id
                created_at
                updated_at
              }
            }
          `,
          fetchPolicy: 'no-cache',
        }),
      );

      return response.data.front_fixtures;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async listByManyLocationsIds(locationsIds: number[]): Promise<Fixture[]> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<{ front_fixtures: Fixture[] }>({
          query: gql`
            query ListFixturesByLocationId {
              front_fixtures(where: {location_id: {_in: [${locationsIds.join(',')}]}}) {
                id
                name
                type
                status
                device_id
                device {
                  status
                }
                property_id
                location_id
                created_at
                updated_at
              }
            }
          `,
          fetchPolicy: 'no-cache',
        }),
      );

      return response.data.front_fixtures;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async listByDeviceId(deviceId: string[]): Promise<Fixture[]> {
    const devicesIds = deviceId.reduce((acc,next,idx) => {
        acc+=`"${next}"${idx + 1 !== deviceId.length ? ',' : ''}`
        return acc
    },"")

    const query = `
    query ListFixturesByDeviceId {
      front_fixtures(where: {device_id: {_in: [${devicesIds}]}}) {
        id
        name
        type
        status
        device_id
        device {
          status
        }
        property_id
        location_id
        created_at
        updated_at
      }
    }
  `
    try {
      const response = await firstValueFrom(
        this.apollo.query<{ front_fixtures: Fixture[] }>({
          query: gql`${query}`,
          fetchPolicy: 'no-cache',
        }),
      );

      return response.data.front_fixtures;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async create(data: Partial<Fixture>): Promise<Fixture> {
    const device_id = data.device_id ? `"${data.device_id}"` : null;
    const type = data.type ? `"${data.type}"` : null;
    const status = `"${data.status || 'online'}"`;

    try {
      const response = await firstValueFrom(
        this.apollo.mutate<{ insert_front_fixtures_one: Fixture }>({
          mutation: gql`
            mutation InsertLocation(
              $name: String = "${data.name}",
              $property_id: Int = ${data.property_id},
              $location_id: Int = ${data.location_id},
              $device_id: String = ${device_id},
              $type: String = ${type},
              $status: String = ${status},
            ) {
              insert_front_fixtures_one(object: {
                name: $name,
                property_id: $property_id,
                location_id: $location_id,
                device_id: $device_id,
                type: $type,
                status: $status
              }) {
                id
                name
                type
                status
                device_id
                location_id
                created_at
                updated_at
                property_id
              }
            }
          `,
        }),
      );

      return response.data?.insert_front_fixtures_one as Fixture;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async update(fixtureId: number, data: Partial<Fixture>) {
    try {
      await firstValueFrom(
        this.apollo.mutate<{ update_front_fixtures: { returning: object } }>({
          mutation: gql`
            mutation UpdateFixture($_eq: Int = ${fixtureId}, $name: String = "${data.name}", $type: String = "${data.type}") {
              update_front_fixtures(where: {id: {_eq: $_eq}}, _set: {name: $name, type: $type}) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async updateDeviceId(fixtureId: number, device_id: string) {
    try {
      await firstValueFrom(
        this.apollo.mutate<{ update_front_fixtures: { returning: object } }>({
          mutation: gql`
            mutation UpdateFixture($_eq: Int = ${fixtureId}, $device_id: String = "${device_id}") {
              update_front_fixtures(where: {id: {_eq: $_eq}}, _set: {device_id: $device_id}) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );

      await this.apollo.client.cache.reset();
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async removeDeviceIdFromMany(fixturesIds: number[]) {
    try {
      await firstValueFrom(
        this.apollo.mutate<{ update_front_fixtures: { returning: object } }>({
          mutation: gql`
            mutation UpdateManyFixtures {
              update_front_fixtures(
                where: {
                  id: {
                    _in: [${fixturesIds.join(',')}]
                  }
                },
                _set: { device_id: null }
              ) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );

      await this.apollo.client.cache.reset();
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async delete(fixtureId: number): Promise<void> {
    try {
      await firstValueFrom(
        this.apollo.mutate({
          mutation: gql`
            mutation DeleteFixture {
              delete_front_fixtures(where: {id: {_eq: ${fixtureId}}}) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }
}
