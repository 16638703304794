@if (isLoading) {
<div class="w-full h-full flex items-center justify-center gap-2">
  <arkiq-spinner />
  <p class="text-gray-500">Loading user...</p>
</div>
} @else {
<main class="w-full flex justify-center gap-6">
  <div class="flex flex-1 flex-col gap-2 rounded-lg bg-[#FFF] max-w-[625px]">
    <header
      class="w-full flex items-center justify-between px-6 py-5 border-b border-b-[#E0E0E0]"
    >
      <section class="flex items-center gap-2">
        <arkiq-icon-button icon="bi-chevron-left" (click)="handleGoBack()" />
        <h1 class="m-0 font-bold">View Details</h1>
      </section>

      <section class="flex items-center gap-2">
        <!-- <arkiq-button
          *hasRole="[USER_ROLES.ORGANIZATION_MASTER]"
          variant="primary-accent"
          [rounded]="false"
          size="md"
          text="Permissions Management"
        /> -->

        <arkiq-button
          *hasRole="[USER_ROLES.ORGANIZATION_MASTER]"
          variant="primary"
          [rounded]="false"
          size="md"
          text="Edit"
          (click)="handleEditUser()"
        />
      </section>
    </header>
    <div class="flex gap-6 p-6 items-center border-b border-b-[#E0E0E0]">
      <img
        [src]="user.photo"
        alt="User Picture"
        class="min-w-[120px] min-h-[120px] max-w-[120px] max-h-[120px] rounded-full object-cover"
      />

      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-3">
          <h3 class="text-xl font-bold">
            {{ user.firstName }} {{ user.lastName }}
          </h3>
          <p class="text-sm font-normal text-[#888]">{{ user.email }}</p>
        </div>
        <!-- <a class="text-primary flex items-center gap-2"
          ><i class="bi bi-chat-dots-fill"></i>
          <span class="font-semibold text-sm">Send or View Message</span>
        </a> -->
      </div>
    </div>

    <div class="flex flex-col px-6 py-[5px] border-b border-b-[#E0E0E0]">
      <div class="flex gap-8 border-b border-b-black/20 border-dashed p-5">
        <p class="font-medium text-sm w-20">Type</p>
        <p class="text-sm font-normal text-[#888]">{{ userType }}</p>
      </div>
      <div class="flex gap-8 border-b border-b-black/20 border-dashed p-5">
        <p class="font-medium text-sm w-20">Phone</p>
        <p class="text-sm font-normal text-[#888]">{{ user.phoneNumber }}</p>
      </div>
      <div class="flex gap-8 p-5">
        <p class="font-medium text-sm w-20">Last Login</p>
        <p class="text-sm font-normal text-[#888]">{{ lastLogin }}</p>
      </div>
    </div>

    <div class="p-6 flex flex-col">
      <h2 class="text-xl font-bold">Property and Roles</h2>

      @if(propertyAndRoles.length === 0) {
      <div class="w-full flex items-center justify-center text-[#CCC]">
        <span>No Properties</span>
      </div>
      } @else { @for(property of propertyAndRoles; track $index ) {
      <div
        class="flex items-center py-5 gap-1 [&:not(:last-child)]:border-b [&:not(:last-child)]:border-dashed [&:not(:last-child)]:border-b-black/20 [&:last-child]:pb-0"
      >
        <p class="text-sm font-medium">
          {{ property.propertyName }}
        </p>
        <p class="text-sm font-medium text-[#888] mr-auto">
          {{ property.organizationName }}
        </p>
        <p class="text-sm font-normal text-[#888]">{{ property.role }}</p>
      </div>
      } }
    </div>
  </div>
</main>
}
