import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertsObject } from '@arkiq-portals/sdk';
import { format } from 'date-fns';
import { toTitleCase } from 'libs/ui/src/lib/utils';
@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  selector: 'app-device-card',
  templateUrl: 'device-card.component.html',
})
export class DeviceCardComponent {
  @Input({ required: true }) alert!: AlertsObject;

  public get formattedAlertType() {
    return toTitleCase(this.alert.alert_type.name);
  }

  public get formattedPropertyName() {
    if (!this.alert.property?.name) {
      return 'Alerts';
    }
    return this.alert.property.name;
  }

  public get initiatedTime() {
    const formattedInitiatedTime = format(
      this.alert.alert_date_time,
      "MM'/'dd'/'yy '•' hh:mm a",
    );
    return formattedInitiatedTime;
  }

  public get clearedTime() {
    const formattedClearedTime = format(
      this.alert.time_cleared,
      "MM'/'dd'/'yy '•' hh:mm a",
    );
    return formattedClearedTime;
  }

  public get formattedLocation() {
    const propertyName = this.alert.property?.name ?? '';
    const fixture = this.alert.fixture
    const locationName = fixture?.location?.name
    const fixtureName = fixture?.name
    if (!propertyName || propertyName.length === 0) {
      return 'N/A';
    }

    if(!propertyName && fixture ) return "N/A"

    return `${propertyName ?? "N/A"} — ${fixture ? locationName : 'N/A' } — ${fixture ? fixtureName : "N/A"}`;
  }


  public get alertTypeLowerCase() {
    return this.alert.alert_type.name.toLowerCase()
  }
}
