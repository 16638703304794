import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { User } from '@arkiq-portals/sdk';
import { getUploadedImageURL } from 'apps/admin/src/utils';

@Component({
  selector: 'app-select-user-photo-button',
  templateUrl: 'select-user-photo-button.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class SelectUserPhotoButtonComponent implements OnInit {
  @Input() user?: User;
  @Output() selectFile = new EventEmitter<File>();

  public control = new FormControl<File | null>(null);
  public pictureUrl = '';

  public ngOnInit(): void {
    if (this.user?.photo) {
      this.pictureUrl = this.user.photo;
    }
  }

  public async handleUploadPicture(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      const url = await getUploadedImageURL(file);
      
      if (url) {
        this.pictureUrl = url;
      }

      this.control.setValue(file);
      this.selectFile.emit(file);
    }
  }
}
