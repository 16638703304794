import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { differenceInMinutes } from 'date-fns';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  BuildUser,
  User,
  UserOrganizationRole,
  UserRole,
  UserType,
} from '../models';
import { SortOrder } from '../types';
import { UserOrganizationRoleService } from './user-organization-role.service';

export type ListUsersParams = {
  limit?: number | null;
  page?: number | null;
  search?: string | null;
  organizationId?: number[] | null;
  propertyId?: number[] | null;
  role?: UserRole[] | null;
  orderBy?: string;
  order?: SortOrder;
  forceRefresh?: boolean;
  ids?: number[] | null;
  type?: UserType[] | null;
};

export type ListUsersQueryResult = {
  front_users: User[];
  front_users_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export type ListUsersResponse = {
  users: User[];
  totalItems: number;
};

@Injectable({ providedIn: 'root' })
export class UsersService {
  public isLoadingUser = true;

  public loggedUser: User | undefined;

  public loggedUserRolesBs = new BehaviorSubject<UserOrganizationRole[]>([]);

  constructor(
    private apollo: Apollo,
    private userOrganizationRolesService: UserOrganizationRoleService,
    private auth: AngularFireAuth,
    private router: Router,
  ) {
    this.auth.authState.subscribe(firebaseUser => {
      if (firebaseUser) {
        this.onUserLoggedIn(firebaseUser);
        // this.isLoadingUser = true;
        // this.getByFirebaseId(firebaseUser.uid)
        //   .then(user => {
        //     this.isLoadingUser = true;

        //     this.loggedUser = user;

        //     this.updateLastLogin(firebaseUser.uid, new Date());

        //     this.userOrganizationRolesService
        //       .listByUserId(user.id)
        //       .then(roles => {
        //         console.log('logged user roles', roles);
        //         this.loggedUserRolesBs.next(roles ?? []);
        //         this.isLoadingUser = false;
        //       });
        //   })
        //   .catch(error => {
        //     this.auth.signOut();
        //   });
      } else {
        this.isLoadingUser = false;
      }
      // else {
      //   console.log('No user, signing out');
      //   this.isLoadingUser = false;
      //   this.auth.signOut();
      //   this.router.navigateByUrl('/auth');
      // }
    });
  }

  private async onUserLoggedIn(firebaseUser: any) {
    try {
      this.isLoadingUser = true;

      const user = await this.getByFirebaseId(firebaseUser.uid);
      this.loggedUser = user;

      const oneDayInMinutes = 60 * 24;
      if (
        firebaseUser.metadata.lastSignInTime &&
        differenceInMinutes(Date.now(), firebaseUser.metadata.lastSignInTime) > oneDayInMinutes
      ) {
        await this.auth.signOut();
        this.router.navigateByUrl('/auth/sign-in');
        return;
      }

      this.updateLastLogin(firebaseUser.uid, new Date());

      const roles = await this.userOrganizationRolesService.listByUserId(user.id);
      this.loggedUserRolesBs.next(roles);

      this.isLoadingUser = false;
    } catch (error) {
      this.auth.signOut();
    }
  }

  public get loggedUserRoles$() {
    return this.loggedUserRolesBs.asObservable();
  }

  public get loggedUserRoles() {
    return this.loggedUserRolesBs.value;
  }

  public get loggedUserOrganizations() {
    return Array.from(
      new Set(this.loggedUserRoles.map(userRole => userRole.organizationId)),
    );
  }

  public get loggedUserProperties() {
    return Array.from(
      new Set(this.loggedUserRoles.map(userRole => userRole.propertyId)),
    );
  }

  public get isLoggedUserCustomer() {
    return this.loggedUser?.type === UserType.CUSTOMER;
    // const roles = Array.from(new Set(this.loggedUserRoles.map(userRole => userRole.role)));
    // return roles.includes(UserRole.CUSTOMER) && !roles.includes(UserRole.ORGANIZATION_MASTER);
  }

  public get isLoggedUserAdmin() {
    return this.loggedUser?.type === UserType.ARKIQ;
    // const roles = Array.from(new Set(this.loggedUserRoles.map(userRole => userRole.role)));
    // return roles.includes(UserRole.CUSTOMER) && !roles.includes(UserRole.ORGANIZATION_MASTER);
  }

  public async list(params?: ListUsersParams): Promise<ListUsersResponse> {
    const forceRefresh = !!params?.forceRefresh;

    const orderBy = params?.orderBy || 'firstName';
    const order = params?.order || 'asc_nulls_first';

    const limit = params?.limit ?? 10;
    const page = params?.page ?? 1;

    const offset = (page - 1) * limit;

    const rolesQuery =
      params?.['role']?.map(role => '"' + role + '"').join(',') ?? '';

    const organizationsQuery =
      params?.['organizationId']
        ?.map(organizationId => '"' + organizationId + '"')
        .join(',') ?? '';

    const propertiesQuery =
      params?.['propertyId']
        ?.map(propertyId => '"' + propertyId + '"')
        .join(',') ?? '';

    const query = `
      order_by: {${orderBy}: ${order}}
      where: {
        ${
          rolesQuery || organizationsQuery || propertiesQuery || params?.organizationId?.length
            ? `
                users_organizations_roles: {
                ${rolesQuery ? `role: {_in: [${rolesQuery}]}` : ''}
                ${
                  organizationsQuery
                    ? `organizationId: {_in: [${organizationsQuery}]}`
                    : ''
                }
                ${propertiesQuery ? `propertyId: {_in: [${propertiesQuery}]}` : ''}
                ${
                  this.isLoggedUserCustomer && !params?.organizationId?.length
                    ? `organizationId: {_in: [${this.loggedUserOrganizations.join(
                        ',',
                      )}]}`
                    : ''
                }
              }
            `
            : ''
        }
        ${
          params?.['search']
            ? `
              _or: [
                { firstName: { _ilike: "%${params?.['search']}%" } },
                { lastName: { _ilike: "%${params?.['search']}%" } },
              ]
            `
            : ''
        }
        ${
          this.loggedUser?.type === UserType.CUSTOMER
            ? 'type: {_eq: "CUSTOMER"}'
            : ''
        }
        ${
          this.isLoggedUserCustomer && !params?.organizationId?.length
            ? `
              users_organizations_roles: {
                organizationId: {_in: [${this.loggedUserOrganizations.join(',')}]}
              }
            `
            : ''
        }
        ${
          params?.ids?.length
            ? `id: { _in: [${params.ids.join(',')}] }`
            : ''
        }
        ${
          params?.type?.length
            ? `type: { _in: [${params.type.map(type => `"${type}"`).join(',')}] }`
            : ''
        }
      }
    `;

    try {
      const response = await firstValueFrom(
        this.apollo.query<ListUsersQueryResult>({
          query: gql`
            query ListUsers {
              front_users(
                limit:${limit}
                offset:${offset}
                ${query}
              ) {
                photo
                phoneNumber
                lastName
                id
                firstName
                firebaseId
                email
                created_at
                role
                type
                lastLogin
                updated_at
                users_organizations_roles {
                  id
                  organizationId
                  userId
                  role
                  propertyId
                  organization {
                    id
                    name
                  }
                  property {
                    id
                    name
                    type
                    address_street
                    address_city
                    address_state
                    address_zip_code
                  }
                }
              }
              front_users_aggregate(${query}) {
                aggregate {
                  count
                }
              }
            }
        `,
          fetchPolicy: 'no-cache',
        }),
      );

      return {
        users: response.data.front_users.map(BuildUser),
        totalItems: response.data.front_users_aggregate.aggregate.count,
      };
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async searchByName(search: string): Promise<User[]> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<{ front_users: User[] }>({
          query: gql`
            query ListUsers {
              front_users(
                where: {
                  ${
                    this.isLoggedUserCustomer
                      ? `
                        users_organizations_roles: {
                          organizationId: {_in: [${this.loggedUserOrganizations.join(
                            ',',
                          )}]}
                        }
                      `
                      : ''
                  }
                  _or: [
                    { firstName: { _ilike: "%${search}%" } },
                    { lastName: { _ilike: "%${search}%" } },
                  ]
                }
              ) {
                photo
                phoneNumber
                lastName
                id
                firstName
                firebaseId
                email
                created_at
                role
                type
                lastLogin
                updated_at
                users_organizations_roles {
                  id
                  organizationId
                  userId
                  role
                  propertyId
                  organization {
                    id
                    name
                  }
                  property {
                    id
                    name
                    type
                    address_street
                    address_city
                    address_state
                    address_zip_code
                  }
                }
              }
            }
        `,
        fetchPolicy: 'no-cache',
        }),
      );

      return response.data.front_users.map(BuildUser);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async getById(userId: number): Promise<User> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<{ front_users: User[] }>({
          query: gql`
            query ListUsers {
              front_users(where: {id: {_eq: ${userId}}}) {
                photo
                phoneNumber
                lastName
                id
                firstName
                firebaseId
                email
                created_at
                role
                type
                updated_at
                lastLogin
                users_organizations_roles {
                  id
                  organizationId
                  userId
                  role
                  propertyId
                  organization {
                    id
                    name
                  }
                  property {
                    id
                    name
                    type
                    address_street
                    address_city
                    address_state
                    address_zip_code
                  }
                }
              }
            }
        `,
        fetchPolicy: 'no-cache',
        }),
      );

      return BuildUser(response.data.front_users[0]);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async getByFirebaseId(firebaseId: string): Promise<User> {
    try {
      const response = await firstValueFrom(
        this.apollo.query<{ front_users: User[] }>({
          query: gql`
            query ListUsers {
              front_users(where: {firebaseId: {_eq: "${firebaseId}"}}) {
                photo
                phoneNumber
                lastName
                id
                firstName
                firebaseId
                email
                created_at
                role
                type
                updated_at
              }
            }
        `,
        fetchPolicy: 'no-cache',
        }),
      );

      return BuildUser(response.data.front_users[0]);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async create(data: Partial<User>) {
    try {
      const response = await firstValueFrom(
        this.apollo.mutate<{ insert_front_users_one: User }>({
          mutation: gql`
            mutation InsertUser($firstName: String = "${data.firstName}", $lastName: String = "${data.lastName}", $type: String = "${data.type}", $email: String = "${data.email}", $phoneNumber: String = "${data.phoneNumber}", $firebaseId: String = "${data.firebaseId}", $photo: String = "${data.photo}") {
              insert_front_users_one(object: {firstName: $firstName, lastName: $lastName, type: $type, email: $email, phoneNumber: $phoneNumber, firebaseId: $firebaseId, photo: $photo}) {
                photo
                phoneNumber
                lastName
                id
                firstName
                firebaseId
                email
                created_at
                role
                type
                updated_at
              }
            }
          `,
        }),
      );

      return BuildUser(response.data?.insert_front_users_one);
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async update(userId: number, data: Partial<User>) {
    try {
      const response = await firstValueFrom(
        this.apollo.mutate<{ update_front_users: { returning: User[] } }>({
          mutation: gql`
            mutation UpdateUser($_eq: Int = ${userId}, $firstName: String = "${data.firstName}", $lastName: String = "${data.lastName}", $type: String = "${data.type}", $phoneNumber: String = "${data.phoneNumber}", $photo: String = "${data.photo}") {
              update_front_users(where: {id: {_eq: $_eq}}, _set: {firstName: $firstName, lastName: $lastName, type: $type, phoneNumber: $phoneNumber, photo: $photo}) {
                returning {
                  photo
                  phoneNumber
                  lastName
                  id
                  firstName
                  firebaseId
                  email
                  created_at
                  role
                  type
                  updated_at
                }
              }
            }
          `,
        }),
      );

      const updatedUser = BuildUser(response.data?.update_front_users.returning[0]);

      if (this.loggedUser?.id === userId) {
        this.loggedUser = updatedUser;
      }

      return updatedUser;
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async updateLastLogin(firebaseId: string, date: Date) {
    try {
      await firstValueFrom(
        this.apollo.mutate<{ update_front_users: { returning: User[] } }>({
          mutation: gql`
            mutation UpdateUser($_eq: String = "${firebaseId}", $lastLogin: timestamptz = "${date.toISOString()}") {
              update_front_users(where: {firebaseId: {_eq: $_eq}}, _set: {lastLogin: $lastLogin}) {
                returning {
                  id
                  lastLogin
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      // throw new Error(error?.error?.message || error?.message || error);
      console.error('Unable to update user last login', error);
    }
  }

  public async delete(userId: number): Promise<void> {
    try {
      if (userId === this.loggedUser?.id) {
        throw new Error('You cannot delete your own account!');
      }

      await this.userOrganizationRolesService.deleteByUserId(userId);

      await firstValueFrom(
        this.apollo.mutate({
          mutation: gql`
            mutation DeleteUser {
              delete_front_users(where: {id: {_eq: ${userId}}}) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public async deleteMany(usersIds: number[]): Promise<void> {
    try {
      await this.userOrganizationRolesService.deleteManyByUserId(usersIds);

      await firstValueFrom(
        this.apollo.mutate({
          mutation: gql`
            mutation DeleteManyUsers {
              delete_front_users(where: {id: {_in: [${usersIds.join(',')}]}}) {
                returning {
                  id
                }
              }
            }
          `,
        }),
      );
    } catch (error: any) {
      throw new Error(error?.error?.message || error?.message || error);
    }
  }

  public validateUserPermission(allowedRoles: UserRole[]) {
    if (this.loggedUser?.type === UserType.ARKIQ) {
      return true;
    }

    // console.log(allowedRoles)
    const userRoles = this.loggedUserRoles.map(item => item.role);
    return allowedRoles.some(role => userRoles.includes(role));
  }
}
