<div class="w-full h-full flex flex-col">
  <header
    class="w-full px-10 md:px-16 lg:px-28 2xl:px-44 py-8 md:py-14 flex flex-col gap-11 md:gap-14"
  >
    <!-- <arkiq-auth-steps /> -->

    <div class="w-full flex flex-col gap-5 items-center">
      <h1 class="m-0 font-semibold text-black text-4xl text-center">
        Login Now!
      </h1>

      <p class="m-0 text-base text-[#9B9B9B] text-center">
        Please enter your credentials to access your account.
      </p>
    </div>
  </header>

  <main
    class="flex-1 items-center w-full px-14 md:px-40 lg:px-44 2xl:px-56 pb-14"
  >
    <form class="w-full flex flex-col gap-7">
      <arkiq-input
        label="Email"
        placeholder="Your email address"
        [control]="form.controls.email"
        [attr.disabled]="isLoading"
        size="lg"
        (keyup.enter)="handleSignIn()"
      />

      <arkiq-password-input
        label="Password"
        placeholder="Your password"
        [control]="form.controls.password"
        [attr.disabled]="isLoading"
        size="lg"
        (keyup.enter)="handleSignIn()"
      />

      <a
        routerLink="/auth/forgot-password"
        class="text-primary text-xs font-medium self-end hover:underline"
      >
        Forget password?
      </a>

      <!-- <arkiq-checkbox
        label="Remember me"
        (changeValue)="form.controls.rememberMe.setValue($event)"
      /> -->

      <arkiq-button
        text="Login"
        [disabled]="form.invalid"
        [loading]="isLoading"
        (click)="handleSignIn()"
      />
    </form>
  </main>

  <!-- <footer class="flex items-center justify-center gap-2 pb-14">
    <span class="font-medium text-sm">Not registered yet?</span>
    <a routerLink="/auth/sign-up" class="font-medium text-sm text-primary hover:underline">Create an Account</a>
  </footer> -->
</div>
