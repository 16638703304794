<arkiq-icon-button
  [icon]="showFilterIcon ? 'bi-funnel-fill' : 'bi-filter'"
  [matMenuTriggerFor]="tableColumnHeaderMenu"
  [customIconClass]="showFilterIcon ? 'text-white bg-primary rounded p-1' : ''"
/>

<mat-menu #tableColumnHeaderMenu="matMenu">
  <div class="bg-white px-4 w-full">
    <header
      class="w-full py-4"
      [ngClass]="filter ? 'border-b border-b-[#AAAAAA]' : ''"
    >
      <button
        type="button"
        class="block w-full px-[10px] rounded-md hover:bg-black/5 transition-all font-medium py-1 disabled:opacity-50 text-left"
        [ngClass]="form.value.sort === 'asc_nulls_first' ? 'bg-black/5' : 'bg-transparent'"
        [disabled]="!isSortable"
        (click)="$event.stopPropagation(); form.controls.sort.setValue('asc_nulls_first')"
      >
        Sort A to Z
      </button>

      <button
        type="button"
        class="block w-full px-[10px] rounded-md hover:bg-black/5 transition-all font-medium py-1 disabled:opacity-50 text-left"
        [ngClass]="form.value.sort === 'desc_nulls_last' ? 'bg-black/5' : 'bg-transparent'"
        [disabled]="!isSortable"
        (click)="$event.stopPropagation(); form.controls.sort.setValue('desc_nulls_last')"
      >
        Sort Z to A
      </button>
    </header>

    @if (filter && filter.type === 'DATE_RANGE') {
      <main
        class="w-full py-4 px-[10px] flex flex-col gap-2"
        (click)="$event.stopPropagation()"
      >
        <fieldset class="flex flex-col gap-1 justify-end">
          <label class="font-medium text-black">
            From
          </label>

          <div class="flex items-center gap-1">
            <input
              [ngClass]="[
                'h-[34px] text-sm px-3 py-2 rounded-[5px]',
                'focus-within:border-primary border border-[#EEEEEE] flex items-center gap-4'
              ]"
              type="text"
              placeholder="Select Date"
              [formControl]="dateForm.controls.startDate"
              [matDatepicker]="pickerFrom"
              (click)="pickerFrom.open()"
            />

            <mat-datepicker-toggle matIconSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </div>
        </fieldset>

        <fieldset class="flex flex-col gap-1 justify-end">
          <label class="font-medium text-black">
            To
          </label>

          <div class="flex items-center gap-1">
            <input
              [ngClass]="[
                'h-[34px] text-sm px-3 py-2 rounded-[5px]',
                'focus-within:border-primary border border-[#EEEEEE] flex items-center gap-4'
              ]"
              type="text"
              placeholder="Select Date"
              [formControl]="dateForm.controls.endDate"
              [matDatepicker]="pickerTo"
              (click)="pickerTo.open()"
            />

            <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </div>
        </fieldset>

        <fieldset class="flex flex-col gap-1 pt-4 justify-end border-t border-t-[#AAAAAA] ">
          <label class="font-medium text-black">
            Quick Range
          </label>

          <!-- <arkiq-select
            size="md"
            icon="bi-search"
            [rounded]="false"
            [options]="quickRangeTypeOptions"
            [control]="selectForm"
          /> -->
          <button
            class="focus-within:border-primary border border-[#EEEEEE] flex items-center gap-4 h-[34px] text-sm px-3 py-2 rounded-[5px] text-[#9D9D9D]"
            [matMenuTriggerFor]="quickRange"
            #quickRangeTrigger="matMenuTrigger"

          >
            {{selectForm.value ? selectForm.value.label : 'Search...'}}
            @if (!selectForm.value){
            <i
              class="bi bi-search text-sm text-[#9D9D9D] ml-auto"
            ></i>}
          </button>
        </fieldset>
      </main>
    }

    @if (filter && filter.type === 'MULTIPLE_SELECT' && filter.options) {
      <main
        class="w-full py-4 px-[10px] flex flex-col gap-2"
        (click)="$event.stopPropagation()"
      >
        <header class="w-full flex flex-col gap-3">
          <div class="w-full flex items-center gap-2">
            <p class="font-medium">Filter by values</p>
            <p class="font-medium text-xs text-[#9D9D9D]">
              ({{ form.value.selectedOptions?.length ?? 0 }} Selected)
            </p>
          </div>

          <div class="w-full flex items-center justify-between gap-4">
            <button
              type="button"
              class="text-xs text-primary underline hover:opacity-70 transition-opacity"
              (click)="handleSelectAllOptions()"
            >
              Select All
            </button>

            <button
              type="button"
              class="text-xs text-primary underline hover:opacity-70 transition-opacity"
              (click)="handleClearAllOptions()"
            >
              Clear All
            </button>

            <p class="font-medium text-xs text-[#9D9D9D]">
              Displaying: {{ displayOptions.length }}
            </p>
          </div>
        </header>

        <section class="w-full">
          <arkiq-input
            size="md"
            icon="bi-search"
            placeholder="Search"
            [rounded]="false"
            [control]="form.controls.search"
          />
        </section>

        <main class="w-full">
          <ul class="w-full max-h-[100px] flex flex-col overflow-y-auto custom-scrollbar">
            @for (option of displayOptions; track $index) {
            <li
              class="w-full flex items-center gap-2 cursor-pointer px-4 py-2 hover:bg-black/5 transition-all rounded-md"
              (click)="handleSelectOption(option.value)"
            >
              <input
                type="checkbox"
                [name]="$index"
                [id]="$index"
                [checked]="form.value.selectedOptions?.includes(option.value)"
              />

              <p class="font-medium text-[15px] truncate">{{ option.label }}</p>
            </li>
            }
          </ul>
        </main>
      </main>
    }

    <footer
      class="w-full py-4 border-t border-t-[#AAAAAA] flex justify-between gap-4"
    >
      <arkiq-button
        variant="tertiary"
        text="Cancel"
        size="md"
        [rounded]="false"
      />

      <arkiq-button
        variant="primary"
        text="Ok"
        size="md"
        [rounded]="false"
        (click)="handleSave()"
      />
    </footer>
  </div>
</mat-menu>



<mat-menu #quickRange="matMenu">
  <ng-template matMenuContent let-node="node">

      @for (item of quickRangeOptions(); track $index) {
        <button
          type="button"
          class="py-2 flex px-4 border-b border-dashed hover:bg-gray-100 transition-all min-w-28"
          (click)="handleSelectQuickRange($event, item)"

        >
          <span>{{item.label}}</span>
        </button>
      }


  </ng-template>
</mat-menu>

