import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SidebarComponent, SidebarItem } from '@arkiq-portals/ui';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, SidebarComponent],
  selector: 'app-root',
  templateUrl: './root.component.html',
})
export class RootComponent {
  public sidebarMenu: SidebarItem[] = [
    {
      text: 'Home',
      icon: 'bi-house-door-fill',
      route: '',
    },
    {
      text: 'Devices',
      icon: 'bi-wifi',
      route: 'devices',
    },
    {
      text: 'Alerts',
      icon: 'bi-exclamation-triangle-fill',
      route: 'alerts',
    },
    {
      text: 'Users',
      icon: 'bi-people-fill',
      route: 'users',
    },
    {
      text: 'Organizations',
      icon: 'bi-building-fill',
      route: 'organizations',
    },
    {
      text: 'Properties',
      icon: 'bi-buildings-fill',
      route: 'properties',
    },
  ];
}
