import { BuildProperty, Property } from './property';
import {
  BuildUserOrganizationRole,
  UserOrganizationRole,
} from './user-organization-role';

export type Organization = {
  id: number;
  name: string;
  description: string;
  created_at: Date;
  updated_at: Date;
  properties?: Property[];
  users_organizations_roles?: UserOrganizationRole[];
  devices_details_aggregate?: {
    aggregate: {
      count;
    };
  };
  alerts_aggregate?: {
    aggregate: {
      count
    }
  }
  devices_online?: {
    aggregate: {
      count;
    };
  };
};

export function BuildOrganization(data: any): Organization {
  return {
    ...data,
    id: data.id,
    name: data.name ?? '',
    description: data.description ?? '',
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
    properties: data.properties?.length
      ? data.properties.map(BuildProperty)
      : [],
    users_organizations_roles:
      data.users_organizations_roles?.length > 0
        ? data.users_organizations_roles.map((userOrganizationRole: any) =>
            BuildUserOrganizationRole(userOrganizationRole),
          )
        : [],
  };
}
