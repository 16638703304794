<div class="w-full flex items-center gap-4 border-b border-b-[#E0E0E0] md:border-0 pb-4 relative">
  <fieldset class="w-full flex flex-wrap items-start md:grid md:grid-cols-3 gap-[10px]">
    <div class="flex w-full md:w-auto flex-col">
      <arkiq-select
        label="Role"
        [required]="true"
        size="md"
        [rounded]="false"
        [options]="roleOptions"
        [control]="form.controls.role"
      />
    </div>

    <div class="flex w-full md:w-auto flex-col gap-2">
      <arkiq-select
        label="Organization"
        [required]="true"
        size="md"
        [rounded]="false"
        [options]="organizationOptions"
        [control]="form.controls.organizationId"
      />

      @if (enableAddOrganization) {
        <button
          type="button"
          class="underline text-primary font-semibold text-sm self-end hover:opacity-75 transition-opacity"
          (click)="handleAddOrganization()"
        >
          Add organization
        </button>
      }
    </div>

    <div class="flex w-full md:w-auto flex-col gap-2">
      <arkiq-select
        label="Property"
        size="md"
        [rounded]="false"
        [options]="propertyOptions"
        [control]="form.controls.propertyId"
      />

      @if (form.value.organizationId && enableAddProperty) {
        <button
          type="button"
          class="underline text-primary font-semibold text-sm self-end hover:opacity-75 transition-opacity"
          (click)="handleAddProperty()"
        >
          Add property
        </button>
      }
    </div>
  </fieldset>

  @if (enableRemoveButton) {
    <arkiq-icon-button icon="bi-x-lg" (click)="handleRemoveLine()" class="absolute md:static -top-[2px] right-0" />
  }
</div>
