import {
  BuildOrganization,
  BuildProperty,
  DeviceType,
  Organization,
  Property,
} from '../models';

export type DeviceOverview = {
  id: string;
  type: DeviceType;
  organization: string;
  organization_id: number;
  property: string;
  property_id: number;
  last_alert: string;
  alerts_in_last_7_days: number;
  status: string;
  battery_level: number;
  device: {
    organization: Organization;
    property: Property;
  };
  gateway_id: string;
  purchased_on: Date;
  sold_on: Date;
  installed_on: Date;
  alerts_aggregate?: {
    aggregate: {
      count: number;
    };
  };
};

export function BuildDeviceOverview(data: any): DeviceOverview {
  return {
    ...data,
    device: {
      organization: data.device.organization
        ? BuildOrganization(data.device.organization)
        : null,
      property: data.device.property
        ? BuildProperty(data.device.property)
        : null,
    },
    purchased_on: data.purchased_on ? new Date(data.purchased_on) : null,
    sold_on: data.sold_on ? new Date(data.sold_on) : null,
    installed_on: data.installed_on ? new Date(data.installed_on) : null,
  };
}
