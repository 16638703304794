<div class="bg-white rounded-[20px] max-w-screen w-[432px]">
  @if (isLoading) {
  <div class="w-full h-[300px] flex items-center justify-center gap-2">
    <arkiq-spinner />
    <p class="text-gray-500">Loading devices...</p>
  </div>
  } @else {
  <header
    class="w-full flex items-center justify-between p-[25px] border-b border-b-black/10"
  >
    <h1 class="m-0 text-center text-2xl font-bold">Assign Device</h1>

    <arkiq-icon-button icon="bi-x-lg" (click)="dialogRef.close()" />
  </header>

  <main class="w-full flex flex-col gap-2 p-[25px]">
    <mat-autocomplete
      (optionSelected)="onDeviceSelected($event)"
      [autoActiveFirstOption]="true"
      #auto="matAutocomplete"
    >
      <mat-option *ngFor="let device of devicesFiltered" [value]="device.id">
        <span class="font-bold">{{ device.id }}</span>
      </mat-option>
    </mat-autocomplete>
    <arkiq-input
      type="text"
      placeholder="Select Device"
      label="Select Device"
      [control]="deviceControl"
      [rounded]="false"
      size="md"
      [matAutocomplete]="auto"
      [required]="true"
      [disabled]="isSaving"
    />
  </main>

  <footer
    class="w-full flex items-center justify-between border-t border-t-black/10 px-[25px] py-5"
  >
    <arkiq-button
      variant="tertiary"
      text="Cancel"
      size="md"
      [rounded]="false"
      [disabled]="isSaving"
      (click)="dialogRef.close()"
    />

    <arkiq-button
      text="Confirm"
      size="md"
      [rounded]="false"
      [disabled]="isSaving || deviceControl.invalid"
      [loading]="isSaving"
      (click)="handleAssignDevice()"
    />
  </footer>
  }
</div>
