import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';

import { ButtonComponent, InputComponent } from '@arkiq-portals/ui';

interface teste {
  text: string;
}

@Component({
  standalone: true,
  imports: [CommonModule, MatDialogModule, InputComponent, ButtonComponent],
  selector: 'arkiq-dialog-device-keys',
  templateUrl: 'dialog-device-keys.component.html',
})
export class DialogDeviceKeysComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: teste,
    public dialogRef: MatDialogRef<DialogDeviceKeysComponent>,
  ) {}

  ngOnInit() {}

  handleConfirmDeviceKeysModal() {
    console.log('handleConfirmDeviceKeysModal');
  }
}
